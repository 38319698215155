import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';

interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props: ItemProps;
	categories: CategoriesState;
	checkedCatIngram?:any;
}

interface searchProps {
	value?: any;
	id?: any;
}

export const FilterCategories = ({ checkedCatIngram, name, data, props, categories }: CrudItemProps) => {
	const dispatch = useDispatch<any>();

	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const [open, setOpen] = React.useState(false);

	const [filters, setFilters] = React.useState<any>({
		categories: [],
	});

	function serachData({ id, value }: searchProps) {
		console.log(value);
		setFilters({
			...filters,
			[id]: value,
		});
	}

	const userSchema = object({
		categories: string().nullable().optional(),
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = () => {
		//methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		methods.reset(defaultValues);
		setFilters({
			...filters,
			categories: [],
		});
	};

	const onSubmitHandler: SubmitHandler<IFilter> = (values: IFilter) => {
		values.categories = filters.categories.map((item: any) => item.title);
		props?.getValues(values);
		handleClose();
	};

	return (
		<div>
			<Button variant='outlined' onClick={handleClickOpen}>
				{name}
			</Button>

			<Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							<AutocompletCheckBoxGroup
								props={{
									group: false,
									data: mapCategories((!checkedCatIngram && categories?.datasearch) || categories?.dataIngram).filter(
										(item: any) => item.parent == item.title
									),
									value: filters?.categories?.filter((item: any) => item.parent == item.title),
									maxWidth: 400,
									id: 'categories',
									title: 'Categorias',
									onSearch: (search: searchProps) => serachData(search),
								}}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleReset}>Reset</Button>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type='submit'>Realizar</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};