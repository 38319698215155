import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';


const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface ItemProps {
	value?: any;
	title?: string;
	id?: string;
	onSearch: Function;
}

interface ItemsProps {
	props?: ItemProps;
}




export default function BasicDatePicker(props: ItemsProps) {
	
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DatePicker
				key={props?.props?.id}
				label={props?.props?.title}
				value={props?.props?.value}
				onChange={(newValue) => {
					props?.props?.onSearch({ id: props?.props?.id, value: new Date(newValue?.toLocaleString('es-MX')) });
				}}
				renderInput={(params) => <TextField {...params} />}
				
			/>
		</LocalizationProvider>
	);
	
}