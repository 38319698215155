import * as React from 'react';

import { useMemo, useState, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts, crudProducts, getProductsReport, putReport, getBrs } from '../../store/products/action';
import { getManufacturer } from '../../store/manufacturer/action';
import { getCategories, getCategoriesSearch, getCategoriesIngramSearch } from '../../store/categories/action';
import { userReset, getUsers } from '../../store/user/action';

import { Dispatch } from 'redux';

import { ProductsState, Prices } from '../../store/products/types';
import { ApplicationState } from '../../store/index';

import AutocompletCheckBox from './Components/AutocompletCheckBox';
import AutocompletCheckBoxGroup from './Components/AutocompletCheckBoxGroup';

import DatePicker from './Components/DatePicker';
import TextNumberBasic from './Components/TextNumber';
import Grid from '@mui/material/Grid';
import { styled, alpha } from '@mui/material/styles';

import { putFilter } from '../../store/filters/action';
import { putLoading } from '../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import { FilterProducts } from './Dialogs/FilterProducts';

import { ViewImages } from './Dialogs/ViewImages';
import { ViewSheets } from './Dialogs/ViewSheets';

import { ItemEdit } from './Dialogs/ItemEdit';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import EditIcon from '@mui/icons-material/Edit';

import Button from '@mui/material/Button';
import LinkIcon from '@mui/icons-material/Link';

import Switch from '@mui/material/Switch';

import { UserState, UserLogin } from '../../store/user/types';
import { loadState } from '../../utils/localstorage';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import AutocompletBoxGroup from './Components/AutocompletBoxGroup';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import FolderInput from '../../components/Admin/Components/FolderInput';

import { convertListFileToObjectParentTree, readTemplate } from '../../../src/utils/FolderInput';

import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PrimaryAttribute from './Dialogs/PrimaryAttribute';

import Menu, { MenuProps } from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
		| undefined
		/* | 'row?.products?.id' */
		| 'row?.products?.createdAt'
		| 'row?.updatedAt'
		/* | 'Image' */
		| 'DialogImages'
		| 'DialogSheets'
		| 'row?.products?.description'
		| 'row?.products?.productDetailDescription'
		| 'row?.products?.sku'
		| 'row?.products?.mfrpartnumber'
		| 'row?.price'
		| 'row?.priceApply'
		| 'row?.inventory'
		| 'row?.products?.manufacturerIngram?.name||row?.products?.manufacturer?.name'
		| "row?.products?.categoriesIngram?.categories ? (row?.products?.categoriesIngram?.categories.length>0? row?.products?.categoriesIngram?.categories?.map((item)=>item?.categories?.parent?.name).join(','): row?.products?.categoriesIngram?.parent?.name + ' ( Ingram ) ') : row?.products?.categoriesIngram?.parent?.name"
		| "row?.products?.categoriesIngram?.categories ? (row?.products?.categoriesIngram?.categories.length>0? row?.products?.categoriesIngram?.categories?.map((item)=>item?.categories?.name).join(','): row?.products?.categoriesIngram?.name + ' ( Ingram ) ') : row?.products?.categoriesIngram?.name"
		| undefined;
	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
	component?: string;
}

const columns: readonly Column[] = [
	{ id: undefined, label: '', minWidth: 10 },
	/* { id: 'row?.products?.id', label: 'Id', minWidth: 20 }, */
	{
		id: 'row?.products?.createdAt',
		label: 'Creado',
		minWidth: 80,
		format: (value: number) =>
			value ? new Date(value?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) : '-',
	},
	{
		id: 'row?.updatedAt',
		label: 'Actualizado',
		minWidth: 80,
		format: (value: number) =>
			value ? new Date(value?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) : '-',
	},
	/* {
		id: undefined,
		label: 'Imagen',
		minWidth: 80,
		component: 'Images'
	}, */
	{ id: undefined, component: 'DialogImages', label: 'Img', minWidth: 40 },
	{ id: undefined, component: 'DialogSheets', label: 'Ficha', minWidth: 40 },
	{ id: 'row?.products?.description', label: 'Nombre', minWidth: 80 },
	{ id: 'row?.products?.productDetailDescription', label: 'Desc. Detalle', minWidth: 80 },
	{
		id: 'row?.products?.sku',
		label: 'sku',
		minWidth: 40,
		align: 'right',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{ id: 'row?.products?.mfrpartnumber', label: 'Modelo', minWidth: 50 },
	{
		id: 'row?.price',
		label: 'Precio',
		minWidth: 60,
		format: (value: number) =>
			value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : '-',
	},

	//new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(100000000)
	{
		id: 'row?.priceApply',
		label: 'Precio Aplicado',
		minWidth: 60,
		format: (value: number) =>
			value ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value) : '-',
	},
	{
		id: 'row?.inventory',
		label: 'Inventario',
		minWidth: 60,
	},
	{
		id: 'row?.products?.manufacturerIngram?.name||row?.products?.manufacturer?.name',
		label: 'Fabricante',
		minWidth: 60,
	},
	{
		id: "row?.products?.categoriesIngram?.categories ? (row?.products?.categoriesIngram?.categories.length>0? row?.products?.categoriesIngram?.categories?.map((item)=>item?.categories?.parent?.name).join(','): row?.products?.categoriesIngram?.parent?.name + ' ( Ingram ) ') : row?.products?.categoriesIngram?.parent?.name",
		label: 'Categoria',
		minWidth: 60,
	},
	{
		id: "row?.products?.categoriesIngram?.categories ? (row?.products?.categoriesIngram?.categories.length>0? row?.products?.categoriesIngram?.categories?.map((item)=>item?.categories?.name).join(','): row?.products?.categoriesIngram?.name + ' ( Ingram ) ') : row?.products?.categoriesIngram?.name",
		label: 'SubCategoria',
		minWidth: 60,
	},
	{ id: undefined, component: 'Switch', label: 'Switch', minWidth: 40 },
];

interface ColumnH {
	id: any;
	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

let columnshistory: ColumnH[] = [
	{ id: 'row?.br10', label: 'br10', minWidth: 5 },
	{ id: 'row?.br20', label: 'br20', minWidth: 5 },
	{ id: 'row?.br30', label: 'br30', minWidth: 5 },
	{ id: 'row?.br40', label: 'br40', minWidth: 5 },
	{ id: 'row?.br50', label: 'br50', minWidth: 5 },
	{ id: 'row?.br55', label: 'br55', minWidth: 5 },
	{ id: 'row?.br60', label: 'br60', minWidth: 5 },
	{ id: 'row?.br70', label: 'br70', minWidth: 5 },
	{ id: 'row?.br80', label: 'br80', minWidth: 5 },
	{ id: 'row?.br90', label: 'br90', minWidth: 5 },
	{ id: 'row?.br73', label: 'br73', minWidth: 5 },
	{ id: 'row?.br05', label: 'br05', minWidth: 5 },
	{ id: 'row?.br03', label: 'br03', minWidth: 5 },
	{ id: 'row?.br28', label: 'br28', minWidth: 5 },
];

interface ColumnHDP {
	id:
		| 'row?.products?.height'
		| 'row?.products?.width'
		| 'row?.products?.length'
		| 'row?.products?.dimensionUnit'
		| 'row?.products?.weight'
		| 'row?.products?.weightUnit';

	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columnshistoryDimPeso: readonly ColumnHDP[] = [
	{ id: 'row?.products?.height', label: 'height', minWidth: 20 },
	{ id: 'row?.products?.width', label: 'width', minWidth: 20 },
	{
		id: 'row?.products?.length',
		label: 'length',
		minWidth: 20,
		//align: 'right',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{ id: 'row?.products?.dimensionUnit', label: 'dimensionUnit', minWidth: 20 },
	{ id: 'row?.products?.weight', label: 'weight', minWidth: 20 },
	{ id: 'row?.products?.weightUnit', label: 'weightUnit', minWidth: 20 },
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function Row(props: { brs:any, checkedBatch:any, rowp: Prices[], hideColumn: String[], user: any, categories: any, manufacturer: any, client: any, selected: { getValues: Function }}) {
	const { brs, checkedBatch, rowp, hideColumn, user, categories, manufacturer, client, selected } = props;
	const [open, setOpen] = React.useState(false);
	const [brDisplay, setBrDisplay] = React.useState<String[]>([]);
	const [otherImage, setOtherImage] = React.useState<number>(0);
	const [otherSheet, setOtherSheet] = React.useState<number>(0);
	const [skuSelect, setSkuSelect] = React.useState<any>(null);
	const [isTrue, setIsTrue] = React.useState<any>(null);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); };
	const handleClose = () => { setAnchorEl(null); };

	const itemEdit: any = {
		['Nombre']: 'row?.products.description',
		['Desc. Detalle']: 'row?.products.productDetailDescription',
		['Fabricante']: 'row?.products.manufacturerIdIngram',
		['SubCategoria']: 'row?.products.categoriesIdIngram',
		['height']: 'row?.products.height',
		['width']: 'row?.products.width',
		['length']: 'row?.products.length',
		['weight']: 'row?.products.weight',
	};
	const Urlingram: any = {
		['co']: 'https://co.ingrammicro.com/_layouts/CommerceServer/IM/ProductDetails.aspx?id=',
		['mx']: 'https://mx.ingrammicro.com/_layouts/CommerceServer/IM/ProductDetails.aspx?id='
	};
	const dispatch = useDispatch<any>();

	useEffect(() => {
		const columns: any = rowp.map((row: any) => brs.map((column: any) => eval(column.id) > 0 ? column.label : undefined))[0];
		setBrDisplay(columns);
	}, [rowp,brs]); 

	/* useEffect(() => {
		const columns: any = rowp.map((row: any) => brs?.map((column: any) => eval(column.id) > 0 ? column.label : undefined))[0];
		setBrDisplay(columns);
	}, [brs]); */	

	function sendData(values: any, sku: any) {
		if ( itemEdit[Object.keys(values)[0]] ) {
			const field: String[] = itemEdit[Object.keys(values)[0]].split('.');
			values = { id: field[field.length - 1], value: values[Object.keys(values)[0]] };
		}
		//values['sku'] = [...checkedBatch, ...[sku]].filter((m: any) => m != 'all');

		if (['excludeSyncShop','sendTitleDescription','sendImages','sendSheets'].includes(values.id)) {
			values['sku'] = [...checkedBatch, ...[sku?.sku]].filter((m: any) => m != 'all');
		} else {
			values['sku'] = [...checkedBatch, ...[sku?.sku], ...sku?.contentsOther?.map((l: any) => l.sku)].filter((m: any) => m != 'all');
		}

		values['user'] = client || (user.user.role == 'SERVICE'?undefined:user?.user?.id);
		dispatch(crudProducts(values));
	}
	
	const getDimensions = (dataString: any) => {

		const data: any = dataString?.join(',');
		let imagesLoad:any = [];
		for (const elem in data) {
			const img = new Image();
			img.src = data[elem];

			img.onload = () => {
				imagesLoad = [...imagesLoad,...[{
					[data[elem]]: {
						height: img.height,
						width: img.width,
					},
				}]];
			};
		}
		return imagesLoad;

	}

	const openInNewTab = (url:any) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	const ListItem = styled('li')(({ theme }) => ({
		margin: theme.spacing(0.5),
	}));

	const handleChangeBatch = (value: any) => {
		/* let checkedBatch_: any = checkedBatch;
		if (!checkedBatch_.includes(sku)) {
			checkedBatch_.push(sku);			
		} else {
			//checkedBatch_= checkedBatch_.filter((m: any) => m == sku);
		}
		setCheckedBatch(checkedBatch_);
		console.log(checkedBatch_); */
		/* setCheckedBatch([
			...checkedBatch,
			...[sku],
		]);  */
		/* console.log(checkedBatch);
		let newValue: String[] = [];
		if (checkedBatch.includes(value)) {
			newValue = checkedBatch.filter((item: any) => item != value);
			console.log('include',newValue);
		} else {
			newValue = [...checkedBatch, ...[value]];
			console.log('include-not', newValue);
		}
		setCheckedBatch(newValue);
		return; */
	};

	return (
		<React.Fragment>
			{rowp?.map((row: any) => {
				
				/* console.log('after', row.products.images);
				row.products.images = row?.products?.images?.split(',').map((item:any)=>!'bdicentralserver.com'.includes(item)?`${process.env.REACT_APP_API_URL}${item}/${user.user.tokenAppContents}`:item).join(',') ||''
				console.log('before',row.products.images) */
				const expand = (
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
						{user?.user?.super && (
							<Paper
								sx={{
									display: 'flex',
									justifyContent: 'center',
									flexWrap: 'wrap',
									listStyle: 'none',
									p: 0.5,
									m: 0,
								}}
								component='ul'
							>
								{`I-H-J\n${row?.imageCustomer || '0'}-${row?.sheetHtmlCustomer || '0'}-${
									row?.sheetJsonCustomer || '0'
								}\n${row?.imageCustomerLockedCount || '0'}-${row?.sheetHtmlCustomerLockedCount || '0'}-${
									row?.sheetJsonCustomerLockedCount || '0'
								}`}
								{
									<>
										<ItemEdit
											categories={undefined}
											manufacturer={undefined}
											name={`ContentsCount`}
											data={{
												imageCustomer: row?.imageCustomer||0,
												sheetHtmlCustomer: row?.sheetHtmlCustomer||0,
												sheetJsonCustomer: row?.sheetJsonCustomer||0,
												imageCustomerLockedCount: row?.imageCustomerLockedCount||0,
												sheetHtmlCustomerLockedCount: row?.sheetHtmlCustomerLockedCount||0,
												sheetJsonCustomerLockedCount: row?.sheetJsonCustomerLockedCount || 0,												
												imageCustomerAll: false,
												sheetHtmlCustomerAll: false,
												sheetJsonCustomerAll: false,
												imageCustomerLockedCountAll: false,
												sheetHtmlCustomerLockedCountAll: false,
												sheetJsonCustomerLockedCountAll: false												
											}}
											props={{ getValues: (values: any) => sendData(values, row.products) }}
										/>
									</>
								}
							</Paper>
						)}
					</TableCell>
				);
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.sku}>
						{columns.map((column) => {
							if (column.id) {
								const value = eval(column.id);
								return (
									!hideColumn.includes(column.label) && (
										<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>											
											{column.format ? column.format(value) : ((!['sku'].includes(column.label)) ? value : (<Badge badgeContent={row?.shopId?`ShopId:${row?.shopId}`:null} color='success'><Paper style={ {padding:'10px'}}>{value}</Paper></Badge>))}		
											{!['Switch'].includes(column.label) && ['sku'].includes(column.label) && (user?.user?.super || user.user.role == 'CUSTOMER') &&(												
												<>						 
													<Paper
														sx={{
															display: 'flex',
															justifyContent: 'center',
															flexWrap: 'wrap',
															listStyle: 'none',
															p: 0.5,
															m: 0,
														}}
														component='ul'
													>
														<Switch 
															checked={ !row?.excludeSyncShop&&row?.active==='active' } 
															defaultChecked={ !row?.excludeSyncShop&&row?.active==='active' } 
															onClick={(e: any) => { if (row?.active === 'active') { setSkuSelect(row?.sku); sendData({ id: 'excludeSyncShop', value: e.target.checked, skuSelect: row.sku }, row.products); } else { return false; } }}
															size='small' 
														/>
													</Paper>

													{user.user.role == 'SERVICE' && (
														<Paper
															sx={{
																display: 'flex',
																justifyContent: 'center',
																flexWrap: 'wrap',
																listStyle: 'none',
																p: 0.5,
																m: 0,
															}}
															component='ul'
														>
															<div>
																<Button
																	id="demo-customized-button"
																	aria-controls={openMenu ? 'demo-customized-menu' : undefined}
																	aria-haspopup="true"
																	aria-expanded={openMenu ? 'true' : undefined}
																	variant="contained"
																	disableElevation
																	onClick={handleClick}
																	endIcon={<KeyboardArrowDownIcon />}
																>Send
																</Button>
																<StyledMenu
																	id="demo-customized-menu"
																	MenuListProps={{
																		'aria-labelledby': 'demo-customized-button',
																	}}
																	anchorEl={anchorEl}
																	open={openMenu}
																	onClose={handleClose}
																>
																	<MenuItem onClick={(e: any) => { setSkuSelect(row?.sku); sendData({ id: 'sendImages', value: e.target.checked, skuSelect: row.sku }, row.products); handleClose(); }} disableRipple>
																		<EditIcon />
																		Send Images
																	</MenuItem>
																	<MenuItem onClick={(e: any) => { setSkuSelect(row?.sku); sendData({ id: 'sendSheets', value: e.target.checked, skuSelect: row.sku }, row.products); handleClose(); }} disableRipple>
																		<FileCopyIcon />
																		Send Sheets
																	</MenuItem>
																	<Divider sx={{ my: 0.5 }} />
																	<MenuItem onClick={(e: any) => { setSkuSelect(row?.sku); sendData({ id: 'sendTitleDescription', value: e.target.checked, skuSelect: row.sku }, row.products); handleClose(); }} disableRipple>
																		<ArchiveIcon />
																		Send Titles & Description
																	</MenuItem>
																	{/* <MenuItem onClick={handleClose} disableRipple>
															<MoreHorizIcon />
															More
															</MenuItem> */}
																</StyledMenu>
															</div>
														</Paper>)
													}
												</>																
											)}										
											{(user.user.role == 'SERVICE' && user.user.super) && (
												< Paper
														sx={{
															display: 'flex',
															justifyContent: 'center',
															flexWrap: 'wrap',
															listStyle: 'none',
															p: 0.5,
															m: 0,
														}}
														component='ul'
												>
											{!['Switch'].includes(column.label) && (
												<>
													{user?.user?.super && ['sku'].includes(column.label) && (<><LinkIcon style={{ fontSize: 'medium', cursor: 'pointer' }} onClick={() => openInNewTab(`${Urlingram[row?.products?.country.toLowerCase()]}${row?.products?.sku}`)} /></>)}
													{user?.user?.super && ['Nombre'].includes(column.label) && (<><LinkIcon style={{ fontSize: 'medium', cursor: 'pointer' }} onClick={() => openInNewTab(`https://www.google.com/search?q=${row?.products.description}`)} /></>)}
													{user?.user?.super && ['Desc. Detalle'].includes(column.label) && (<><LinkIcon style={{ fontSize: 'medium', cursor: 'pointer' }} onClick={() => openInNewTab(`https://www.google.com/search?q=${row?.products.productDetailDescription}`)} /></>)}
													{user?.user?.super && ['Modelo'].includes(column.label) && (<><LinkIcon style={{ fontSize: 'medium', cursor: 'pointer' }} onClick={() => openInNewTab(`https://www.google.com/search?q=${row.products.mfrpartnumber}`)} /></>)}
												</>
											)}
											{user?.user?.super && !['Switch'].includes(column.label) && Object.keys(itemEdit).includes(column.label) && (
												<>
													{
													(user.user?.category) 
													?
													(
														(!['Fabricante', 'SubCategoria'].includes(column.label))?
														<ItemEdit
															categories={categories}
															manufacturer={manufacturer}
															name={column.label}
															data={eval(itemEdit[column.label])}
															props={{ getValues: (values: any) => sendData(values, row.products) }}
														/>
														:undefined
													)
													:(
														<ItemEdit
															categories={categories}
															manufacturer={manufacturer}
															name={column.label}
															data={eval(itemEdit[column.label])}
															props={{ getValues: (values: any) => sendData(values, row.products) }}
														/>
													)
													}
												</>
											)}
											</Paper>)
											}
										</TableCell>
									)
								);
							} else {
								switch (column.component) {
									case 'Switch': {
										return ( (user?.user?.super || user.user.role == 'CUSTOMER') &&  !user.user?.category && (<TableCell><Switch checked={ checkedBatch.includes(row.sku) } defaultChecked={false} onClick={(e:any) => selected?.getValues(row.sku)} size='small' /></TableCell>))
									}
									case 'DialogImages': {
										return (
											<TableCell>
												<ViewImages													
													mfrpartnumber={row?.products?.mfrpartnumber}
													name={``}
													data={row?.products?.images || row?.products?.contentsOther[otherImage]?.images}
													props={{ getValues: (values: any) => sendData(values, row.products) }}
													user={user}
													//dimensions={getDimensions(row?.products?.images)}
												/>
												{
												/* <CrudUser
													props={{ getValues: (values: any) => sendData(values) }}
													name={`Actualizar Usuario`}
													role={roleUser}
													data={row}
													profile={profile}
													categories={categories}
													manufacturer={manufacturer}
												/> */
												}
												{row?.products?.contentsOther.length > 0 && user.user.role == 'SERVICE' && user.user.super && (
													<Paper
														sx={{
															color: row?.products?.images?.length > 10 ? undefined : 'red',
															display: 'flex',
															justifyContent: 'center',
															flexWrap: 'wrap',
															listStyle: 'none',
															p: 0.5,
															m: 0,
														}}
														component='ul'
													>
														{row?.products?.contentsOther.map((value: any, index: number) => (
															<LinkIcon
																key={index}
																style={{ fontSize: 'medium', cursor: 'pointer' }}
																onClick={() => setOtherImage(index)}
															/>
														))}
													</Paper>
												)}
											</TableCell>
										);
									}
									/* case 'Images': {
										return (
											<>
											<img height='50px' srcSet={row?.products?.images?.split(',')[0]||undefined} src={row?.products?.images?.split(',')[0]||undefined} alt={``} loading='lazy' />
											</>
										)
									} */
									case 'DialogSheets': {
										return (
											<TableCell>
												<ViewSheets
													listPrimaryAttribute={row?.products?.listPrimaryAttribute}
													mfrpartnumber={row?.products?.mfrpartnumber}
													sku={row?.products?.sku}
													name={``}
													data={
														row?.products?.technicalSpecifications ||
														row?.products?.contentsOther[otherSheet]?.technicalSpecifications
													}
													props={{
														getValues: (values: any) => sendData(values, row.products),
														putPrimaryAttribute: (values: any) => sendData(values, row.products),
													}}
													user={user}
												/>
												{row?.products?.listPrimaryAttribute?.length > 0  && <PrimaryAttribute listPrimaryAttribute={ row?.products?.listPrimaryAttribute} />}
												{/* <CrudUser
													props={{ getValues: (values: any) => sendData(values) }}
													name={`Actualizar Usuario`}
													role={roleUser}
													data={row}
													profile={profile}
													categories={categories}
													manufacturer={manufacturer}
												/> */}
												{row?.products?.contentsOther.length > 0 && user.user.role == 'SERVICE' && user.user.super && (
													<Paper
														sx={{
															color: row?.products?.technicalSpecifications?.length > 100 ? undefined : 'red',
															display: 'flex',
															justifyContent: 'center',
															flexWrap: 'wrap',
															listStyle: 'none',
															p: 0.5,
															m: 0,
														}}
														component='ul'
													>
														{row?.products?.contentsOther.map(
															(value: any, index: number) =>
																value.technicalSpecifications && (
																	<LinkIcon
																		key={index}
																		style={{ fontSize: 'medium', cursor: 'pointer' }}
																		onClick={() => setOtherSheet(index)}
																	/>
																)
														)}
													</Paper>
												)}
											</TableCell>
										);
									}
									default: {
										return expand;
									}
								}
							}
						})}
					</TableRow>
				);
			})}
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant='h6' gutterBottom component='div'>
								Dimensiones y Peso
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										{columnshistoryDimPeso.map((column) => (
											<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rowp.map((row) => {
										return (
											<TableRow tabIndex={-1} key={row.sku}>
												{columnshistoryDimPeso.map((column) => {
													const value = eval(column.id);
													return (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
															{user?.user?.super && Object.keys(itemEdit).includes(column.label) && (
																<ItemEdit
																	categories={undefined}
																	manufacturer={undefined}
																	name={column.label}
																	data={eval(itemEdit[column.label])}
																	props={{ getValues: (values: any) => sendData(values, row.products) }}
																/>
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
						<Box sx={{ margin: 1 }}>
							<Typography variant='h6' gutterBottom component='div'>
								Almacenes
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										{brs?.map(
											(column:any) =>
												brDisplay.includes(column.label) && (
													<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
														{column.label}
													</TableCell>
												)
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{rowp.map((row: any) => {
										return (
											<TableRow tabIndex={-1} key={row.sku}>
												{brs?.map((column:any) => {
													const value = eval(column.id);
													/* if (value==0) {
														setBrDisplay({ ...brDisplay, [column.label]:value });
													} */
													return brDisplay.includes(column.label) && (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

interface searchProps {
	value?: any;
	id?: any;
}

export default function Products() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [checkedBatch, setCheckedBatch] = React.useState<String[]>([]);	

	const [filters, setFilters] = React.useState({
		createdAt: null,
		updatedAt: null,
		price: null,
		inventory: null,
		manufacturer: [],
		categories: [],
		user:null
	});
	
	const [open, setOpen] = React.useState(false);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();

	const { skusEmptyImage, data, length, errors, report, reportStatus, brs } = useSelector((state: ApplicationState) => state.products);
	const users  = useSelector((state: ApplicationState) => state.user);
	let userLocal: UserLogin = loadState();
	const manufacturer = useSelector((state: ApplicationState) => state.manufacturer);
	const categories = useSelector((state: ApplicationState) => state.categories);
	const filtersGlb = useSelector((state: ApplicationState) => state.filters);
	const user = useSelector((state: ApplicationState) => state.user.data) || {};
	const [hideColumn, setHideColumn] = React.useState<String[]>(['Creado', 'Actualizado', 'Precio', 'Categoria', `${(!user?.user?.super && user.user.role == 'SERVICE') ? 'Switch':undefined }` ]);
	const [hideLabel, setHideLabel] = React.useState<String[]>(['Switch']);

	const [root, setRoot] = useState('');
	const [fileTree, setFileTree] = useState({images:null});
	const [mappedFileItems, setMappedFileItems] = useState<any>({});
	const [currentSelectFile, setCurrentSelectFile] = useState<string | null | undefined>(null);

	let [imagesBatch, totaImagesBatch] = useState(0);

	const mapUsers = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.email || item?.email,
					title: item?.email,
					id: item?.id || 0,
					header: !item?.email,
				};
			}) || []
		);
	};

	const mapManufacturer = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.name.split(' ')[0].toUpperCase(),
					title: item?.name,
					id: item?.name,
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const mapFilter = (value: any) => {
		return !Array.isArray(value) ? value : value?.map((elem: any) => elem.id);
	};

	function handleChangeHide(value: string) {
		let newValue: String[] = [];
		if (hideColumn.includes(value)) {
			newValue=hideColumn.filter((item:any)=>item!=value);
		} else {
			newValue = [...hideColumn,...[value]]
		}
		setHideColumn(newValue);
	}

	function serachData({ id, value }: searchProps) {
		setFilters({
			...filters,
			[id]:value,
		});
		dispatchThunk(putFilter({ id: 'products', value: {[id]:value} }));
	}

	useEffect(() => {
		// 👇️ run a function when the component unmounts 👇️
		return () => {
			dispatchThunk(putFilter({ id: 'clear', value: undefined }));
		};
	}, [dispatchThunk]);

	useEffect(() => {
		dispatchThunk(putLoading(false));
		setCheckedBatch([]);
	}, [dispatchThunk, data]);

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, manufacturer]);

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, categories]);

	useEffect(() => {
		if (reportStatus) {
			dispatchThunk(putLoading(false));
			var blob = new Blob([report], {
				type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
			});
			// Programatically create a link and click it:
			var a = document.createElement('a');
			a.href = URL.createObjectURL(blob);
			a.download = 'Report.xlsx';
			a.click();
			dispatchThunk(putReport(false));
		}
	}, [dispatchThunk,report, reportStatus]);
	
	useEffect(() => {		
		dispatchThunk(putLoading(true));
		dispatch(
				getManufacturer({
					search: null
				})
		);		
		dispatch(
			getUsers({
				profile: 'CUSTOMER',
				filters: null,
				rowsPerPage: 1000,
				page: 0,
			})
		);
		dispatch(
			getBrs()
		);
	}, [dispatchThunk, dispatch]);

	useEffect(() => {
		let userId: any = 0;
		if (user.user.role == 'SERVICE') {userId =  filters?.user || 0}
		if (user.user.role == 'CUSTOMER') {	userId =  user?.user?.id }
		dispatchThunk(putLoading(true));
		dispatch(
			getCategoriesSearch({
				search: null,
				idSegmentarIngram: userId,
			})
		);		
	}, [ dispatchThunk, dispatch, filters?.user,user?.user] );	

	useEffect(() => {
		if (errors?.code) {
			dispatchThunk(userReset());
		} 
	}, [dispatchThunk, errors]);

	useEffect(() => {
		setPage(0);
	}, [dispatchThunk, dispatch, filtersGlb, rowsPerPage]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getProducts({
				filters: filtersGlb,
				rowsPerPage: rowsPerPage,
				page: page,
			})
		);
	}, [dispatchThunk, dispatch, page, rowsPerPage, filtersGlb]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeProductsReport = () => {
		dispatchThunk(putLoading(true));
		dispatchThunk(putReport(false));
		dispatch(
			getProductsReport({
				report: 'excel',
				filters: filtersGlb,
			})
		);
	};	

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	function sendData(values: any) {
		//serachData({ id: 'user', value: values });
		setFileTree(values);
		values['user'] = filters?.user;
		dispatchThunk(putFilter({ id: 'products', value: values }));
	}

	async function sendDataBatchImages(values: any) {
		return await dispatch(crudProducts(values));
	}	

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.text.secondary,
	}));

	const handleChangeBatch = (value: any | null) => {
		setCheckedBatch(checkedBatch.includes(value) && checkedBatch.filter((item: any) => item != value) || [...checkedBatch, ...[value]]);
	};

	const handleChangeAllBatch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let dataSelect: any = data?.map((item: any) => item.sku);
		setCheckedBatch((e.target.checked && [...dataSelect,...['all']]) || []);
	};

	function waitForImage(blob: any) {
		return new Promise((res: any, rej: any) => {
			let reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				let base64String = reader?.result?.toString().replace(/^data:(.*,)?/, '');
				return res(base64String);
			};
			reader.onerror = () => rej(blob);
		});
	}

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			{/* <Grid container spacing={1} columns={16}>
				<Grid item xs={2}>
					<Item>
						<DatePicker
							props={{
								value: filters?.createdAt,
								id: 'createdAt',
								title: 'Creado',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={2}>
					<Item>
						<DatePicker
							props={{
								value: filters?.updatedAt,
								id: 'updatedAt',
								title: 'Actualizado',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={1}>
					<Item>
						<TextNumberBasic
							props={{
								value: filters?.price,
								id: 'price',
								title: 'Precio',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={1}>
					<Item>
						<TextNumberBasic
							props={{
								value: filters?.inventory,
								id: 'inventory',
								title: 'Inventario',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={4}>
					<Item>
						
						<AutocompletCheckBoxGroup
							props={{
								group: true,
								data: mapManufacturer(manufacturer.datasearch),
								value: filters?.manufacturer,
								maxWidth: 900,
								id: 'manufacturer',
								title: 'Fabricantes',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
				<Grid item xs={6} sx={{ zIndex: 'tooltip' }}>
					<Item>
						
						<AutocompletCheckBoxGroup
							props={{
								group: true,
								data: mapCategories(categories.datasearch),
								value: filters?.categories,
								maxWidth: 900,
								id: 'categories',
								title: 'Categorias',
								onSearch: (search: searchProps) => serachData(search),
							}}
						/>
					</Item>
				</Grid>
			</Grid> */}
			<FormControl sx={{ float: 'left', m: 1, minWidth: 40 }} size='small'>
				<FilterProducts
					props={{ getValues: (values: any) => sendData(values) }}
					data={undefined}
					name='Filtros'
					categories={categories}
					manufacturer={manufacturer}
					/* users={users}*/
					user={user}
				/>
			</FormControl>
			<FormControl sx={{ float: 'left', m: 1, minWidth: 40 }} size='small'>
				{user?.user?.super && user.user.role == 'SERVICE' && fileTree?.images == 'notimages' && (
						<Badge badgeContent={imagesBatch} color='success'>
							<IconButton aria-label='toggle visibility'>
								<FolderInput
								onChange={async (event: any) => {
										totaImagesBatch(0);
										const files = event.target.files || {};
										const tempedMappedFiles: any = {};
										const mappedFiles = Object.values(files).map((item: any) => {
											tempedMappedFiles[item.webkitRelativePath] = item;
											return {
												name: item.name,
												webkitRelativePath: item.webkitRelativePath,
											};
										});

										const template = convertListFileToObjectParentTree(mappedFiles);
										setRoot(Object.keys(template)[0]);

										const value = readTemplate(template, {});
										const filesUpload: any = Object.values(value)
											.filter((d: any) => !d.isFolder)
											.map((d: any) => d.data);

										let urlIamgesSkus: any = {};

										for (let file in filesUpload) {
											const nameFile: any = filesUpload[file].split('/');
											const skuName: any =
												skusEmptyImage?.filter((i: any) => nameFile[nameFile.length - 1].includes(i)&&!nameFile[nameFile.length - 1].includes('pdf')) || [];

											if (skuName?.length <= 0) continue;

											if (urlIamgesSkus[skuName[0]]) {
												urlIamgesSkus[skuName[0]] = [...urlIamgesSkus[skuName[0]], ...[filesUpload[file]]];
											} else {
												urlIamgesSkus[skuName[0]] = [filesUpload[file]];
											}
										}

										const listSkus: any = Object.keys(urlIamgesSkus);
									
										let countDecre: number = listSkus.length;
										totaImagesBatch(listSkus.length);
									
										for (let sku in listSkus) {
											const filesName: any = urlIamgesSkus[listSkus[sku]];
											let uploadImages: any = [];

											for (let urlUpload in filesName) {
												await fetch(URL.createObjectURL(tempedMappedFiles[filesName[urlUpload]]))
													.then(async (res) => await res.blob())
													.then(async (blob: any) => {
														const base64String: any = await waitForImage(blob);
														uploadImages = [...uploadImages, ...[{ dataURL: base64String }]];
													});
											}
											
											const res: any = await sendDataBatchImages({
												id: 'images',
												sku: [listSkus[sku]],
												value: uploadImages,
												upload: true,
											});

											countDecre = countDecre-1;
											totaImagesBatch(countDecre);
										}									
									}}
								/>
							</IconButton>
						</Badge>
				)}
			</FormControl>
			<FormControl sx={{ float: 'left', m: 1, minWidth: 120 }} size='small'>
				<div>
					<Checkbox
						//checked={checkedCat}
						//onClick={handleChangeCat2}
						onClick={() => {
							handleChangeHide('Creado');
						}}
						id='creado'
						//{...methods.register('segmentarIngram')}
						//checked={methods.watch('segmentarIngram')}
					/>
					Creado
				</div>
			</FormControl>
			<FormControl sx={{ float: 'left', m: 1, minWidth: 120 }} size='small'>
				<div>
					<Checkbox
						//checked={checkedCat}
						//onClick={handleChangeCat2}
						onClick={() => {
							handleChangeHide('Actualizado');
						}}
						id='actualizado'
						//{...methods.register('segmentarIngram')}
						//checked={methods.watch('segmentarIngram')}
					/>
					Actualizado
				</div>
			</FormControl>
			<FormControl sx={{ float: 'left', m: 1, minWidth: 120 }} size='small'>
				<div>
					<Checkbox
						//checked={checkedCat}
						//onClick={handleChangeCat2}
						onClick={() => {
							handleChangeHide('Precio');
						}}
						id='precio'
						//{...methods.register('segmentarIngram')}
						//checked={methods.watch('segmentarIngram')}
					/>
					Precio
				</div>
			</FormControl>
			<FormControl sx={{ float: 'left', m: 1, minWidth: 120 }} size='small'>
				<div>
					<Checkbox
						//checked={checkedCat}
						//onClick={handleChangeCat2}
						onClick={() => {
							handleChangeHide('Categoria');
						}}
						id='categoria'
						//{...methods.register('segmentarIngram')}
						//checked={methods.watch('segmentarIngram')}
					/>
					Categoria
				</div>
			</FormControl>

			<FormControl sx={{ float: 'right', m: 1, minWidth: 120 }} size='small'>
				<div>
					<Button onClick={handleChangeProductsReport}>Crear Reporte</Button>
				</div>
			</FormControl>

			{user.user.role == 'SERVICE' && !user?.user?.category && (
				<FormControl sx={{ float: 'right', marginTop: { sm: '.5rem' }, width: '20%' }} size='small'>
					<Autocomplete
						id='user'
						isOptionEqualToValue={(option, value) => option.label === value.label}
						options={[
							...[{ id: 0, label: 'Selecciona...' }],
							...(users.users?.map((m: any) => {
								return { id: m.id, label: m.email };
							}) || []),
						]}
						sx={{
							'& .MuiInputBase-input': {
								height: '.01px',
							},
						}}
						renderInput={(params) => <TextField {...params} label='Cliente' />}
						value={
							users.users
								?.filter((k: any) => k.id == filters?.user)
								.map((m: any) => {
									return { id: m.id, label: m.email };
								})[0] || { id: 0, label: 'Selecciona...' } ||
							undefined
						}
						onChange={(e: any, value: any) => {
							serachData({ id: 'user', value: value?.id || 0 });
						}}
					/>
				</FormControl>
			)}

			<TableContainer sx={{ maxHeight: 400 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map(
								(column) =>
									!hideColumn.includes(column.label) && (
										<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
											{/* user?.user?.super && */ ['Switch'].includes(column.component || '') && (
												<Switch
													checked={checkedBatch.includes('all')}
													onClick={(e: any) => handleChangeAllBatch(e)}
													size='small'
												/>
											)}
											{!hideLabel.includes(column.label) && column.label}
										</TableCell>
									)
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.length
							? data.map((row: any) => {
									return (
										<Row
											brs={brs}
											key={row.sku}
											rowp={[row]}
											hideColumn={hideColumn}
											user={user}
											categories={categories.datasearch}
											manufacturer={manufacturer.datasearch}
											client={filters?.user}
											selected={{ getValues: (values: any) => handleChangeBatch(values) }}
											checkedBatch={checkedBatch}
										/>
									);
							  })
							: !data && (
									<CircularProgress
										disableShrink
										size={70}
										sx={{
											position: 'fixed',
											left: '50%',
											top: '50%',
											transform: 'translate(-50%, -50%)',
											zIndex: 5,
										}}
									/>
							  )}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={
					user.user.role == 'SERVICE' && user.user.super ? [10, 25, 100, 300, 500, 1000, 2000, 4000, 6000,8000,10000] : [10, 25, 100, 300]
				}
				component='div'
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}