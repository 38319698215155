import { Reducer } from "redux";
import { OrdersActionTypes, OrdersState } from './types';
import { userReset } from '../user/action';

export const initialState: OrdersState = {
	length: 1,
	data: undefined,
	datasearch: undefined,
	dataresponse:undefined,
	errors: { code: undefined, message: undefined },
	loading: false,
};

const reducer: Reducer<OrdersState> =  (state = initialState, action)  => {
	switch (action.type) {
		case OrdersActionTypes.GET_ORDERS: {
			return { ...state, datasearch:action.payload.orders, data: action.payload.orders, length: action.payload.total, errors: action.payload?.orders ? undefined : { code:500, message:undefined } };
		}
		case OrdersActionTypes.GET_ORDERS_FAILURE: {	
			return { ...state,  datasearch: undefined, data: undefined, length: 1, errors: { code:action.payload, message:undefined } };
		}
		case OrdersActionTypes.POST_CRUD_ORDERS: {
			return { ...state, dataresponse:action.payload.response, errors: action.payload?.orders ? undefined : { code:500, message:undefined } };
		}
		case OrdersActionTypes.POST_CRUD_ORDERS_FAILURE: {	
			return { ...state,  dataresponse: undefined, errors: { code:action.payload, message:undefined } };
		}
		default: {
			return state;
		}
	}
};

export { reducer as OrdersReducer };