import { Reducer } from "redux";
import { CategoriesActionTypes, CategoriesState } from './types';
import { userReset } from '../user/action';
import { saveState, updateToken } from '../../utils/localstorage';


export const initialState: CategoriesState = {
	length: 1,
	lengthIngram: 1,
	datasearch: undefined,
	data: undefined,
	dataIngram: undefined,
	dataIngramSearch: undefined,
	errors: { code: undefined, message: undefined },
	loading: false,
};

const reducer: Reducer<CategoriesState> = (state = initialState, action) => {
	if (action.payload?.token) updateToken(action.payload.token);
	switch (action.type) {
		case CategoriesActionTypes.GET_CATEGORIES: {
			return { ...state, data: action.payload.categories, length: action.payload.total, errors: action.payload?.categories ? undefined : { code:500, message:undefined } };
		}
			case CategoriesActionTypes.PUT_CATEGORIES_INGRAM: {
			return {...state, dataIngramSearch: state.dataIngramSearch?.map((itemCat) => { if (!action.payload.categories.map((item: any) => item.name).includes(itemCat.name)) { return itemCat; }else{return action.payload.categories[0];} })};
		}
		case CategoriesActionTypes.PUT_CATEGORIES: {
			return {...state, data: state.data?.map((itemCat) => { if (!action.payload.categories.map((item: any) => item.name).includes(itemCat.name)) { return itemCat; }else{return action.payload.categories[0];} })};
		}
		case CategoriesActionTypes.PUT_CATEGORIES_ADD: {
			return { ...state, datasearch: [action.payload.categories, ...state.datasearch||[]], loading:false };
		}
			case CategoriesActionTypes.GET_CATEGORIES_INGRAM: {
			return { ...state, dataIngram: action.payload.categories, lengthIngram: action.payload.total, errors: action.payload?.categories ? undefined : { code:500, message:undefined } };
		}
		case CategoriesActionTypes.GET_CATEGORIES_INGRAM_SEARCH: {
			return { ...state, dataIngramSearch: action.payload.categories, lengthIngram: action.payload.total, errors: action.payload?.categories ? undefined : { code:500, message:undefined } };
		}
		case CategoriesActionTypes.GET_CATEGORIES_SEARCH: {
			return { ...state, datasearch: action.payload.categories, length: action.payload.total, errors: undefined };
		}
		case CategoriesActionTypes.GET_CATEGORIES_FAILURE: {
			return { ...state, data: undefined, length: 1, errors: { code: action.payload, message: undefined } };
		}
		case CategoriesActionTypes.PUT_CATEGORIES_FAILURE: {
			return { ...state, data: undefined, length: 1, errors: { code: action.payload, message: undefined } };
		}
		default: {
			return state;
		}
	}
};

export { reducer as CategoriesReducer };