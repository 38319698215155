import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const DisabledBackground = styled(Box)({
	width: '100%',
	height: '100%',
	position: 'fixed',
	background: '#ccc',
	opacity: 0.3,
	zIndex: 9999,
});

export default function CircularLoading() {
	return (
		<Box sx={{ display: 'flex' }}>
			<CircularProgress
				disableShrink
				size={70}
				sx={{
					position: 'fixed',
					left: '50%',
					top: '50%',
					transform: 'translate(-50%, -50%)',
					zIndex: 99999,
				}}
			/>
			<DisabledBackground />
		</Box>
	);
}

