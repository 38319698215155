import { HistoryAccessActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { HistoryAccessSearch } from "../historyaccess/types";
import axios from "axios";

import { userReset } from '../user/action';

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<HistoryAccessSearch>>;

export const getHistoryAccess: ActionCreator<ThunkAction<void, ApplicationState, HistoryAccessSearch, Action<HistoryAccessSearch>>> = (historyaccess) => {
return (dispatch: Dispatch) => {
	try {
		return axios
			.post<HistoryAccessSearch>(REACT_APP_API_URL + '/profile/history', historyaccess, {
				headers: {
					Accept: 'application/json',
					Authorization: getAuthorization(),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				return dispatch({ type: HistoryAccessActionTypes.GET_HISTORYACCESS, payload: response?.data });
			})
			.catch((err) => {
				return dispatch({ type: HistoryAccessActionTypes.GET_HISTORYACCESS_FAILURE, payload: 500 });
			});        
	} catch (e) {
		return dispatch({ type: HistoryAccessActionTypes.GET_HISTORYACCESS_FAILURE, payload: 500 });
	}
};
};
