const { APP_VERSION = '1.0' } = process.env;
const STORAGE_KEY = `__SERIALIZED_STATE_TREE_v${APP_VERSION}__`;
import { UserLogin } from '../store/user/types';
const CryptoTS = require('crypto-ts');


const { KEYSTORE = 'AQ3456GGRFDGDGD12345XZCXHS5G5GF6WGEFVDYF56W35VF6FV6GRVFF6454F5F65VF46VFGGSVDGHFVH' } = process.env;

export function  getAuthorization() {
	const userState: UserLogin = loadState();
	return `Bearer ${userState.token}`;
};

export function  getRol() {
	const userState: UserLogin = loadState();
	return userState.user.role;
};

export function  updateToken(token:string) {
	const userState: UserLogin = loadState();
	userState.token = token;
	saveState(userState);
};


export function saveState<T = object>(storeState: T): boolean {
	if (!localStorage) {
		return false;
	}
	try {
		const serializedState = JSON.stringify(storeState);
		localStorage.setItem(STORAGE_KEY, CryptoTS.AES.encrypt(serializedState, KEYSTORE));
		return true;
	} catch (error) {
		throw new Error('store serialization failed');
	}
}

export function clearState<T = object>(storeState: T): boolean {
	if (!localStorage) {
		return false;
	}

	try {
		const serializedState = JSON.stringify(storeState);
		localStorage.setItem(STORAGE_KEY, CryptoTS.AES.encrypt(serializedState, KEYSTORE));
		return true;
	} catch (error) {
		throw new Error('store serialization failed');
	}
}

export function loadState<T = object>(): T | UserLogin {
	if (!localStorage) {
		return {
			user: {
				id: String(),
				email: String(),
				role: String(),
				countryCode: String(),
				segmentarIngram: String(),
				tokenAppContents: String()
			},
			token: String(),
			signup: false,
		};
	}

	try {
		const serializedState = localStorage.getItem(STORAGE_KEY);
		if (serializedState == null) {
			return {
				user: {
					id: String(),
					email: String(),
					role: String(),
					countryCode: String(),
					segmentarIngram: String(),
					tokenAppContents: String(),
				},
				token: String(),
				signup: false,
			};
		}
		const bytes = CryptoTS.AES.decrypt(serializedState?.toString(), KEYSTORE);
		return JSON.parse(bytes.toString(CryptoTS.enc.Utf8));
	} catch (error) {
		throw new Error('store deserialization failed');
	}
}
