import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function createData(endpoint: string, update: string, type:string, action: string, reque: string, title: string, def: string, link: string) {
	return { endpoint, update, type,action, reque, title, def, link };
}

const rows = [
	createData('POST api/prices', 'Abr/2024', 'Boolean', 'Request Body', 'Opcional [Recomendado, Solo para verificar precio y stock al colocar una orden]', 'currentIngram', 'Si se presenta el atributo se realiza una consulta en tiempo real del los productos solicitados', 'se genera solo si "search" existe'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'report', '"json ó excel" genera un reporte de productos para el usuario', 'Si se omite, se segmenta el resultado'),
	createData('POST api/prices', 'Abr/2024', 'Object', 'Request Body', 'Si', 'filters', 'Objeto filters, filtra la respuesta', 'Necesario para accesos a recursos'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'search', 'Campo comodin, busca en base de datos el texto. puede enviar SKU\'s VPN, Vendors Numbers separados por coma', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Date', 'Request Body', 'Opcional [Productos Nuevos]', 'createdAt', 'Fecha de creación del producto, si fecha es igual o mayor se muestra, para obtener "Productos Nuevos" "Formato de fecha: 2023-12-27" restar dos dias a fecha actual', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Date', 'Request Body', 'Opcional', 'updatedAt', 'Fecha de actualización de producto, si fecha es igual o mayor se muestra, "Formato de fecha: 2023-12-27"', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'price', 'Precio del producto, si precio es mayor ó igual. se muestra', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'inventory', 'Inventario del producto, si inventario es igual o mayor, se muestra', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'image', 'Producto con imagen valor del campo "images", Producto sin imagen valor del campo "notimages"', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'sheet', 'Producto con ficha técnica valor del campo "sheets", Producto sin ficha técnica valor del campo "notsheets"', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Boolean', 'Request Body', 'Si', 'active', 'Productos activos Precio > 0 y Inventario > 0 y Autorizado para venta web valor de campo "active"', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Boolean', 'Request Body', 'Si', 'inactive', 'Productos inactivos no autorizados ó fuera de stock, valor del campo "inactive"', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Array [String]', 'Request Body', 'Opcional', 'categories', 'Lista o array de categorias a buscar [\'Accesorios\',\'Componentes\'] segun categorias de endpoint api/categories','padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Array [String]', 'Request Body', 'Opcional', 'manufacturer', 'Lista o array de fabricantes a buscar [\'Acer\',\'Lenovo\'] segun fabricantes de endpoint api/manufacturer', 'padre del campo filters'),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'rowsPerPage', 'Número de renglones por página', 'si "report" no existe'),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'page', 'Página del segmentado a mostrar', 'si "report" no existe'),

	createData('POST api/prices', 'Abr/2024', 'Array [Object products]', 'Response', '-', 'products', 'Lista de precios para productos solicitados', ''),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram', 'Si "currentIngram" existe, se muestra resultado actual para producto desde ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.sku', 'Identificador unico ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.price', 'Precio ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.currencyCode', 'Tipo de moneda', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.inventory', 'Inventario disponible ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.branch', 'Lista de almacenes disponibles ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.branch[].quantityAvailable', 'Inventario disponible ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.branch[].warehouseId', 'Identificador unico del almacen', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.branch[].location', 'Nombre del almacen ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial', 'Lista de precios especiales "Descuentos" ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial[].specialPriceQty', 'Cantidad disponible para precio especial "Descuento" ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial[].specialPriceEnd', 'Fecha de finalizacion del precio especial "Descuento"', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial[].specialPriceEffective', 'Fecha de inicio del precio especial "Descuento ingram"', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial[].specialPriceDiscount', 'Monto $ restado al precio original Precio ingram', 'Datos tiempo real desde ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].ingram.priceSpecial[].specialPriceDaysValidity', 'Dias restantes para finalizar el precio especial', 'Datos tiempo real desde ingram'),

	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].status', 'Estado del producto producto activo para venta web "active", producto inactivo "inactive" ó "ingram" para venta web', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].price', 'Precio del producto Sincronización BDI', ''),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceApply', 'Precio aplicado del producto, dentro del administrador puede configurar utilidad, el precio aplicado es "Precio del producto ingram + utilidad"', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].currencyCode', 'Tipo de moneda', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial', 'Objeto para precio especial "Descuento" BDI', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial.specialPriceQty', 'Cantidad disponible para precio especial "Descuento" BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial.specialPriceEnd', 'Fecha de finalizacion del precio especial "Descuento" BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial.specialPriceEffective', 'Fecha de inicio del precio especial "Descuento ingram" BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial.specialPriceDiscount', 'Monto $ restado al precio original Precio BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].priceSpecial.specialPriceDaysValidity', 'Dias restantes para finalizar el precio especial Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),

	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].inventory', 'Inventario disponible BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].sku', 'Identificador unico ingram', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].updatedAt', 'Fecha de actualización de precio y stock BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].vendorNumber', 'Identificador del fabricante VPN, Vendor Number', 'Dato actualizaciones BDI vs Ingram'),

	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].daysOutStock', 'Dias con Inventario stock Cero, si es menor de cero el producto optimo para venta web BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].br...[branch]', 'Lista de almacenes, br10, br20, ... etc', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].brs', 'Lista de almacenes BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].brs[].id', 'Identificador unico de almacen BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].brs[].name', 'Nombre de almacen BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	createData('POST api/prices', 'Abr/2024', 'String', 'Response', '-', 'products[].brs[].inventory', 'Inventario disponible almacen, BDI Sincronizaciones', 'Dato actualizaciones BDI vs Ingram'),
	
	createData('POST api/prices', 'Abr/2024', 'Number', 'Response', '-', 'totalPages', 'Total de páginas de la solicitud', ''),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Response', '-', 'total', 'Total de páginas de productos', ''),
	createData('POST api/prices', 'Abr/2024', 'Number', 'Response', '-', 'page', 'Página actual', ''),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function BasicTable() {
	const [search, setSearch] = React.useState('');
	const [valueRadio, setValueRadio] = React.useState('');
	const [valueRadioNodos, setValueRadioNodos] = React.useState('Todos');
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValueRadio((event.target as HTMLInputElement).value);
	};
	

	const handleChangeNodos = (event: SelectChangeEvent) => {
		setValueRadioNodos(event.target.value as string);
	};


	return (
		<Paper sx={{ width: '100%' }}>
			<Box>
				<FormControl>
					<TextField
						margin='dense'
						id='search'
						label='Buscar texto'
						type='text'
						variant='standard'
						onChange={(e) => setSearch(e.target.value)}
					/>
				</FormControl>
				<FormControl>
					<InputLabel id='demo-simple-select-label'>Nodos</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						value={valueRadioNodos}
						label='Nodos'
						onChange={handleChangeNodos}
						sx={{ width: 'auto' }}
					>
						<MenuItem value={'Todos'}>Todos</MenuItem>
						<MenuItem value={'products[].'}>products[].</MenuItem>
						<MenuItem value={'products[].ingram.'}>products[].ingram.</MenuItem>
						<MenuItem value={'products[].priceSpecial.'}>products[].priceSpecial.</MenuItem>
						<MenuItem value={'products[].ingram.priceSpecial[].'}>products[].ingram.priceSpecial[].</MenuItem>
						<MenuItem value={'products[].brs[].'}>products[].brs[].</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<FormLabel id='demo-row-radio-buttons-group-label'>Acciones</FormLabel>
					<RadioGroup
						row
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={valueRadio}
						onChange={handleChange}
					>
						<FormControlLabel value='Response' control={<Radio />} label='Response' />
						<FormControlLabel value='Request' control={<Radio />} label='Request' />
						<FormControlLabel value='' control={<Radio />} label='Todo' />
					</RadioGroup>
				</FormControl>
			</Box>
			<TableContainer sx={{ maxHeight: 340 }}>
				<Table stickyHeader aria-label='customized sticky table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>EndPoint</StyledTableCell>
							<StyledTableCell align='right'>Update</StyledTableCell>
							<StyledTableCell align='right'>Tipo</StyledTableCell>
							<StyledTableCell align='right'>Acción</StyledTableCell>
							<StyledTableCell align='right'>Requerido</StyledTableCell>
							<StyledTableCell align='right'>Titulo (Campo en Acción)</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Definición
							</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Enlace
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.filter((r: any) =>
								`${r.reque} ${r.title} ${r.type} ${r.def} ${r.link}`.toLowerCase().includes(search.toLowerCase())
							)
							.filter((r: any) => `${r.action}`.toLowerCase().includes(valueRadio.toLowerCase()))
							.filter((r: any) =>
								valueRadioNodos.replace('Todos','') !== 'products[].'
									? `${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos','').toLowerCase())
									: (`${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos','').toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].ingram.'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].priceSpecial.'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].ingram.priceSpecial[].'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].brs[].'.toLowerCase()))
							)
							.map((row: any) => (
								<StyledTableRow key={row.endpoint} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<StyledTableCell component='th' scope='row'>
										{row.endpoint}
									</StyledTableCell>
									<StyledTableCell align='right'>{row.update}</StyledTableCell>
									<StyledTableCell align='right'>{row.type}</StyledTableCell>
									<StyledTableCell align='right'>{row.action}</StyledTableCell>
									<StyledTableCell align='right'>{row.reque}</StyledTableCell>
									<StyledTableCell align='right'>{row.title}</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.def}
									</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.link}
									</StyledTableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
