import { Reducer } from "redux";
import { FilterActionTypes, FiltersState } from './types';

export const initialState: FiltersState = {
	//filtersGlb: undefined,
};

const reducer: Reducer<FiltersState> = (state = initialState, action) => {
	switch (action.type) {
		case FilterActionTypes.PUT_FILTER: {
			let response: any = null;
			if (['user','search'].includes(action.payload.id)) { response = { ...state, ...{ [action.payload.id]: action.payload.value } } }
			if (action.payload.id == 'clear') { response= {}; }
			if (action.payload.id == 'products') { response = { ...state, ...action.payload.value } }
			if (action.payload.id == 'categories') { response = { ...state, ...action.payload.value } }
			//if (!['clear', 'products', 'categories'].includes(action.payload.id)) { response = { [action.payload.id]: action.payload.value }; }
			return response;
		}
		case FilterActionTypes.PUT_FILTER_FAILURE: {
			return { state:{ } };
		}
		default: {
			return state;
		}
	}
};

export { reducer as FiltersReducer };