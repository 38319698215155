import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts } from '../../store/products/action';
import { getManufacturer } from '../../store/manufacturer/action';
import { getCategories } from '../../store/categories/action';
import { userReset } from '../../store/user/action';

import { Dispatch } from 'redux';

import { ManufacturerState, Manufacturer } from '../../store/manufacturer/types';
import { ApplicationState } from '../../store/index';

import AutocompletCheckBox from './Components/AutocompletCheckBox';
import AutocompletCheckBoxGroup from './Components/AutocompletCheckBoxGroup';
import Select from './Components/Select';
import DatePicker from './Components/DatePicker';
import TextNumberBasic from './Components/TextNumber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { putFilter } from '../../store/filters/action';
import { putLoading } from '../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { ViewImagesManufacturer } from './Dialogs/ViewImagesManufacturer';
import { crudManufacturer } from '../../store/manufacturer/action';
import Checkbox from '@mui/material/Checkbox';


const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
	| 'row?.id'
	| 'row?.name'
	| 'row?.count'
	| 'row?.image'
		/* | 'row?.country' */;

	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{
		id: 'row?.id',
		label: 'Id',
		minWidth: 80,
	},
	{
		id: 'row?.name',
		label: 'Nombre',
		minWidth: 80,
	},
	{
		id: 'row?.count',
		label: 'Products Activos',
		minWidth: 80,
	},
	{
		id: 'row?.image',
		label: 'Imagen',
		minWidth: 80,
	},
	//{ id: 'row?.country', label: 'Ciudad', minWidth: 40 },
];


function Row(props: { activeNotImage: any;  user: any; rowp: Manufacturer[] }) {
	const { rowp, user, activeNotImage } = props;
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch<any>();

	function sendData(values: any, manu: any) {
		values['id'] = 'images';
		values['name'] = manu.name;
		values['idManufacturer'] = manu.id;
		values['activeNotImage'] = activeNotImage;
		console.log(values);
		dispatch(crudManufacturer(values));
	}

	return (
		<React.Fragment>
			{rowp?.map((row: any) => {
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column) => {
							const value = eval(column.id);
							if (column.label != 'Imagen') {
								return (
									<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
										{column.format ? column.format(value) : value}
									</TableCell>
								);
							} else {
								return (
									<TableCell>
										<ViewImagesManufacturer
											name={``}
											data={row?.image}
											props={{ getValues: (values: any) => sendData(values, row) }}
											user={user}
										/>
									</TableCell>
								);
							}
						})}
					</TableRow>
				);
			})}
		</React.Fragment>
	);
}

export default function Manufacturers() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [activeNotImage, setActiveNotImage] = React.useState(false);

	const [open, setOpen] = React.useState(false);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();

	const { data, length, errors } = useSelector((state: ApplicationState) => state.manufacturer);
	const filtersGlb = useSelector((state: ApplicationState) => state.filters);
	const user = useSelector((state: ApplicationState) => state.user.data) || {};


	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, data]);

	useEffect(() => {
		if (errors?.code) {
			dispatchThunk(userReset());
		} 
	}, [dispatchThunk, errors]);

	useEffect(() => {		
		setPage(0);
	}, [dispatchThunk, dispatch, filtersGlb, rowsPerPage]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getManufacturer({
				filters: { ...filtersGlb, ...{ activeNotImage } },
				rowsPerPage: rowsPerPage,
				page: page,
			})
		);
	}, [dispatchThunk, dispatch, page, rowsPerPage, filtersGlb, activeNotImage]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.text.secondary,
	}));

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<div>
				<Checkbox
					onClick={() => {
						setActiveNotImage(!activeNotImage);
					}}
					id='nameAll'
					checked={activeNotImage}
				/>Activos sin imagen
			</div>
			<TableContainer sx={{ maxHeight: 400 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.length
							? data?.map((row) => <Row key={row.id} activeNotImage={activeNotImage} user={user} rowp={[row]} />)
							: !data && (
									<CircularProgress
										disableShrink
										size={70}
										sx={{
											position: 'fixed',
											left: '50%',
											top: '50%',
											transform: 'translate(-50%, -50%)',
											zIndex: 5,
										}}
									/>
							  )}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100, 300]}
				component='div'
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}