import { ListItemButton, ListItemIcon, ListItemText, IconButton, styled } from '@mui/material';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useMemo, useState, useEffect } from 'react';


import { useSelector } from 'react-redux';
import { UserState, UserLogin } from '../../../../store/user/types';

import { ApplicationState } from '../../../../store/index';


import { saveState } from '../../../../utils/localstorage';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { userReset } from '../../../../store/user/action';

export const SignOutRoute = () => {	
	const dispatch = useDispatch<Dispatch<any>>();

	const handleSignOutClick = () => {
		dispatch(userReset());	
		window.location.href = '/';
	};


	return (
		<StyledListItemButton onClick={handleSignOutClick}>
			<ListItemIcon>
				<IconButton size='small'>
					<ExitToApp />
				</IconButton>
			</ListItemIcon>
			<ListItemText primary='Sign Out' />
		</StyledListItemButton>
	);
};

const StyledListItemButton = styled(ListItemButton)`
	position: absolute;
	bottom: 0;
	width: 100%;
`;
