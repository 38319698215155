import { ManufacturerActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { ManufacturerSearch } from "../manufacturer/types";
import axios from "axios";

import { userReset } from '../user/action';

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<any>>;

export const crudManufacturer: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> =
(manufacturer) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<any>(REACT_APP_API_URL + `/manufacturer/crud`, manufacturer, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({
						type: ManufacturerActionTypes.PUT_MANUFACTURER,
						payload: { response: response?.data, manufacturer: manufacturer },
					});
				})
				.catch((err) => {
					console.log(err);
					/* return dispatch({
					type: ProductsActionTypes.PUT_PRODUCTS,
					payload: { error: { message: err.response.data.error, code: err.response.status } },
				}); */
				});
		} catch (e) {
			return dispatch({ type: ManufacturerActionTypes.PUT_MANUFACTURER_FAILURE, payload: 500 });
		}
	};
};

export const getManufacturer: ActionCreator<ThunkAction<void, ApplicationState, ManufacturerSearch, Action<ManufacturerSearch>>> = (manufacturer) => {

return (dispatch: Dispatch) => {
	try {
		return axios
			.post<ManufacturerSearch>(REACT_APP_API_URL + '/manufacturer', manufacturer, {
				headers: {
					Accept: 'application/json',
					Authorization: getAuthorization(),
					'Content-Type': 'application/json',
				},
			})
			.then((response) => {
				return dispatch({ type: ManufacturerActionTypes.GET_MANUFACTURER, payload: response?.data });
			})
			.catch((err) => {
				return dispatch({ type: ManufacturerActionTypes.GET_MANUFACTURER_FAILURE, payload: 500 });
			});        
	} catch (e) {
		return dispatch({ type: ManufacturerActionTypes.GET_MANUFACTURER_FAILURE, payload: 500 });
	}
};
};
