import { Divider, Menu, MenuItem } from '@mui/material';
import { useMemo, useState, useEffect } from 'react';


import { Settings, Preferences, SignOut } from '../../Actions';

import { saveState } from '../../../utils/localstorage';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { userReset, createUser } from '../../../store/user/action';

interface DefaultMenuProps {
	isMenuOpen: boolean;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const DefaultMenu =  ({ isMenuOpen, handleMenuClose, anchorEl }: DefaultMenuProps) => {
	const navigate = useHistory();
	const dispatch = useDispatch<Dispatch<any>>();

	function handleLogout() {		
		dispatch(userReset());
		window.location.href = '/';
	}
	function handlePreferences() {
		return;
	}
	return (
		<Menu anchorEl={anchorEl} id='primary-search-account-menu' keepMounted open={isMenuOpen} onClose={handleMenuClose}>
			{/* <MenuItem onClick={handleMenuClose}>
				<Settings disableTooltip />
				Settings
			</MenuItem>
			<MenuItem onClick={handlePreferences}>
				<Preferences disableTooltip />
				Preferences
			</MenuItem>
			<Divider /> */}
			<MenuItem onClick={handleLogout}>
				<SignOut disableTooltip />
				Sign Out
			</MenuItem>
		</Menu>
	);
};
