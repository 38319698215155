import { Reducer } from "redux";

import { UserActionTypes, UserState } from "./types";

import { saveState, updateToken } from '../../utils/localstorage';
import { writeFileSync } from "fs";
import { stringify } from "querystring";

export const userInit = {
	user: {
		id: String(),
		email: String(),
		role: String(),
		countryCode: String(),
		segmentarIngram: String(),
		tokenAppContents: String(),
	},
	token: String(),
	signup: false,
};

export const initialState: UserState = {
	sandbox: false,
	users: undefined,
	logs: [],
	customers: undefined,
	length: 1,
	errors: { code: undefined, message: undefined },
	productsCount: undefined,
	productsCountCustomer: undefined,
	productsSegment: undefined,
	productsSegmentCount: undefined,
	data: userInit,
	loading: false,
};

const reducer: Reducer<UserState> = (state = initialState, action) => {
	if (action.payload?.token) updateToken(action.payload.token);
    switch (action.type) {
		case UserActionTypes.GET_PRODUCTS_COUNT: {
			return { ...state, productsCount: action.payload.count, productsCountCustomer: action.payload.countCustomer };
		}
		case UserActionTypes.GET_PRODUCTS_SEGMENT: {
			return { ...state, productsSegment: action.payload.products, productsSegmentCount: action.payload.total };
		}
		case UserActionTypes.GET_PRODUCTS_COUNT_FAILURE: {
			return { ...state, loading: false,errors: { code:action.payload.error.code||500, message: action.payload.error.message||undefined }};
		}
		case UserActionTypes.GET_PRODUCTS_SEGMENT_FAILURE: {
			return { ...state, loading: false,errors: { code:action.payload.error.code||500, message: action.payload.error.message||undefined }};
		}
		case UserActionTypes.FETCH_REQUEST: {
			return { ...state, loading: true };
		}
		case UserActionTypes.GET_USERS: {
			return { ...state, loading: false, users: action.payload.users, customers:action.payload?.customers, length: action.payload.total, errors: action.payload?.users ? undefined : { code:500, message:undefined } };
		}
		case UserActionTypes.GET_LOGIN: {
			saveState(action.payload);
			return { ...state, data: action.payload, errors: { code: undefined, message: undefined }, loading: false };
		}
		case UserActionTypes.GET_LOGS: {
			return { ...state, logs: action.payload, errors: { code: undefined, message: undefined }, loading: false };
		}
		case UserActionTypes.REFRESH_LOGIN: {
			return { ...state, data: action.payload, errors: { code: undefined, message: undefined }, loading: false };
		}			
		case UserActionTypes.PUT_USER: {			
			action.payload.user['sandbox'] = state.users?.find((user) => user.id === action.payload.user.id)?.sandbox;
			return { ...state, loading: false,errors:{ code: undefined, message: undefined },users: [action.payload.user, ...state.users?.filter((user) => user.id != action.payload.user.id) || []].sort((a, b) => b.id - a.id) };
		}
		case UserActionTypes.PUT_SANDBOX: {			
			return { ...state, loading: false,errors:{ code: undefined, message: undefined }, sandbox:action.payload };
		}
		case UserActionTypes.PUT_SANDBOX_DATA: {
			return {
				...state,
				users: !action.delete?
				state.users?.map((u: any) => {
					if (u?.sandbox?.length > 0) {
						u.sandbox = u.sandbox?.map((user: any) => (user.email != action.payload.email ? user : action.payload));
					}
					return u;
				}) :				
				state.users?.map((u: any) => {
					if (u?.sandbox?.length > 0) {
						u.sandbox = u.sandbox?.filter((user: any) => user.email != action.payload.email);
					}
					return u;
				})
			};
		}
		case UserActionTypes.PUT_SANDBOX_FAILURE: {			
			return { ...state, loading: false,errors:{ code: undefined, message: undefined }, sandbox:action.payload };
		}
		case UserActionTypes.GET_LOGIN_FAILURE: {
			saveState(userInit);
			return { ...state, data: userInit, errors: { code:201, message: 'Usuario ó contraseña Incorrectos' }, loading: false };
		}
		case UserActionTypes.PUT_USER_FAILURE: {
			return { ...state, loading: false,errors: { code:action.payload.error.code||500, message: action.payload.error.message||undefined }};
		}
		default: {
			return state;
		}
	}
};

export { reducer as UserReducer };