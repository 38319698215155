export function convertListFileToObjectParentTree(list: any[]) {
	const obj:any = {};

	list.forEach((item: { webkitRelativePath: string }) => {
		const splitPath:any = item.webkitRelativePath.split('/');

		if (splitPath.length === 1) {
			obj[splitPath[0]] = splitPath[0];
		} else {
			let tempPointer:any = obj;
			let i = 0;
			while (i < splitPath.length - 1) {
				if (tempPointer[splitPath[i]]) {
					tempPointer = tempPointer[splitPath[i]];
				} else {
					tempPointer[splitPath[i]] = {};
					tempPointer = tempPointer[splitPath[i]];
				}
				i++;
			}
			tempPointer[item.webkitRelativePath] = null;
		}
	});
	return obj;
}

export const readTemplate_ = (template:any, data:any) => {
	for (const [key, value] of Object.entries(template)) {
		//if (value !== null) continue;
		//if (value === null && !"b08ad6".includes(key)) continue;

		data[key] = {
			index: key,
			canMove: true,
			isFolder: value !== null,
			children: value !== null ? Object.keys(value || {}) : undefined,
			data: key,
			canRename: true,
		};

		if (value !== null) {
			readTemplate(value, data);
		}
	}
	return data;
};

export const readTemplate = (template:any, data:any) => {
	for (const [key, value] of Object.entries(template)) {
		data[key] = {
			index: key,
			canMove: true,
			isFolder: value !== null,
			children: value !== null ? Object.keys(value || {}) : undefined,
			data: key,
			canRename: true,
		};

		if (value !== null) {
			readTemplate(value, data);
		}
	}

	return data;
};
