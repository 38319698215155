import { OrdersActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { OrdersSearch } from "../orders/types";
import axios from "axios";

import { userReset } from '../user/action';

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<OrdersSearch>>;

export const getOrders: ActionCreator<ThunkAction<void, ApplicationState, OrdersSearch, Action<OrdersSearch>>> = (orders) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
					.post<OrdersSearch>(REACT_APP_API_URL + '/orders/getIngram', orders, {
						headers: {
							Accept: 'application/json',
							Authorization: getAuthorization(),
							'Content-Type': 'application/json',
						},
					})
					.then((response) => {
						return dispatch({ type: OrdersActionTypes.GET_ORDERS, payload: response?.data });
					})
					.catch((err) => {
						return dispatch({ type: OrdersActionTypes.GET_ORDERS_FAILURE, payload: 500 });
					});        
		} catch (e) {
			return dispatch({ type: OrdersActionTypes.GET_ORDERS_FAILURE, payload: 500 });
		}
	};
};

export const crudOrders: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (orders) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<any>(REACT_APP_API_URL + `/orders/crud`, orders, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({
						type: OrdersActionTypes.POST_CRUD_ORDERS,
						payload: { response: response?.data, orders: orders },
					});
				})
				.catch((err) => {
					console.log(err);
				});
		} catch (e) {
			return dispatch({ type: OrdersActionTypes.POST_CRUD_ORDERS_FAILURE, payload: 500 });
		}
	};
};