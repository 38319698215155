import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import People from '@mui/icons-material/People';
import PermMedia from '@mui/icons-material/PermMedia';
import Dns from '@mui/icons-material/Dns';
import Public from '@mui/icons-material/Public';

import Tour from '../../../components/Admin/Dialogs/Tour';

import Fab from '@mui/material/Fab';

import NavigationIcon from '@mui/icons-material/Navigation';

import LocationCityIcon from '@mui/icons-material/LocationCity';
import SandBoxAccessQServices from '../../Admin/Dialogs/SandBoxAccessQServices';

const data = [
	{ icon: <LocationCityIcon />, label: 'México', url: 'https://mx.shopintegrate.net' },
	{ icon: <LocationCityIcon />, label: 'Colombia', url: 'https://co.shopintegrate.net' },
	/* { icon: <PermMedia />, label: 'Storage' },
	{ icon: <Public />, label: 'Hosting' }, */
];

const FireNav = styled(List)<{ component?: React.ElementType }>({
	'& .MuiListItemButton-root': {
		paddingLeft: 24,
		paddingRight: 24,
	},
	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 16,
	},
	'& .MuiSvgIcon-root': {
		fontSize: 20,
	},
});

interface ItemProps {
	getValues: Function;
}

interface ApiItemProps {
	props: ItemProps;
}

export default function AboutQServices({ props }: ApiItemProps) {
	const [open, setOpen] = React.useState(false);
	const [openToolTip, setOpenToolTip] = React.useState(true);
	const openInNewTab = (url: any) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};
	const [openList, setOpenList] = React.useState(false);

	const handleClickOpen = () => {
		setOpenList(true);
		//props?.getValues(true);
	};

	const handleClose = () => {
		setOpenList(false);
		//props?.getValues(false);
	};
	return (
		<>
			<Fab
				sx={{ display: !openList ? 'flex' : 'none' }}
				color='primary'
				variant='extended'
				onMouseOver={handleClickOpen}
			>
				<NavigationIcon sx={{ mr: 1 }} />
				Qservices
			</Fab>
			<Box sx={{ display: openList ? 'flex' : 'none' }} onMouseOver={handleClickOpen} onMouseOut={handleClose}>
				<ThemeProvider
					theme={createTheme({
						components: {
							MuiListItemButton: {
								defaultProps: {
									disableTouchRipple: true,
								},
							},
						},
						palette: {
							mode: 'light',
							primary: { main: '#FFFFFF' },
							background: { paper: '#388e3c' },
						},
					})}
				>
					<Paper elevation={0} sx={{ maxWidth: 256 }}>
						<FireNav component='nav' disablePadding>
							<ListItemButton component='a' href='#customized-list' onClick={() => openInNewTab('https://developer.ingrammicro.com/user/login')}>
								{/* <ListItemIcon sx={{ fontSize: 20 }}>🔥</ListItemIcon> */}
								<ListItemText
									sx={{ my: 0, color: '#FFFFFF' }}
									primary='Ingram'
									primaryTypographyProps={{
										fontSize: 20,
										fontWeight: 'medium',
										letterSpacing: 0,
									}}
								/>
							</ListItemButton>
							<Divider />
							{/* <ListItem component='div' disablePadding>
								<ListItemButton sx={{ height: 56, color: '#FFFFFF' }}>
									<ListItemIcon>
										<Home color='primary' />
									</ListItemIcon>
									<ListItemText
										primary='Auth Reseller'
										primaryTypographyProps={{
											color: 'primary',
											fontWeight: 'medium',
											variant: 'body2',
										}}
									/>
								</ListItemButton>
								<Tooltip title='Woocomerce'>
									<IconButton
										size='large'
										sx={{
											'& svg': {
												color: 'rgba(255,255,255,0.8)',
												transition: '0.2s',
												transform: 'translateX(0) rotate(0)',
											},
											'&:hover, &:focus': {
												bgcolor: 'unset',
												'& svg:first-of-type': {
													transform: 'translateX(-4px) rotate(-20deg)',
												},
												'& svg:last-of-type': {
													right: 0,
													opacity: 1,
												},
											},
											'&::after': {
												content: '""',
												position: 'absolute',
												height: '80%',
												display: 'block',
												left: 0,
												width: '1px',
												bgcolor: 'divider',
											},
										}}
									>
										<Settings />
										<ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
									</IconButton>
								</Tooltip>
							</ListItem> */}
							<SandBoxAccessQServices />
							<Divider />
							<Tour props={{ getValues: (values: any) => setOpenToolTip(!values) }} />
							{/* <ListItem component='div' disablePadding>
							<ListItemButton sx={{ height: 56, color: '#FFFFFF' }}>
								<ListItemIcon>
									<Home color='primary' />
								</ListItemIcon>
								<ListItemText
									primary='QServices Administrador'
									primaryTypographyProps={{
										color: 'primary',
										fontWeight: 'medium',
										variant: 'body2',
									}}
								/>
							</ListItemButton>
							<Tooltip title='Admin'>
								<IconButton
									size='large'
									sx={{
										'& svg': {
											color: 'rgba(255,255,255,0.8)',
											transition: '0.2s',
											transform: 'translateX(0) rotate(0)',
										},
										'&:hover, &:focus': {
											bgcolor: 'unset',
											'& svg:first-of-type': {
												transform: 'translateX(-4px) rotate(-20deg)',
											},
											'& svg:last-of-type': {
												right: 0,
												opacity: 1,
											},
										},
										'&::after': {
											content: '""',
											position: 'absolute',
											height: '80%',
											display: 'block',
											left: 0,
											width: '1px',
											bgcolor: 'divider',
										},
									}}
								>
									<Settings />
									<ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
								</IconButton>
							</Tooltip>
						</ListItem>
						<Divider /> */}
							<Box
								sx={{
									...(open
										? {
												bgcolor: 'rgba(71, 98, 130, 0.2)',
										  }
										: {
												bgcolor: null,
										  }),
									...(open
										? {
												pb: 2,
										  }
										: {
												pb: 0,
										  }),
								}}
							>
								<ListItemButton
									alignItems='flex-start'
									onClick={() => setOpen(!open)}
									sx={{
										...{
											px: 3,
											pt: 2.5,
										},
										...(open
											? {
													pb: 0,
											  }
											: {
													pb: 2.5,
											  }),
										...(open
											? {
													'&:hover, &:focus': {
														'& svg': {
															opacity: 1,
														},
													},
											  }
											: {
													'&:hover, &:focus': {
														'& svg': {
															opacity: 0,
														},
													},
											  }),
									}}
								>
									<ListItemText
										primary='Woocomerce'
										primaryTypographyProps={{
											fontSize: 15,
											fontWeight: 'medium',
											lineHeight: '20px',
											mb: '2px',
										}}
										secondary='México, Colombia'
										secondaryTypographyProps={{
											noWrap: true,
											fontSize: 12,
											lineHeight: '16px',
											color: open ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)',
										}}
										sx={{ my: 0, color: '#FFFFFF' }}
									/>
									<KeyboardArrowDown
										sx={{
											...{
												mr: -1,
												opacity: 0,
												transition: '0.2s',
											},
											...(open
												? {
														transform: 'rotate(-180deg)',
												  }
												: {
														transform: 'rotate(0)',
												  }),
										}}
									/>
								</ListItemButton>
								{open &&
									data.map((item) => (
										<ListItemButton
											key={item.label}
											onClick={() => openInNewTab(item.url)}
											sx={{ py: 0, minHeight: 32, color: 'rgba(255,255,255,.8)' }}
										>
											<ListItemIcon sx={{ color: 'inherit' }}>{item.icon}</ListItemIcon>
											<ListItemText
												primary={item.label}
												primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
											/>
										</ListItemButton>
									))}
							</Box>
						</FireNav>
					</Paper>
				</ThemeProvider>
			</Box>
		</>
	);
}
