import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts } from '../../store/products/action';
import { getManufacturer } from '../../store/manufacturer/action';
import { getOrders, crudOrders } from '../../store/orders/action';
import { getCategories } from '../../store/categories/action';

import { userReset, getUsers } from '../../store/user/action';

import { Dispatch } from 'redux';

import { Orders, OrdersState } from '../../store/orders/types';
import { ManufacturerState, Manufacturer } from '../../store/manufacturer/types';
import { ApplicationState } from '../../store/index';

import AutocompletCheckBox from './Components/AutocompletCheckBox';
import AutocompletCheckBoxGroup from './Components/AutocompletCheckBoxGroup';
import Select from './Components/Select';
import DatePicker from './Components/DatePicker';
import TextNumberBasic from './Components/TextNumber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { putFilter } from '../../store/filters/action';
import { putLoading } from '../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { ViewImagesManufacturer } from './Dialogs/ViewImagesManufacturer';
import  OrdersDocumentationJson  from './Dialogs/OrdersDocumentationJson';
import { crudManufacturer } from '../../store/manufacturer/action';
import Checkbox from '@mui/material/Checkbox';

import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { stringify } from 'querystring';

import Button from '@mui/material/Button';
import { handleBreakpoints } from '@mui/system';


const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
	| 'row?.customer?.id'
	| 'row?.customer?.email'
	//| `new Date(row?.createdAt?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat)`
	| `new Date(row?.completedAt?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat)`
	| 'row?.country'
	| 'row?.customerNumber'
	| `row?.ingramNumber`
	| 'JSON.stringify(row?.request)'
	| 'JSON.stringify(row?.ingramResponseError)'
	| `JSON.stringify(row?.ingramResponse)`
	| `JSON.stringify(row?.ingramResponse)`
	| `JSON.stringify(row?.ingramResponse)`;

	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{
		id: 'row?.customer?.id',
		label: 'Id',
		minWidth: 80,
	},
	{
		id: 'row?.customer?.email',
		label: 'Email Cliente',
		minWidth: 80,
	},
	/*{
		id: `new Date(row?.createdAt?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat)`,
		label: 'Creada',
		minWidth: 80,
	},*/
	{
		id: `new Date(row?.completedAt?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat)`,
		label: 'Completada',
		minWidth: 80,
	},
	{
		id: 'row?.country',
		label: 'Pais',
		minWidth: 80,
	},
	{
		id: 'row?.customerNumber',
		label: '# Orden Cliente',
		minWidth: 80,
	},
	{
		id: `row?.ingramNumber`,
		label: '# Orden Ingram',
		minWidth: 80,
	},
	{
		id: `JSON.stringify(row?.request)`,
		label: 'Request',
		minWidth: 80,
	},
	{
		id: `JSON.stringify(row?.ingramResponseError)`,
		label: 'Error',
		minWidth: 80,
	},
	{
		id: `JSON.stringify(row?.ingramResponse)`,
		label: 'Response',
		minWidth: 80,
	},
	{
		id: `JSON.stringify(row?.ingramResponse)`,
		label: 'Status',
		minWidth: 80,
	},
	{
		id: `JSON.stringify(row?.ingramResponse)`,
		label: 'Cancelar',
		minWidth: 80,
	},
	//{ id: 'row?.country', label: 'Ciudad', minWidth: 40 },
];

interface searchProps {
	value?: any;
	id?: any;
}

function Row(props: { dataresponse: any; activeNotImage: any;  user: any; rowp: Manufacturer[] }) {
	const { rowp, user, activeNotImage, dataresponse } = props;
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch<any>();

	function sendData(orderNumber: any,customerId:any, type:any) {
		dispatch(crudOrders({ customerId, orderNumber, type }));
	}

	return (
		<React.Fragment>
			{rowp?.map((row: any) => {
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column) => {

							let value = eval(column.id);

							if (!['Cancelar', 'Status', 'Request', 'Error', 'Response'].includes(column.label)) {
								
								return (
									<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
										{column.format ? column.format(value) : value}
									</TableCell>
								);

							} else {

								if (['Request', 'Error', 'Response'].includes(column.label)) {
									return (
										<TableCell>
											<OrdersDocumentationJson
												data={value}
												title={column.label}
												label={column.label}
												diccionario={{}}
											/>
										</TableCell>
									);
								}

								if (['Cancelar','Status'].includes(column.label)) {
									return (
										/*
										<TableCell>
											<Button onClick={() => sendData(row?.ingramNumber, row?.customer?.id,column.label)}>{column.label}</Button>
										</TableCell>
										*/
										<TableCell>
											<OrdersDocumentationJson
												data={ JSON.stringify(dataresponse || {}) }
												title={column.label}
												label={column.label}
												diccionario={{}}
												props={{ getValues: () => sendData(row?.ingramNumber, row?.customer?.id, column.label) }}
											/>
										</TableCell>
									);
								}

							}

						})}
					</TableRow>
				);
			})}
		</React.Fragment>
	);
}

export default function OrdersIngram() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [activeNotImage, setActiveNotImage] = React.useState(false);

	const [open, setOpen] = React.useState(false);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();

	const users = useSelector((state: ApplicationState) => state.user);
	const [filters, setFilters] = React.useState({
		user: null
	});

	const { data, length, errors, dataresponse } = useSelector((state: ApplicationState) => state.orders);
	const filtersGlb = useSelector((state: ApplicationState) => state.filters);
	const user = useSelector((state: ApplicationState) => state.user.data) || {};

	function serachData({ id, value }: searchProps) {
		setFilters({
			...filters,
			[id]: value,
		});
		dispatchThunk(putFilter({ id: 'orders', value: { [id]: value } }));
	}

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, data]);

	useEffect(() => {
		if (errors?.code) {
			dispatchThunk(userReset());
		} 
	}, [dispatchThunk, errors]);

	useEffect(() => {		
		setPage(0);
	}, [dispatchThunk, dispatch, filtersGlb, rowsPerPage]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getOrders({
				filters: { ...filtersGlb, ...filters },
				rowsPerPage: rowsPerPage,
				page: page,
			})
		);
	}, [dispatchThunk, dispatch, page, rowsPerPage, filtersGlb, filters]);

	useEffect(() => {
		dispatch(
			getUsers({
				profile: 'CUSTOMER',
				filters: null,
				rowsPerPage: 1000,
				page: 0,
			})
		);
	}, [dispatchThunk, dispatch]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.text.secondary,
	}));

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<div>
				{user.user.role == 'SERVICE' && (
					<FormControl sx={{ float: 'right', marginTop: { sm: '.5rem' }, width: '20%' }} size='small'>
						<Autocomplete
							id='user'
							isOptionEqualToValue={(option, value) => option.label === value.label}
							options={[
								...[{ id: 0, label: 'Selecciona...' }],
								...(users.users?.map((m: any) => {
									return { id: m.id, label: m.email };
								}) || []),
							]}
							sx={{
								'& .MuiInputBase-input': {
									height: '.01px',
								},
							}}
							renderInput={(params) => <TextField {...params} label='Cliente' />}
							value={
								users.users
									?.filter((k: any) => k.id == filters?.user)
									.map((m: any) => {
										return { id: m.id, label: m.email };
									})[0] || { id: 0, label: 'Selecciona...' } ||
								undefined
							}
							onChange={(e: any, value: any) => {
								serachData({ id: 'user', value: value?.id || 0 });
							}}
						/>
					</FormControl>
				)}
			</div>
			<TableContainer sx={{ maxHeight: 400 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (

								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>

							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.length
							? data?.map((row) => <Row key={row.id} dataresponse={ dataresponse} activeNotImage={activeNotImage} user={user} rowp={[row]} />)
							: !data && (
									<CircularProgress
										disableShrink
										size={70}
										sx={{
											position: 'fixed',
											left: '50%',
											top: '50%',
											transform: 'translate(-50%, -50%)',
											zIndex: 5,
										}}
									/>
							  )}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100, 300]}
				component='div'
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}