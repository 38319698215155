import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

function createData(endpoint: string, action: string, type:string, update: string, reque: string, title: string, def: string, link: string) {
	return { endpoint, action, title,update, reque, type, def, link };
}

const rows = [	
	createData('GET api/countrycode', 'Abr/2024', 'Objectc{code:[List country]}', 'Response', '-', '-', 'Regresa listado de codigos de estados de ciudad {"code":["Ciudad México"],"code":["",""],...}', ''),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function BasicTable() {
	return (
		<Paper sx={{ width: '100%' }}>
			<TableContainer sx={{ maxHeight: 340 }}>
				<Table stickyHeader aria-label='customized sticky table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>EndPoint</StyledTableCell>
							<StyledTableCell align='right'>Acción</StyledTableCell>
							<StyledTableCell align='right'>Update</StyledTableCell>
							<StyledTableCell align='right'>Tipo</StyledTableCell>							
							<StyledTableCell align='right'>Requerido</StyledTableCell>
							<StyledTableCell align='right'>Titulo (Campo en Acción)</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Definición
							</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Enlace
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<StyledTableRow key={row.endpoint} sx={{'&:last-child td, &:last-child th': { border: 0} }}>
								<StyledTableCell component='th' scope='row'>
									{row.endpoint}
								</StyledTableCell>
								<StyledTableCell align='right'>{row.update}</StyledTableCell>
								<StyledTableCell align='right'>{row.action}</StyledTableCell>
								<StyledTableCell align='right'>{row.type}</StyledTableCell>
								<StyledTableCell align='right'>{row.reque}</StyledTableCell>
								<StyledTableCell align='right'>{row.title}</StyledTableCell>
								<StyledTableCell style={{ maxWidth: 300 }} align='right'>
									{row.def}
								</StyledTableCell>
								<StyledTableCell style={{ maxWidth: 300 }} align='right'>
									{row.link}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
