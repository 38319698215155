export interface UserL {
	id?: number;
	parentId?: number;
	sandbox?: any;
	name?: string;
	clientId?: string | null;
	clientSecret?: string;
	customerNumber?: string;
	countryCode?: string | null | undefined;
	categoriesSegmentation?: any | null | undefined;
	categoriesIngramSegmentation?: any | null | undefined;
	categoriesExcludeSegmentation?: any | null | undefined;
	categoriesExcludeIngramSegmentation?: any | null | undefined;
	manufacturerSegmentation?: any | null | undefined;
	brsExcludes?: string | null;
	activeOnly?: boolean;
	paramSpecial?: string;
	secretOrder: boolean;
	setImages?: boolean;
	setSheets?: boolean;
	setStock?: boolean;
	setPrices?: boolean;
	onlyManufacturerSegmentation?: boolean;
	deleteOutStock?: boolean;
	setDescriptions?: boolean;
	showBr?: boolean;
	logsDate?: any;
	setRecategorized?: boolean;
	setRecategorizeDescription?: boolean;
	senderId?: string;
	skusSegmentation?: string;
	userBD?: string;
	passBD?: string;
	nameBD?: string;
	hostEmail?: string;
	userEmail?: string;
	passEmail?: string;
	toEmail?: string;
	iva: number;
	utility: number;
	maxProductsSegment?: any;
	maxPricesShop?: any;
	userAdminApp?: string;
	passAdminApp?: string;
	urlApiApp?: string;
	idApiApp?: string;
	secretApiApp?: string;
	secretAppOrder?: string;
	tokenAppContents?: string;
	typeApp?: string | null | undefined;
	timeProductsCron?: string;
	timeProductsShopOnlyCron?: string;
	timePricesCron?: string;
	timePricesShopOnlyCron?: string;
	timeContentsImages?: string;
	timeContentsSheets?: string;
	timeContentsTitles?: string;
	timeRefactor?: string;
	username?: string;
	password: string;
	url?: string;
	status?: string | null | undefined;
	description?: string;
	firstName?: string;
	lastName?: string;
	web?: string;
	email?: string;
	createdAt?: Date;
	profile?: string;
	segmentarIngram?: boolean;
	rulesPricesIva?: RulesPricesIva[];
	rulesPricesUtility?: RulesPricesUtility[];
}
export interface RulesPricesUtility {
	id: number;
	manufacturerId: string | null;
	categoriesIngramId: string | null;
	customerId: number | null;
	type: string | null;
	key: string | null;
	keyWord: string | null;
	value: string | null;
	valueApply: string | null;
	priority: number | null;
}
export interface RulesPricesIva {
	id: number;
	manufacturerId: string | null;
	categoriesIngramId: string |null;
	customerId: number|null;
	type: string|null;
	key: string|null;
	keyWord: string|null;
	value: string|null;
	valueApply: string|null;
	priority: number|null;
}
export interface UserLogin {	
	user: UserResponse;
	token: string;
	signup: boolean;
}

export interface UserResponse {
	id: string;
	email: string;
	role: string;
	countryCode: string;
	segmentarIngram: string;
	super?: boolean;
	category?: string;
	tokenAppContents: string;
}

export interface User {
	email: string;
	password: string;
}

export enum UserActionTypes {
	PUT_USER = '@@user/PUT_USER',
	GET_USERS = '@@user/GET_USERS',
	GET_LOGIN = '@@user/GET_LOGIN',
	GET_LOGIN_FAILURE = '@@user/GET_LOGIN_FAILURE',
	PUT_USER_FAILURE = '@@user/PUT_USER_FAILURE',
	PUT_SANDBOX = '@@user/PUT_SANDBOX',
	PUT_SANDBOX_DATA = '@@user/PUT_SANDBOX_DATA',
	PUT_SANDBOX_FAILURE = '@@user/PUT_SANDBOX_FAILURE',
	FETCH_REQUEST = '@@user/FETCH_REQUEST',
	FETCH_SUCCESS = '@@user/FETCH_SUCCESS',
	FETCH_ERROR = '@@user/FETCH_ERROR',
	REFRESH_LOGIN = '@@user/REFRESH_LOGIN',
	GET_PRODUCTS_COUNT_FAILURE = '@@user/GET_PRODUCTS_COUNT_FAILURE',
	GET_PRODUCTS_COUNT = '@@user/GET_PRODUCTS_COUNT',
	GET_PRODUCTS_SEGMENT_FAILURE = '@@user/GET_PRODUCTS_SEGMENT_FAILURE',
	GET_PRODUCTS_SEGMENT = '@@user/GET_PRODUCTS_SEGMENT',
	GET_LOGS = '@@user/GET_LOGS',
	GET_LOGS_FAILURE = '@@user/GET_LOGS_FAILURE',
	PUT_SUPPORT = '@@user/PUT_SUPPORT',
	PUT_SUPPORT_FAILURE = '@@user/PUT_SUPPORT_FAILURE',
}


export interface UserState {
	sandbox: boolean;
	users?: UserL[];
	logs?: any;
	customers?: any;
	length: number;
	loading: boolean;
	data: UserLogin;
	errors?: errors;
	productsCount?: any;
	productsCountCustomer?: any;
	productsSegment?: any;
	productsSegmentCount?: any;
}

export interface errors {
	code?: number;
	message?: any;
}
