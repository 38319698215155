import React from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { Hamburger } from './Hamburger';
import { Search } from './Search';
import { AppTitle } from './AppTitle';
import { ThemeSwitcher } from './ThemeSwitcher';
import { Messages, More, Notifications, UserAccount } from '../Actions';
import { DefaultMenu, MobileMenu } from './Menu';

import { putFilter } from '../../store/filters/action';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../store/index';
import { isTemplateExpression } from 'typescript';


import IconButton from '@mui/material/IconButton';

import { useRef, useMemo, useState, useEffect } from 'react';


import DialogDocumentation from '../../components/Admin/Dialogs/ApiDocumentation';
import Tooltip from '@mui/material/Tooltip';

import Support from '../../components/Admin/Dialogs/Support';



interface HeaderProps {
	toggleNavigation: () => void;
}

interface searchProps {
	value?: any;
	id?: any;
}

let links = ['./txtCode/phpConnect.txt', './txtCode/nodeConnect.txt'];

export const Header = ({ toggleNavigation }: HeaderProps) => {

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const user = useSelector((state: ApplicationState) => state.user.data) || {};

	function serachData({ id, value }: searchProps) {
		dispatchThunk(putFilter({ id: id, value: value }));
	}

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => setMobileMoreAnchorEl(event.currentTarget);

	const handleMobileMenuClose = () => setMobileMoreAnchorEl(null);

	const handleMenuClose = () => {
		setAnchorEl(null);
		handleMobileMenuClose();
	};

	const [openToolTip, setOpenToolTip] = React.useState(true);

	return (
		<>
			<AppBar position='fixed' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
				<Toolbar disableGutters variant='dense'>
					<Hamburger toggleNavigation={toggleNavigation} />
					{/* <AppTitle /> */}
					<Search props={{ onSearch: (search: searchProps) => serachData(search) }} />
					<Box sx={{ flexGrow: 1 }} />
					<Box sx={{ flexGrow: 1 }}
						/* sx={{
							margin: 0,
							top: 60,
							left: 'auto',
							bottom: 20,
							right: 20,
							position: 'fixed',
							zIndex: (theme) => theme.zIndex.drawer + 1,
						}} */
					>
						<Support />
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
						<Tooltip arrow open={openToolTip} title={'Click Documentación Api'} placement='left'>
							<IconButton edge='start' color='inherit' aria-label='menu' sx={{ mr: 2 }}>
								<DialogDocumentation props={{ getValues: (values: any) => setOpenToolTip(!values) }} />
							</IconButton>
						</Tooltip>
						( {user.user.email} ) {user.user.role} ( {user.user.countryCode} )
					</Box>
					<Box sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center' } }}>
						<ThemeSwitcher />
						{/* <Messages total={15} />
						<Notifications total={20} /> */}
						<UserAccount onClick={handleProfileMenuOpen} />
					</Box>
					<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
						<More onClick={handleMobileMenuOpen} />
					</Box>
				</Toolbar>
			</AppBar>
			<MobileMenu
				isMenuOpen={Boolean(mobileMoreAnchorEl)}
				handleMenuOpen={handleMobileMenuOpen}
				handleMenuClose={handleMobileMenuClose}
				anchorEl={mobileMoreAnchorEl}
			/>
			<DefaultMenu isMenuOpen={Boolean(anchorEl)} handleMenuClose={handleMenuClose} anchorEl={anchorEl} />
		</>
	);
};
