export interface HistoryAccessSearch {
	search: string;
	rowsPerPage: number;
	page: number;
}

export enum HistoryAccessActionTypes {
	GET_HISTORYACCESS = '@@historyaccess/GET_HISTORYACCESS',
	GET_HISTORYACCESS_FAILURE = '@@historyaccess/GET_HISTORYACCESS_FAILURE',
}
export interface errors {
	code?: number;
	message?: string;
}

export interface HistoryAccessState {
	length: number;
	loading: boolean;
	data?: HistoryAccess[];
	datasearch?: HistoryAccess[];
	errors?: errors;
}

export interface HistoryAccess {
	id?: number;
	name: string;
	tags: string;
}