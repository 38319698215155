import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled, lighten, darken } from '@mui/system';
import { useRef, useMemo, useState, useEffect } from 'react';
import PreviewIcon from '@mui/icons-material/Preview';

import ProductsSegment from '../Dialogs/ProductsSegment';
import Tooltip from '@mui/material/Tooltip';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const GroupItems = styled('ul')({
	padding: 0,
});


interface ItemProps {
	statusProductsSegment?: any;
	//disabled?: boolean;
	group?: any;
	//selected: string[];
	data: any;
	value?: any;
	title?: string;
	id?: string;
	onSearch?: Function;
	changeOpenDialog?: Function;
	sort?: boolean;
	maxWidth?: number;
	error?: boolean;
	errorMessage?: string;
	dropDownDisabled?: boolean;
}

interface ItemsProps {
	props?: ItemProps;
}
const filterDuplicate = (array:any) => {
	let hash:any = {};
	array = array.filter((o:any) => (hash[o.title] ? false : (hash[o.title] = true)));
	return array;
}
export default function CheckboxesTags(propsaut: ItemsProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [open, setOpen] = useState(false);
	const [maxSegment, setMaxSegment] = useState(false);
	const [viewProducts, setViewProducts] = useState(false);
	const [mouseMove, setMouseMove] = React.useState(false);


	useEffect(
		() => {
			setMaxSegment(
				propsaut?.props?.statusProductsSegment?.productsCount > propsaut?.props?.statusProductsSegment?.maxProductsSegment &&
				propsaut?.props?.statusProductsSegment?.maxProductsSegment > 0
			);
	}, [propsaut?.props?.statusProductsSegment]);
	
	
	
	return (
		<Autocomplete
			multiple
			limitTags={4}
			key={propsaut?.props?.id}
			options={propsaut?.props?.data.sort((a: any, b: any) => -b?.parent?.localeCompare(a?.parent))}
			groupBy={(option) => option?.parent}
			id={propsaut?.props?.id}
			value={propsaut?.props?.value}
			disableCloseOnSelect
			//blurOnSelect
			autoComplete
			/* onInputChange={(_, value) => {
				if (value.length === 0) {
					if (open) setOpen(false);
				} else {
					if (!open) setOpen(true);
				}
			}}
			onClose={() => setOpen(false)} */
			open={isOpen}
			onOpen={() => setIsOpen(true)}
			onClose={(e, reason) => {
				if (reason === 'blur' && propsaut?.props?.dropDownDisabled) return;
				setIsOpen(false);
			}}
			getOptionLabel={(option) => option?.title}
			isOptionEqualToValue={(option, value) => option.title === value.title}
			/*defaultValue={propsaut?.props?.value} */
			renderGroup={(params) => (
				<li key={params.key}>
					<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={propsaut.props?.value.map((item: any) => item.title)?.includes(params.group)}
						disabled={maxSegment && !propsaut.props?.value.map((item: any) => item.title)?.includes(params.group)}
						onChange={() => {
							if (propsaut?.props?.onSearch) {
								let value: any = [
									...propsaut?.props?.value,
									...[{ id: params.group, parent: params.group, title: params.group }],
								];

								if (propsaut.props?.value.map((item: any) => item.title).includes(params.group)) {
									value = value.filter((item: any) => item.parent != params.group);
								} else {
									value = [
										...value,
										...propsaut?.props?.data.filter(
											(item: any) => item.parent == params.group && item.parent != item.title
										),
									];
								}

								propsaut?.props?.onSearch({
									id: propsaut?.props?.id,
									value: filterDuplicate(value),
								});
							}
						}}
					/>
					{params.group}
					<GroupItems>{propsaut?.props?.group && params.children}</GroupItems>
				</li>
			)}
			renderOption={(props, option, { selected }) => {
				return (
					<>
						<li key={option.key} {...props} style={{ fontSize: '10px' }}>
							<Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }}
								checked={propsaut.props?.value.map((item: any) => item.title)?.includes(option?.title)}
								disabled={maxSegment && !propsaut.props?.value.map((item: any) => item.title)?.includes(option?.title)}
								onChange={(e: any) => {}}
							/>
							{`${option?.title} ${option?.count ? ` (${option?.count}) ` : ''}`}
							{option?.count && !maxSegment && (
								<>
									<Tooltip title="Ver Productos" placement="top">
										<PreviewIcon
											onClick={() => propsaut?.props?.changeOpenDialog && propsaut?.props?.changeOpenDialog({ status:true, segment: option.title})}
											onMouseOver={() => setMouseMove(true)}
											onMouseOut={() => setMouseMove(false)}
										/>
									</Tooltip>
								</>
							)}
						</li>
					</>
				);
			}}
			fullWidth
			onChange={(event, value, reason, details) => {
				if (!mouseMove) {
					if (propsaut?.props?.onSearch) {
						if (reason == 'removeOption') {
							const values: any = propsaut.props?.value.map((item: any) => item.title).includes(details?.option.title)
								? value
										.filter((item: any) => item.title != details?.option.title)
										.filter((item: any) => item.parent != details?.option.title)
								: value.filter((item: any) => item.parent != details?.option.title);

							propsaut?.props?.onSearch({
								id: propsaut?.props?.id,
								value: filterDuplicate(values),
							});
						} else {
							if (maxSegment) return;
							const values: any = propsaut.props?.value.map((item: any) => item.title).includes(details?.option.title)
								? value.filter((item: any) => item.title != details?.option.title)
								: value;

							propsaut?.props?.onSearch({
								id: propsaut?.props?.id,
								value: filterDuplicate(values),
							});
						}
					}
				}
			}}
			style={{
				maxWidth: propsaut?.props?.maxWidth || 300,
				/* overflowY: 'scroll',
				height: '70px', */
				paddingTop: '10px',
				fontSize: '10px',
			}}
			renderInput={(params) => {
				const { InputProps, ...restParams } = params;
				const { startAdornment, ...restInputProps } = InputProps;
				return (
					<TextField
						error={propsaut?.props?.error}
						helperText={propsaut?.props?.errorMessage}
						{...restParams}
						placeholder={propsaut?.props?.title}
						InputProps={{
							...restInputProps,
							startAdornment: (
								<div
									style={{
										maxHeight: '40px',
										overflowY: 'auto',
										scrollbarWidth: 'thin',
									}}
								>
									{startAdornment}
								</div>
							),
						}}
					/>
				);
			}}
			/* renderInput={(params) => (
				<TextField
					{...params}
					label={propsaut?.props?.title}
					placeholder={propsaut?.props?.title}
					style={{ fontSize: '10px' }}
				/>
			)} */
			sx={{
				'& .MuiAutocomplete-endAdornment': {
					top: '10px !important',
				},
				/* '& .MuiFormControl-root': {
					overflowY: 'scroll',
					height: '70px',
				}, */
			}}
		/>
	);
}
