import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

function createData(endpoint: string, update: string, type:string, action: string, reque: string, title: string, def: string, link: string) {
	return { endpoint, update, type,action, reque, title, def, link };
}

const rows = [
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'orderNumberClient', 'Número de orden del cliente, numero interno', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'company', 'Nombre de la compañia o razon social', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'note', 'comentarios o nota del cleinte, señas de la dirección', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'nameClient', 'Nombre del cliente', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'street', 'Descripción de las calles', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'colony', 'Descripción de la colonia', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'phoneNumber', 'Numero de télefono', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'city', 'Nombre de la ciudad', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'state', 'Codigo de estado, desde enpoint api/countrycode', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'email', 'Correo electronico del cliente', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'carrier', 'Paqueteria o metodo de envio, "E1" Estafeta, "D1" DHL', 'Si se omite por default E1'),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'branch', 'Almacen desde donde se serviran los productos', 'si se omite, se tomara el envio desde el almacen pricipal'),
	createData('PUT api/order ', 'Abr/2024', 'Array [Object products]', 'Request Body', 'Si', 'products', 'Lista de productos a servir ó enviar', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Request Body', 'Si', 'products[].sku', 'Sku ingram del producto a enviar', ''),
	createData('PUT api/order ', 'Abr/2024', 'Number', 'Request Body', 'Si', 'products[].qty', 'Cantidad de productos a servir', ''),
	createData('PUT api/order ', 'Abr/2024', 'Number', 'Response', '-', 'orderNumberClient', 'Numero d eorden enviada por el cliente', ''),
	createData('PUT api/order', 'Abr/2024', 'String', 'Response', '-', 'orderNumberIngram', 'Número de orden ingram', ''),
	createData('PUT api/order ', 'Abr/2024', 'String', 'Response', '-', 'message', 'Mensaje adicional', '')
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function BasicTable() {
	const [search, setSearch] = React.useState('');
	return (
		<Paper sx={{ width: '100%' }}>
			<Box>
				<TextField
					margin='dense'
					id='search'
					label='Buscar texto'
					type='text'
					variant='standard'
					onChange={(e) => setSearch(e.target.value)}
				/>
			</Box>
			<TableContainer sx={{ maxHeight: 340 }}>
				<Table stickyHeader aria-label='customized sticky table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>EndPoint</StyledTableCell>
							<StyledTableCell align='right'>Update</StyledTableCell>
							<StyledTableCell align='right'>Tipo</StyledTableCell>
							<StyledTableCell align='right'>Acción</StyledTableCell>
							<StyledTableCell align='right'>Requerido</StyledTableCell>
							<StyledTableCell align='right'>Titulo (Campo en Acción)</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Definición
							</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Enlace
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.filter((r: any) =>
								`${r.endpoint} ${r.reque} ${r.title} ${r.action} ${r.type} ${r.def} ${r.link}`
									.toLowerCase()
									.includes(search.toLowerCase())
							)
							.map((row: any) => (
								<StyledTableRow key={row.endpoint} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<StyledTableCell component='th' scope='row'>
										{row.endpoint}
									</StyledTableCell>
									<StyledTableCell align='right'>{row.update}</StyledTableCell>
									<StyledTableCell align='right'>{row.type}</StyledTableCell>
									<StyledTableCell align='right'>{row.action}</StyledTableCell>
									<StyledTableCell align='right'>{row.reque}</StyledTableCell>
									<StyledTableCell align='right'>{row.title}</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.def}
									</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.link}
									</StyledTableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
