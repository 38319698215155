import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';
import DialogContentText from '@mui/material/DialogContentText';
//import ApiGenerateFilter from '../Dialogs/ApiGenerateFilter';



interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props: ItemProps;
	categories: CategoriesState;
	manufacturer: ManufacturerState;
	/* users: any;*/
	user: any; 
}

interface searchProps {
	value?: any;
	id?: any;
}


export const FilterProducts = ({ name, data, props, categories, manufacturer/* , users*/,user }: CrudItemProps) => {
	const dispatch = useDispatch<any>();

	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const mapManufacturer = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.name.split(' ')[0].toUpperCase(),
					title: item?.name,
					id: item?.name,
					header: !item?.parentName,
				};
			}) || []
		);
	};
	
	const [open, setOpen] = React.useState(false);
	const [filtersJson, setFiltersJson] = React.useState({});
	

	const [filters, setFilters] = React.useState<any>({
		price: null,
		inventory: null,
		createdAt: null,
		updatedAt: null,
		categories: [],
		manufacturer: [],
		active: null,
		inactive: null,
		recategorized: null,
		images: null,
		sheets: null,
		//user:null
	});

	function serachData({ id, value }: searchProps) {	
		setFilters({
			...filters,
			[id]: value,
		});
	}
	
	const userSchema = object({
		createdAt: undefined().nullable().optional(),
		updatedAt: undefined().nullable().optional(),
		price: string().nullable().optional(),
		inventory: string().nullable().optional(),
		active: boolean().nullable().optional(),
		inactive: boolean().nullable().optional(),
		recategorized: boolean().nullable().optional(),
		categories: string().nullable().optional(),
		manufacturer: string().nullable().optional(),
		images: string().nullable().optional(),
		sheets: string().nullable().optional(),
		//user: string().nullable().optional(),
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {
		createdAt: null,
		updatedAt: null,
		price: '',
		active: null,
		inactive: null,
		recategorized:null,
		inventory: '',
		images: '',
		sheets: '',
		//user: null
	};	

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});
	

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = () => {
		//methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		methods.reset(defaultValues);
		setFilters({
			...filters,
			price: '',
			inventory: '',
			createdAt: null,
			updatedAt: null,
			active: null,
			images: null,
			sheets: null,
			inactive: null,
			categories: [],
			manufacturer: [],
			//user: null,
		});
	};

	const onSubmitHandler: SubmitHandler<IFilter> = (values: IFilter) => {
		values.price = filters?.price;
		values.active = filters?.active;
		values.inactive = filters?.inactive;
		values.recategorized = filters?.recategorized;

		values.inventory = filters?.inventory;
		values.createdAt = filters?.createdAt;
		values.updatedAt = filters?.updatedAt;
		//values.user = filters?.user;
		values.images = filters?.images;
		values.sheets = filters?.sheets;
		values.categories = filters.categories.map((item:any)=>item.title);
		values.manufacturer = filters.manufacturer./* filter((item: any) => item.parent != item.title). */map((i: any) => i.title);
		props?.getValues(values);
		handleClose();
	};	

	return (
		<div>
			<Button variant='outlined' onClick={handleClickOpen}>
				{name}
			</Button>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							{/* {user.user.role=='SERVICE'&&(
								<FormControl sx={{ marginBottom: { sm: '1rem' }, width: '100%' }} size='small'>
									<InputLabel id='user'>Usuario</InputLabel>
									<Select
										value={filters?.user || ''}
										labelId='user'
										id='user'
										label='Usuario'
										name='user'
										onChange={(e) => {
											serachData({ id: e.target.name, value: e.target.value });
										}}
									>
										<MenuItem value=''>
											<em>Seleccionar....</em>
										</MenuItem>
										{users.users?.map((item: any) => (
											<MenuItem key={item.id} value={item.id}>
												{item.email}
											</MenuItem>
										))}
									</Select>
								</FormControl>)
							} */}
							<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '20%' }}>
								<div>
									<Checkbox
										checked={filters?.active}
										//onClick={handleChangeCat2}
										onClick={(event: any) => {
											serachData({ id: event.target.id, value: event.target.checked });
										}}
										id='active'
										/* {...methods.register('segmentarIngram')}
									checked={methods.watch('segmentarIngram')} */
									/>
									Activos
								</div>
							</FormControl>
							<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '20%' }}>
								<div>
									<Checkbox
										checked={filters?.inactive}
										//onClick={handleChangeCat2}
										onClick={(event: any) => {
											serachData({ id: event.target.id, value: event.target.checked });
										}}
										id='inactive'
										/* {...methods.register('segmentarIngram')}
									checked={methods.watch('segmentarIngram')} */
									/>
									Inactivos
								</div>
							</FormControl>
							{user.user.role == 'SERVICE' && user.user.super && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '20%' }}>
									<div>
										<Checkbox
											checked={filters?.recategorized}
											//onClick={handleChangeCat2}
											onClick={(event: any) => {
												serachData({ id: event.target.id, value: event.target.checked });
											}}
											id='recategorized'
											/* {...methods.register('segmentarIngram')}
										checked={methods.watch('segmentarIngram')} */
										/>
										Recat.
									</div>
								</FormControl>
							)}
							<FormControl sx={{ width: user.user.role == 'SERVICE' && user.user.super ? '20%' : '30%' }} size='small'>
								<InputLabel id='images'>Imagenes</InputLabel>
								<Select
									value={filters?.images || ''}
									labelId='images'
									id='images'
									label='Imagenes'
									name='images'
									onChange={(e) => {
										serachData({ id: e.target.name, value: e.target.value });
									}}
								>
									<MenuItem value=''>
										<em>Seleccionar....</em>
									</MenuItem>
									<MenuItem value='images'>Con Imagen</MenuItem>
									<MenuItem value='notimages'>Sin Imagen</MenuItem>
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='notimagesvendor'>Sin Imagen Vendor</MenuItem>
									)}
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='imagesdimlow'>Imagen Dim. Baja</MenuItem>
									)}
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='contentscountimg'>Contenido Img</MenuItem>
									)}
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='contentscountallimg'>Contenido Contado Img</MenuItem>
									)}
								</Select>
							</FormControl>
							<FormControl sx={{ width: user.user.role == 'SERVICE' && user.user.super ? '20%' : '30%' }} size='small'>
								<InputLabel id='sheets'>Fichas</InputLabel>
								<Select
									value={filters?.sheets || ''}
									labelId='sheets'
									id='sheets'
									label='Fichas'
									name='sheets'
									onChange={(e) => {
										serachData({ id: e.target.name, value: e.target.value });
									}}
								>
									<MenuItem value=''>
										<em>Seleccionar....</em>
									</MenuItem>
									<MenuItem value='sheets'>Con Ficha</MenuItem>
									<MenuItem value='notsheets'>Sin Ficha</MenuItem>
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='contentscountsheets'>Contenido Ficha</MenuItem>
									)}
									{user.user.role == 'SERVICE' && user.user.super && (
										<MenuItem value='contentscountallsheets'>Contenido Contado Ficha</MenuItem>
									)}
								</Select>
							</FormControl>
							<FormControl variant='outlined' size='medium' fullWidth={true} error={false}>
								<DatePicker
									props={{
										value: filters?.createdAt,
										id: 'createdAt',
										title: 'Creado',
										onSearch: (search: searchProps) => serachData(search),
									}}
								/>
							</FormControl>
							<FormControl variant='outlined' size='medium' style={{ paddingTop: '10px' }} fullWidth={true}>
								<DatePicker
									props={{
										value: filters?.updatedAt,
										id: 'updatedAt',
										title: 'Actualizado',
										onSearch: (search: searchProps) => serachData(search),
									}}
								/>
							</FormControl>
							<TextNumberBasic
								props={{
									value: filters?.price,
									id: 'price',
									title: 'Precio',
									onSearch: (search: searchProps) => serachData(search),
								}}
							/>
							<TextNumberBasic
								props={{
									value: filters?.inventory,
									id: 'inventory',
									title: 'Inventario',
									onSearch: (search: searchProps) => serachData(search),
								}}
							/>
							<AutocompletCheckBoxGroup
								props={{
									group: true,
									data: mapManufacturer(manufacturer.datasearch),
									value: filters?.manufacturer,
									maxWidth: 900,
									id: 'manufacturer',
									title: 'Fabricantes',
									onSearch: (search: searchProps) => serachData(search),
								}}
							/>
							<AutocompletCheckBoxGroup
								props={{
									group: true,
									data: mapCategories(categories.datasearch),
									value: filters?.categories,
									maxWidth: 900,
									id: 'categories',
									title: 'Categorias',
									onSearch: (search: searchProps) => serachData(search),
								}}
							/>
						</DialogContent>
						<DialogActions>
							{/* <ApiGenerateFilter
								title='Filtros para Api'
								label=' Filtros Api'
								data={filters || ''}
								manufacturer={manufacturer}
								categories={categories}
							/> */}
							<Button onClick={handleReset}>Reset</Button>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type='submit'>Realizar</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};