import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import NoPhotographyRoundedIcon from '@mui/icons-material/NoPhotographyRounded';
import Badge from '@mui/material/Badge';

import { alpha, InputBase, styled, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Close from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getImagesBDI } from '../../../store/products/action';
import { ApplicationState } from '../../../store/index';



import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import PermMediaIcon from '@mui/icons-material/PermMedia';


//const requestImageSize = require('request-image-size');

//import getImageSize from 'image-size-from-url';

/* const url = require('url');
const https = require('https');

const imageSize = require('image-size'); */

const CryptoTS = require('crypto-ts');
const KEYSTORE: any = 'csx235tffgg';

interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props?: ItemProps;
	user?: any;
	mfrpartnumber?: string;
}

interface searchProps {
	value?: any;
	id?: any;
}


export const ViewImagesManufacturer = ({ mfrpartnumber, name, data, props, user }: CrudItemProps) => {
	const dispatch = useDispatch<any>();

	const bdiimages  = useSelector((state: ApplicationState) => state.products).bdiimages || [];


	const [imagesLoad, setImagesLoad] = React.useState<any>({});
	const [imagesLoadError, setImagesLoadError] = React.useState<any>(false);

	
	const [open, setOpen] = React.useState(false);

	const [images, setImages] = React.useState(data||'');	

	const [filters, setFilters] = React.useState<any>({});

	const [imagesUpload, setImagesUpload] = React.useState([]);
	const [nameAll, setNameAll] = React.useState(false);

	

	const maxNumber = 69;

	const onChangeUpload = async  (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
		// data for submit
		setImages('');
		
		setImagesUpload(imageList as never[]);
		//console.log(imageList as never[]);
		const data64: any = imageList as never[];
		await loadDimIamges(data64.map((i: any) => i.dataURL));
		
	};

	
	const userSchema = object({		
		categories: string().nullable().optional()
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {
		
	};	

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});
	


	function waitForImage(imgElem: any) {
		return new Promise((res:any, rej:any) => {
			if (imgElem.complete) {
				return res();
			}
			imgElem.onload = () => res();
			imgElem.onerror = () => rej(imgElem);
		});
	}

	async function loadDimIamges(img64:any) {
		const data_: any = img64;
		let dimensions: any = {};
		let countLoad: number = 0;
		for (const elem in data_) {
			const img = new Image();			
			img.onload = function () {
				
				try {
					dimensions = {
						...dimensions,
						...{
							[elem]: {
								height: img.height,
								width: img.width,
							},
						},
					};
					countLoad++;
					setImagesLoad(dimensions);
					setOpen(true);
				} catch (e: any) {
					dimensions = {
						...dimensions,
						...{
							[elem]: {
								height: -1,
								width: -1,
							},
						},
					};
					setImagesLoadError(true);
				}
			};			
			img.src = data_[elem];
		}

		if (countLoad == data_.length) {
			setImagesLoadError(false);
			setImages(data);
		}
		
	}

	const handleClickOpen = async () => {
		setImagesUpload([]);
		const data_: any = data?.split(',').map((k:any)=>k) || [];
		let dimensions: any = {};
		let countLoad: number = 0;
		for (const elem in data_) {			
			const img = new Image();
			img.src = data_[elem];
			
			try{
				await waitForImage(img);
				dimensions = {
					...dimensions,
					...{
						[data_[elem]]: {
							height: img.height,
							width: img.width,
						}
					}
				};		
				countLoad++;
			}catch(e:any){
				dimensions = {
					...dimensions,
					...{
						[data_[elem]]: {
							height: -1,
							width: -1,
						}
					}
				};
				setImagesLoadError(true);
			}
		}
		if (countLoad==data_.length) {
			setImagesLoadError(false);
			setImages(data);
		}
		setImagesLoad(dimensions);
		setOpen(true);
	};


	const handleClose = () => {
		setOpen(false);
	};	

	const handleSubmit = () => {
		handleClose();
		props?.getValues({
			id: 'images',
			value: imagesUpload.length > 0 ? imagesUpload : images,
			upload: imagesUpload.length > 0 ? true : false,
			nameAll: nameAll
		});
		setImagesUpload([] as never[]);
	};

	const handleMouseClickClose = () => {
		setImages('');
	};
	//const imgRef:any = React.createRef(); 
	const handleSubmitBDI = () => {
		dispatch(getImagesBDI({ country: user.user.countryCode.toLowerCase(), mfrpartnumber:mfrpartnumber }));
	};

	const handleImages = (images:any) => {
		setImages(images.join());
	}

	return (
		<div>
			{/* <Button variant='outlined' >
				{name}  */}
			{data ? (
				<>
					<Badge badgeContent={data?.split(',').length} color='success'>
						{imagesLoadError && <PhotoLibraryIcon style={{ cursor: 'pointer' }} onClick={handleClickOpen} />}
						{!imagesLoadError && (
							<ImageListItem
								style={{ width: '60px', cursor: 'pointer' }}
								onClick={handleClickOpen}
								key={data?.split(',')[0]}
							>
								<img
									style={{ borderRadius: '10px' }}
									width='60px'
									srcSet={`${data?.split(',')[0]}`}
									src={`${data?.split(',')[0]}`}
									alt={``}
									loading='lazy'
								/>
							</ImageListItem>
						)}
					</Badge>
				</>
			) : (
				<NoPhotographyRoundedIcon style={{ cursor: 'pointer' }} onClick={handleClickOpen} />
			)}
			{/* </Button> */}
			<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						//onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							{user.user.super && <Button onClick={handleSubmitBDI}>Buscar BDI</Button>}
							{bdiimages &&
								Object.keys(bdiimages)?.map((m: any, key: number) => {
									return (
										<Button
											key={key}
											onClick={() => {
												handleImages(bdiimages[m]);
											}}
										>
											{m}
										</Button>
									);
								})}
							{user.user.super && (
								<div>
									<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
										<TextField
											/* defaultValue={images}*/
											value={images}
											margin='dense'
											id='images'
											label='Imagenes'
											multiline
											maxRows={6}
											fullWidth
											variant='standard'
											onPaste={(event) => {
												setImagesUpload([]);
												setImages(
													[...(images?.split(',') || []), ...[event.clipboardData.getData('text')]]
														.join(',')
														.replace(/^,+|,+$/gm, '')
												);
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position='start'>
														<IconButton aria-label='toggle visibility' onClick={handleMouseClickClose}>
															<Close />
														</IconButton>
														<IconButton aria-label='toggle visibility' onClick={handleMouseClickClose}>
															<ImageUploading
																multiple
																value={imagesUpload}
																onChange={onChangeUpload}
																maxNumber={maxNumber}
															>
																{({ onImageUpload }) => (
																	<>
																		<CloudUploadIcon onClick={onImageUpload} />
																	</>
																)}
															</ImageUploading>
														</IconButton>
														<Checkbox
															//onClick={handleChangeCat2}
															onClick={() => {
																setNameAll(!nameAll);
															}}
															id='nameAll'
															checked={nameAll}
														/>
													</InputAdornment>
												),
											}}
											//onChange={(e) => setImagesValue(e.target.value)}
										/>
									</FormControl>
									{!data && (
										<ImageList sx={{ width: 800, height: images?.length > 0 ? 200 : 0 }} cols={4} rowHeight={250}>
											{images?.length > 0 &&
												images?.split(',').map((item: any) => {
													const itemImage: any = `${item}`;
													return (
														<ImageListItem key={item}>
															<div>
																<img height='200px' srcSet={itemImage} src={itemImage} alt={``} loading='lazy' />
																<ImageListItemBar
																	style={{ padding: '5px' }}
																	subtitle={
																		<span>
																			H:{imagesLoad[itemImage]?.height || 0} / W: {imagesLoad[itemImage]?.width || 0}
																		</span>
																	}
																	position='below'
																/>
															</div>
														</ImageListItem>
													);
												})}
										</ImageList>
									)}
								</div>
							)}

							{data && imagesUpload.length <= 0 && (
								<ImageList sx={{ width: 800, height: 200 }} cols={4} rowHeight={250}>
									{images?.split(',').map((item: any) => {
										const itemImage: any = `${item}`;
										return (
											<div key={item}>
												<img height='200px' srcSet={itemImage} src={itemImage} alt={``} loading='lazy' />
												<ImageListItemBar
													style={{ padding: '5px' }}
													subtitle={
														<span>
															H:{imagesLoad[itemImage]?.height || 0} / W: {imagesLoad[itemImage]?.width || 0}
														</span>
													}
													position='below'
												/>
											</div>
										);
									})}
								</ImageList>
							)}

							{imagesUpload.length > 0 && (
								<ImageList sx={{ width: 800, height: 200 }} cols={4} rowHeight={250}>
									{imagesUpload?.map((item: any, index: any) => (
										<div key={item.id}>
											<img height='200px' srcSet={`${item.dataURL}`} src={`${item.dataURL}`} alt={``} loading='lazy' />
											<ImageListItemBar
												style={{ padding: '5px' }}
												subtitle={
													<span>
														H:{imagesLoad[index]?.height || 0} / W: {imagesLoad[index]?.width || 0}
													</span>
												}
												position='below'
											/>
										</div>
									))}
								</ImageList>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cerrar</Button>
							{user.user.super && <Button onClick={handleSubmit}>Cargar</Button>}
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};
