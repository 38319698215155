import { ProductsActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { ProductsSearch, SheetsBDI,ImagesBDI, ReportStatusState } from '../products/types';
import axios from "axios";

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<ReportStatusState>>;

export const getProducts: ActionCreator<ThunkAction<void, ApplicationState, ProductsSearch, Action<ProductsSearch>>> = (product) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<ProductsSearch>(REACT_APP_API_URL + '/products', product, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: ProductsActionTypes.GET_PRODUCTS, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: ProductsActionTypes.GET_PRODUCTS_FAILURE, payload: 500 });
				});        
		} catch (e) {
			return dispatch({ type: ProductsActionTypes.GET_PRODUCTS_FAILURE, payload: 500 });
		}
	};
};

export const getImagesBDI: ActionCreator<ThunkAction<void, ApplicationState, ImagesBDI, Action<ImagesBDI>>> = (imagesbdi) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<ImagesBDI>(
					REACT_APP_API_URL + '/products/bdi/images',
					imagesbdi,
					{
						headers: {
							Accept: 'application/json',
							Authorization: getAuthorization(),
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					return dispatch({ type: ProductsActionTypes.GET_BDIIMAGES, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: ProductsActionTypes.GET_BDIIMAGES_FAILURE, payload: 500 });
				});
		} catch (e) {
			return dispatch({ type: ProductsActionTypes.GET_BDIIMAGES_FAILURE, payload: 500 });
		}
	};
};

export const getSheetsBDI: ActionCreator<ThunkAction<void, ApplicationState, ImagesBDI, Action<SheetsBDI>>> = (sheetsbdi) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<SheetsBDI>(
					REACT_APP_API_URL + '/products/bdi/sheets',
					sheetsbdi,
					{
						headers: {
							Accept: 'application/json',
							Authorization: getAuthorization(),
							'Content-Type': 'application/json',
						},
					}
				)
				.then((response) => {
					return dispatch({ type: ProductsActionTypes.GET_BDISHEETS, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: ProductsActionTypes.GET_BDISHEETS_FAILURE, payload: 500 });
				});
		} catch (e) {
			return dispatch({ type: ProductsActionTypes.GET_BDISHEETS_FAILURE, payload: 500 });
		}
	};
};

export const crudProducts: ActionCreator<ThunkAction<void, ApplicationState, ProductsSearch, Action<ProductsSearch>>> = (
	product
) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<ProductsSearch>(REACT_APP_API_URL + `/products/crud`, product, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: ProductsActionTypes.PUT_PRODUCTS, payload: { response:response?.data,product:product } });
				})
				.catch((err) => {
					console.log(err)
					/* return dispatch({
						type: ProductsActionTypes.PUT_PRODUCTS,
						payload: { error: { message: err.response.data.error, code: err.response.status } },
					}); */
				});
		} catch (e) {
			return dispatch({ type: ProductsActionTypes.PUT_PRODUCTS, payload: 500 });
		}
	};
};

export const getProductsReport: ActionCreator<ThunkAction<void, ApplicationState, ProductsSearch, Action<ProductsSearch>>> =
	(product) => {
		return (dispatch: Dispatch) => {
			try {
				return axios
					.post<ProductsSearch>(REACT_APP_API_URL + `/products/report`, product, {
						headers: {
							Accept: 'application/json',
							Authorization: getAuthorization(),
							'Content-Type': 'application/json',
						},
						responseType: 'arraybuffer',
					})
					.then((response) => {
						return dispatch({
							type: ProductsActionTypes.REPORT_PRODUCTS,
							payload: { response: response?.data, product: undefined },
						});
					})
					.catch((err) => {
						console.log(err);
						return dispatch({type: ProductsActionTypes.REPORT_PRODUCTS_FAILURE, payload: { error: { message: err.response.data.error, code: err.response.status } },
					});
					});
			} catch (e) {
				return dispatch({ type: ProductsActionTypes.REPORT_PRODUCTS_FAILURE, payload: 500 });
			}
		};
	};

	export const putReport: ActionCreator<ThunkAction<void, ApplicationState, ReportStatusState, Action<boolean>>> = (
		report
	) => {
		return (dispatch: Dispatch) => {
			try {
				console.log(report)
				return dispatch({
					type: ProductsActionTypes.REPORT_STATUS_PRODUCTS,
					payload: report,
				});
			} catch (e) {
				return dispatch({
					type: ProductsActionTypes.REPORT_STATUS_PRODUCTS,
					payload: false,
				});
			}
		};
};
	
export const getBrs: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = () => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.get<any>(REACT_APP_API_URL + '/products/brs', {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: ProductsActionTypes.GET_BRS, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: ProductsActionTypes.GET_BRS_FAILURE, payload: 500 });
				});
		} catch (e) {
			return dispatch({ type: ProductsActionTypes.GET_BRS_FAILURE, payload: 500 });
		}
	};
};

