import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function createData(endpoint: string, update: string, type:string, action: string, reque: string, title: string, def: string, link: string) {
	return { endpoint, update, type,action, reque, title, def, link };
}

const rows = [
	createData('POST api/products', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'report', '"json ó excel" genera un reporte de productos para el usuario', 'Si se omite, se segmenta el resultado'),
	createData('POST api/products', 'Abr/2024', 'Object', 'Request Body', 'Si', 'filters', 'Objeto filters, filtra la respuesta', 'Necesario para accesos a recursos'),
	createData('POST api/products', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'search', 'Campo comodin, busca en base de datos el texto. puede enviar SKU\'s VPN, Vendors Numbers separados por coma', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Date', 'Request Body', 'Opcional [Productos Nuevos]', 'createdAt', 'Fecha de creación del producto, si fecha es igual o mayor se muestra, para obtener "Productos Nuevos" "Formato de fecha: 2023-12-27" restar dos dias a fecha actual', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Date', 'Request Body', 'Opcional', 'updatedAt', 'Fecha de actualización de producto, si fecha es igual o mayor se muestra, "Formato de fecha: 2023-12-27"', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Date', 'Request Body', 'Opcional [Imagenes Optimizadas]', 'optimizedImg', 'Fecha de imagen optimizada, si fecha es igual o mayor se muestra, para obtener "Imagenes optimizadas o agregada recientemente" "Formato de fecha: 2023-12-27" restar dos dias a fecha actual', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Date', 'Request Body', 'Opcional [Ficha Técnica Optimizada]', 'optimizedSheets', 'Fecha de Ficha técnica optimizada, si fecha es igual o mayor se muestra, para obtener "Ficha técnica optimizada o agregada recientemente" "Formato de fecha: 2023-12-27" restar dos dias a fecha actual', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Date', 'Request Body', 'Opcional [Titulo, Descr. Corta Optimizada]', 'optimized', 'Fecha de Titulo, Descr. corta, categoria, marca optimizada, si fecha es igual o mayor se muestra, para obtener "Titulo, Descr. corta, categoria, marca optimizada recientemente" "Formato de fecha: 2023-12-27" restar dos dias a fecha actual', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'price', 'Precio del producto, si precio es mayor ó igual. se muestra', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'inventory', 'Inventario del producto, si inventario es igual o mayor, se muestra', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'image', 'Producto con imagen valor del campo "images", Producto sin imagen valor del campo "notimages"', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'sheet', 'Producto con ficha técnica valor del campo "sheets", Producto sin ficha técnica valor del campo "notsheets"', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Boolean', 'Request Body', 'Si', 'active', 'Productos activos Precio > 0 y Inventario > 0 y Autorizado para venta web valor de campo "active"', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Boolean', 'Request Body', 'Si', 'inactive', 'Productos inactivos no autorizados ó fuera de stock, valor del campo "inactive"', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Array [String]', 'Request Body', 'Opcional', 'categories', 'Lista o array de categorias a buscar [\'Accesorios\',\'Componentes\'] segun categorias de endpoint api/categories','padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Array [String]', 'Request Body', 'Opcional', 'manufacturer', 'Lista o array de fabricantes a buscar [\'Acer\',\'Lenovo\'] segun fabricantes de endpoint api/manufacturer', 'padre del campo filters'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'rowsPerPage', 'Número de renglones por página', 'si "report" no existe'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'page', 'Página del segmentado a mostrar', 'si "report" no existe'),

	createData('POST api/products', 'Abr/2024', 'Array [Object products]', 'Response', '-', 'products', 'Lista de productos segun filtros presentados en el body', ''),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].active', 'Estado del producto Activo "active" ó Inactivo "inactive" sólo activo para venta web', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].sku', 'Identificador unico Ingram sku', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].price', 'Precio del producto ingram', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].inventory', 'Inventario disponible del producto', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].priceApply', 'Precio aplicado del producto, dentro del administrador puede configurar utilidad e iva, el precio aplicado es "Precio del producto ingram + utilidad + iva"', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].currencyCode', 'Tipo de moneda del precio', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'DateTime', 'Response', '-', 'products[].updatedAt', 'Fecha del producto actulizado', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'DateTime', 'Response', '-', 'products[].products', 'Objeto productos, caracteristicas del producto', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.description', 'Titulo o descripción del producto', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.productDetailDescription', 'Descripción corta del producto', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.sheet', 'Url de la ficha técnica del producto en Tabla HTML','padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.sheetJson', 'Url de la ficha técnica del producto en Objeto Json', 'padre del campo products'),
	createData('POST api/products', 'Mayo/2024', 'Array [String()]', 'Response', '-', 'products[].products.listPrimaryAttribute', 'Lista de atributos principales del producto, obtenido de sheets', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Array [technicalSpecifications]', 'Response', '-', 'products[].products.technicalSpecifications', 'Ficha técnica del producto en formato Json', 'padre del campo products, se presenta solo si "report" no existe'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.technicalSpecifications[].headerName', 'Titulo agrupador del atributo', 'padre del campo technicalSpecifications'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.technicalSpecifications[].attributeName', 'Nombre del atributo o de la caracteristica del producto', 'padre del campo technicalSpecifications'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.technicalSpecifications[].attributeDisplay', 'Vista unificada de la caracteristica del producto', 'padre del campo technicalSpecifications'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].products.technicalSpecifications[].attributeValue', 'Valor del atributo o de la caracteristica del producto', 'padre del campo technicalSpecifications'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].vendornumber', 'Identificador del fabricante VPN, Vendor Number', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].upcnumber', 'Codigo de barras del fabricante', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].images', 'Lista de imagenes separada por comas', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].categoriesIdIngram', 'Titulo de la categoria', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].manufacturerIdIngram', 'Titulo del fabricante', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Object', 'Response', '-', 'products[].manufacturerIngram', 'Objeto del fabricante de endpoint api/manufacturer', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].manufacturerIngram.id', 'Identificador unico del fabricante', 'padre del campo manufacturerIngram'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].manufacturerIngram.name', 'Nombre del fabricante', 'padre del campo manufacturerIngram'),
	createData('POST api/products', 'Abr/2024', 'Object', 'Response', '-', 'products[].categoriesIngram', 'Objeto de la categoria de endpoint api/categories',''),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].categoriesIngram.id', 'Identificador unico categoria', 'padre del campo products'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].categoriesIngram.nameTemp', 'Nombre de la categoria', 'padre del campo categoriesIngram'),
	createData('POST api/products', 'Abr/2024', 'Object', 'Response', '-', 'products[].categoriesIngram.parent', 'Objeto padre de categoria', 'padre del campo categoriesIngram'),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'products[].categoriesIngram.parent.id', 'Identificador unico del padre de categoria', 'padre del campo parent'),
	createData('POST api/products', 'Abr/2024', 'String', 'Response', '-', 'products[].categoriesIngram.parent.name', 'Nombre unico del padre de categoria', 'padre del campo parent'),

	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'totalPages', 'Total de páginas de la solicitud', ''),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'total', 'Total de páginas de productos', ''),
	createData('POST api/products', 'Abr/2024', 'Number', 'Response', '-', 'page', 'Página actual', ''),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function BasicTable() {
	const [search, setSearch] = React.useState('');
	const [valueRadio, setValueRadio] = React.useState('');
	const [valueRadioNodos, setValueRadioNodos] = React.useState('Todos');
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValueRadio((event.target as HTMLInputElement).value);
	};

	const handleChangeNodos = (event: SelectChangeEvent) => {
		setValueRadioNodos(event.target.value as string);
	};

	return (
		<Paper sx={{ width: '100%' }}>
			<Box>
				<FormControl>
					<TextField
						margin='dense'
						id='search'
						label='Buscar texto'
						type='text'
						variant='standard'
						onChange={(e) => setSearch(e.target.value)}
					/>
				</FormControl>
				<FormControl>
					<InputLabel id='demo-simple-select-label'>Nodos</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						value={valueRadioNodos}
						label='Nodos'
						onChange={handleChangeNodos}
						sx={{ width: 'auto' }}
					>
						<MenuItem value={'Todos'}>Todos</MenuItem>
						<MenuItem value={'products[].'}>products[].</MenuItem>
						<MenuItem value={'products[].products.'}>products[].products.</MenuItem>
						<MenuItem value={'products[].products.technicalSpecifications[].'}>products[].products.technicalSpecifications[].</MenuItem>
						<MenuItem value={'products[].manufacturerIngram.'}>products[].manufacturerIngram.</MenuItem>
						<MenuItem value={'products[].categoriesIngram.'}>products[].categoriesIngram.</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<FormLabel id='demo-row-radio-buttons-group-label'>Acciones</FormLabel>
					<RadioGroup
						row
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={valueRadio}
						onChange={handleChange}
					>
						<FormControlLabel value='Response' control={<Radio />} label='Response' />
						<FormControlLabel value='Request' control={<Radio />} label='Request' />
						<FormControlLabel value='' control={<Radio />} label='Todo' />
					</RadioGroup>
				</FormControl>
			</Box>
			<TableContainer sx={{ maxHeight: 340 }}>
				<Table stickyHeader aria-label='customized sticky table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>EndPoint</StyledTableCell>
							<StyledTableCell align='right'>Update</StyledTableCell>
							<StyledTableCell align='right'>Tipo</StyledTableCell>
							<StyledTableCell align='right'>Acción</StyledTableCell>
							<StyledTableCell align='right'>Requerido</StyledTableCell>
							<StyledTableCell align='right'>Titulo (Campo en Acción)</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Definición
							</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Enlace
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.filter((r: any) =>
								`${r.reque} ${r.title} ${r.type} ${r.def} ${r.link}`.toLowerCase().includes(search.toLowerCase())
							)
							.filter((r: any) => `${r.action}`.toLowerCase().includes(valueRadio.toLowerCase()))
							.filter((r: any) =>
								valueRadioNodos.replace('Todos','') !== 'products[].'
									? `${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos','').toLowerCase())
									: `${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos','').toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].products.'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].products.technicalSpecifications[].'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].manufacturerIngram.'.toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('products[].categoriesIngram.'.toLowerCase())
							)
							.map((row: any) => (
								<StyledTableRow key={row.endpoint} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<StyledTableCell component='th' scope='row'>
										{row.endpoint}
									</StyledTableCell>
									<StyledTableCell align='right'>{row.update}</StyledTableCell>
									<StyledTableCell align='right'>{row.type}</StyledTableCell>
									<StyledTableCell align='right'>{row.action}</StyledTableCell>
									<StyledTableCell align='right'>{row.reque}</StyledTableCell>
									<StyledTableCell align='right'>{row.title}</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.def}
									</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.link}
									</StyledTableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
