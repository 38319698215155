import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentationJson from './ApiDocumentationJson'
import DocumentationCode from './ApiDocumentationCode';

import php from '../../../assets/images/php.png';
import node from '../../../assets/images/node.png';
import python from '../../../assets/images/python.png';
import curl from '../../../assets/images/curl.png';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Collapse from '@mui/material/Collapse';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import Tooltip from '@mui/material/Tooltip';

import DialogDiccionario from './ApiDiccionario';

import DiccionarioSingIn from '../Diccionario/Singin';
import DiccionarioImages from '../Diccionario/Images';
import DiccionarioSheets from '../Diccionario/Sheets';
import DiccionarioSheetsJson from '../Diccionario/SheetsJson';
import DiccionarioProducts from '../Diccionario/Products';
import DiccionarioPrecios from '../Diccionario/Precios';
import DiccionarioFabricantes from '../Diccionario/Fabricantes';
import DiccionarioCategorias from '../Diccionario/Categorias';
import DiccionarioBrsLocations from '../Diccionario/BrsLocations';
import DiccionarioOrders from '../Diccionario/Orders';
import DiccionarioShipping from '../Diccionario/Shipping';
import DiccionarioCountryCode from '../Diccionario/CountryCode';

import MenuBookIcon from '@mui/icons-material/MenuBook';



interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children?: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface ItemProps {
	getValues: Function;
}

interface ApiItemProps {	
	props: ItemProps;	
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

let links = ["./txtCode/phpConnect.txt","./txtCode/nodeConnect.txt"]

export default function ApiDocumentation({ props }: ApiItemProps) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
		props?.getValues(true);
	};

	const handleClose = () => {
		setOpen(false);
		props?.getValues(false);
	};

	const [value, setValue] = React.useState(0);

	

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};	

	const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);

	const handleOpen = (clickedIndex:any) => {
		if (isOpenCollapse === clickedIndex) {
			setIsOpenCollapse(null);
		} else {
			setIsOpenCollapse(clickedIndex);
			setOpenToolTip(true);
		}
	};

	const [openToolTip, setOpenToolTip] = React.useState(false);

	

	return (
		<React.Fragment>
			<>
				<MenuBookIcon onClick={handleClickOpen} />
				<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
					{/* <Box sx={{ flexGrow: 1 }}>
							<AppBar sx={{ position: 'static' }}>
								<Toolbar>
									<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
										<CloseIcon />
									</IconButton>
									
									<Button color='inherit' onClick={handleClose}>
										Web Services
									</Button>
									<Button color='inherit'>Diccionario</Button>
								</Toolbar>
							</AppBar>
						</Box> */}
					<Box sx={{ flexGrow: 1 }}>
						<AppBar position='static'>
							<Toolbar variant='dense'>
								<IconButton
									size='large'
									edge='start'
									color='inherit'
									onClick={handleClose}
									aria-label='menu'
									sx={{ mr: 2 }}
								>
									<CloseIcon />
								</IconButton>
								<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
									Web Services
								</Typography>
								<Tooltip arrow open={openToolTip} placement='left' title={'Click ver Diccionario de Datos'}>
									<IconButton edge='end' color='inherit' aria-label='menu' sx={{ mr: 0 }}>
										<DialogDiccionario />
									</IconButton>
								</Tooltip>
							</Toolbar>
						</AppBar>
					</Box>
					{/* <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}>
					<Tabs
						orientation='vertical'
						variant='scrollable'
						value={value}
						onChange={handleChange}
						aria-label='Vertical tabs example'
						sx={{ borderRight: 1, borderColor: 'divider' }}
					>
						<Tab label='POST /signin' {...a11yProps(0)} />
						<Tab label='GET /api/image' {...a11yProps(1)} />
						<Tab label='GET /api/sheets' {...a11yProps(2)} />
						<Tab label='GET /api/sheets/json' {...a11yProps(3)} />
						<Tab label='POST /api/products' {...a11yProps(4)} />
						<Tab label='POST /api/prices' {...a11yProps(5)} />
						<Tab label='POST /api/manufacturer' {...a11yProps(6)} />
						<Tab label='POST /api/categories' {...a11yProps(7)} />
						<Tab label='GET /api/brslocations' {...a11yProps(8)} />
						<Tab label='PUT /api/order' {...a11yProps(9)} />
						<Tab label='POST /api/shipping' {...a11yProps(10)} />
						<Tab label='GET /api/countrycode' {...a11yProps(11)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						POST /signin
					</TabPanel>
					<TabPanel value={value} index={1}>
						GET /api/image
					</TabPanel>
					<TabPanel value={value} index={2}>
						GET /api/sheets
					</TabPanel>
					<TabPanel value={value} index={3}>
						GET /api/sheets/json
					</TabPanel>
					<TabPanel value={value} index={4}>
						POST /api/products
					</TabPanel>
					<TabPanel value={value} index={5}>
						POST /api/prices
					</TabPanel>
					<TabPanel value={value} index={6}>
						POST /api/manufacturer
					</TabPanel>
					<TabPanel value={value} index={7}>
						POST /api/categories
					</TabPanel>
					<TabPanel value={value} index={8}>
						GET /api/brslocations
					</TabPanel>
					<TabPanel value={value} index={9}>
						PUT /api/order
					</TabPanel>
					<TabPanel value={value} index={10}>
						POST /api/shipping
					</TabPanel>
					<TabPanel value={value} index={11}>
						GET /api/countrycode
					</TabPanel>
				</Box> */}
					<List onMouseOver={() => setOpenToolTip(true)} onMouseOut={() => setOpenToolTip(false)}>
						<ListItem button>
							QServices provee una serie de servicios web para integrar el inventario de Ingram Micro.
							Lineamientos a tomar en cuenta: los request están ubicados por batch, en la mayoría de los casos, lo cual
							implica que los request iniciales están limitados. Contacte a su administrador de soporte Qservices, y solicite
							vía correo el incremento de request según las necesidades.
						</ListItem>
						<ListItem>
							<ListItemText
								sx={{ pl: 4 }}
								primary='URL de entrega'
								secondary={
									<React.Fragment>
										<div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
											{'https://api.shopintegrate.net (endpoint) ó https://api-admin-ingram-5e142a21f0e9.herokuapp.com/ (endpoint)'}
										</div>
									</React.Fragment>
								}
							/>
							<ListItemText
								sx={{ pl: 4 }}
								primary='Sincronizaciones Precios Ingram'
								secondary='Masivos para Precios: 7, 13, 18 horas Lunes - Viernes'
							/>
							<ListItemText
								sx={{ pl: 4 }}
								primary='Sincronizaciones Productos Ingram'
								secondary='Masivos para Productos Nuevos: 8, 16 horas Lunes - Viernes'
							/>
						</ListItem>
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(12)}
							/>
							<ListItemText
								onClick={() => handleOpen(12)}
								sx={{ pl: 0, cursor: 'pointer' }}
								primary='Conector'
								secondary='Conectate a los servicios con CronJob Integrado, npm, yarn, composer'
							/>
							<ListItemText
								style={{ overflowX: 'auto', maxWidth: '350px', scrollbarWidth: 'thin', justifyContent: 'flex-end' }}
								sx={{ pl: 0 }}
								primary={null}
								secondary={
									<React.Fragment>
										<ListItem>
											<DocumentationCode label={php} header={'PHP Conector'} title={['Conector']} data={[`<?php

// cd proyect
// composer init
// composer require react/event-loop react/http
// composer require dragonmantank/cron-expression
// copy proyect in connect.php
// php connect.php

require_once __DIR__ . '/vendor/autoload.php';

use Cron\\CronExpression;
use React\\EventLoop\\Loop;

try {
    if (!defined('ROOT_CONTENTS_IMAGES')){define('ROOT_CONTENTS_IMAGES', 'images');}
    if (!defined('ROOT_CONTENTS_SHEETS')){define('ROOT_CONTENTS_SHEETS', 'sheets');}
    if (!defined('ROOT_LOGS')){define('ROOT_LOGS', 'logs/');}

    if (!is_dir(ROOT_CONTENTS_IMAGES)) if(!mkdir(ROOT_CONTENTS_IMAGES, 0775, true)) {  }
    if (!is_dir(ROOT_CONTENTS_SHEETS)) if(!mkdir(ROOT_CONTENTS_SHEETS, 0775, true)) {  }
    if (!is_dir(ROOT_LOGS)) if(!mkdir(ROOT_LOGS, 0775, true)) {  }

    if (!defined('USER')){define('USER', 'superj@customer.com');}/*<---------------- edit*/
    if (!defined('PASS')){define('PASS', 'yIP9fj4I8g');}/*<---------------- edit*/

    if (!defined('TIME_ZONE')){define('TIME_ZONE', 'America/Mexico_City');}/*<-------------- edit*/

    if (!defined('CRON_NEW_PRODUCTS')){define('CRON_NEW_PRODUCTS', '0 8,15 * 1-5 *');}
    if (!defined('CRON_PRODUCTS_LOAD_INIT_QUERY')) {define('CRON_PRODUCTS_LOAD_INIT_QUERY', ["report"=>"json","filters"=>["active"=>true]]);}
    if (!defined('CRON_NEW_PRODUCTS_QUERY')) {define('CRON_NEW_PRODUCTS_QUERY', ["report"=>"json","filters"=>["active"=>true,"createdAt"=> (new DateTime('now', new DateTimeZone(TIME_ZONE)))->format('Y-m-d')]]);}

    if (!defined('CRON_PRICES')){define('CRON_PRICES', '0 8,13,19 * 1-5 *');}
    if (!defined('CRON_PRICES_ACTIVE_QUERY')) {define('CRON_PRICES_ACTIVE_QUERY', ["report" => "json", "filters" => ["active" => true]]);}
    if (!defined('CRON_PRICES_INACTIVE_QUERY')) {define('CRON_PRICES_INACTIVE_QUERY', ["report" => "json", "filters" => ["inactive" => true]]);}

    if (!defined('CRON_OPTIMIZED_IMAGES')){define('CRON_OPTIMIZED_IMAGES', '0 11,20 * 1-5 *');}
    if (!defined('CRON_OPTIMIZED_IMAGES_QUERY')){define('CRON_OPTIMIZED_IMAGES_QUERY', ["report" => "json", "filters" => ["active"=> true,"image" => "images", "optimizedImg"=> (new DateTime('now', new DateTimeZone(TIME_ZONE)))->format('Y-m-d')]]);}

    if (!defined('CRON_OPTIMIZED_SHEETS')){define('CRON_OPTIMIZED_SHEETS', '0 10,19 * 1-5 *');}
    if (!defined('CRON_OPTIMIZED_SHEETS_QUERY')){define('CRON_OPTIMIZED_SHEETS_QUERY', ["report" => "json", "filters" => ["active"=> true,"sheets" => "sheets", "optimizedSheets" => (new DateTime('now', new DateTimeZone(TIME_ZONE)))->format('Y-m-d')]]);}

    if (!defined('CRON_OPTIMIZED')){define('CRON_OPTIMIZED', '30 10,19 * 1-5 *');}
    if (!defined('CRON_OPTIMIZED_QUERY')){define('CRON_OPTIMIZED_QUERY', ["report" => "json", "filters" => ["active"=>true,"optimized" => (new DateTime('now', new DateTimeZone(TIME_ZONE)))->format('Y-m-d')]]);}

    if (!defined('CRON_CATEGORIES')){define('CRON_CATEGORIES', '0 7,20 * 1-5 *');}
    if (!defined('CRON_CATEGORIES_QUERY')){define('CRON_CATEGORIES_QUERY', ["ingram" => true, "rowsPerPage"=>1000,"page"=>0]);}

    if (!defined('CRON_MANUFACTURER')){define('CRON_MANUFACTURER', '30 7,20 * 1-5 *');}
    if (!defined('CRON_MANUFACTURER_QUERY')){define('CRON_MANUFACTURER_QUERY', ["rowsPerPage" => 1000, "page" => 0]);}
} catch (Exception $e) {
    $dateLog = date("Y-m-d");
    $logFile = fopen("logs/log-{$dateLog}.txt", 'a') or die("Error creando archivo");
    fwrite($logFile, "\\n" . date("d/m/Y H:i:s") . " Error App - " . 'Excepción capturada: ',  $e->getMessage(), "\\n") or die("Error escribiendo en el archivo");
    fclose($logFile);
}

class Sync
{
    private $tokenAccount;
    private $token;
    private $tokenContents;
    private $user;
    private $pass;

    // Constructor
    public function __construct($user=null,$pass=null)
    {
        $this->tokenAccount = 0;
        $this->token = null;
        $this->tokenContents = null;
        $this->user = $user;
        $this->pass = $pass;
    }

    public function logConnect(string $message): void {
        try{
            $dateLog = date("Y-m-d");
            $logFile = fopen(ROOT_LOGS."log-{$dateLog}.txt", 'a') or die("Error creando archivo");
            fwrite($logFile, "\\n" . date("d/m/Y H:i:s") . " {$message}") or die("Error escribiendo en el archivo");
            fclose($logFile);
        } catch (Exception $e) {
            $dateLog = date("Y-m-d");
            $logFile = fopen("logs/log-{$dateLog}.txt", 'a') or die("Error creando archivo");
            fwrite($logFile, "\\n" . date("d/m/Y H:i:s") . " Error App - " . 'Excepción capturada: ',  $e->getMessage(), "\\n") or die("Error escribiendo en el archivo");
            fclose($logFile);
        }
    }

    public function tokenAccount(): void
    {
        try{
            $this->tokenAccount = $this->tokenAccount++;
            if($this->tokenAccount >= 5){ $this->logConnect("max getToken - token"); exit; }
        } catch (Exception $e) {
            $this->logConnect(" Error App - tokenAccount - " . $e->getMessage());
        }
    }

    public function schedule(callable $task, CronExpression $cron, string $time_zone): void
    {
        try{
            if(file_exists('exit_app.txt'))exit;
            $now = new DateTime('now', new DateTimeZone($time_zone));
            // CRON is due, run task asap:
            if ($cron->isDue($now)) {
                Loop::futureTick(function () use ($task) {
                    $task();
                });
            }
            // Function that executes the task
            // and adds a timer to the event loop to execute the function again when the task is next due:
            $schedule = function () use (&$schedule, $task, $cron,$time_zone) {
                $task();
                $now = new DateTime('now', new DateTimeZone($time_zone));//new DateTime();
                $nextDue = $cron->getNextRunDate($now);
                Loop::addTimer($nextDue->getTimestamp() - $now->getTimestamp(), $schedule);
            };
            // Add a timer to the event loop to execute the task when it is next due:
            $nextDue = $cron->getNextRunDate($now);
            Loop::addTimer($nextDue->getTimestamp() - $now->getTimestamp(), $schedule);
        } catch (Exception $e) {
            $this->logConnect(" Error App - ".$e->getMessage());
        }
    }

    public function token(): void
    {
        try{
            $curl = curl_init();
            curl_setopt_array($curl, array(
                CURLOPT_URL => 'https://api.shopintegrate.net/signin',
                CURLOPT_RETURNTRANSFER => true,
                CURLOPT_ENCODING => '',
                CURLOPT_MAXREDIRS => 10,
                CURLOPT_TIMEOUT => 0,
                CURLOPT_FOLLOWLOCATION => true,
                CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                CURLOPT_CUSTOMREQUEST => 'POST',
                CURLOPT_POSTFIELDS => json_encode(['email'=>$this->user,'password'=>$this->pass], JSON_UNESCAPED_UNICODE | JSON_UNESCAPED_SLASHES),
                CURLOPT_HTTPHEADER => array(
                    'Content-Type: application/json'
                ),
            ));

            $response = curl_exec($curl);

            $code = curl_getinfo($curl, CURLINFO_HTTP_CODE);
            curl_close($curl);
            
            if ($code == 201 || $code == 200) {
                $objectResponse = json_decode($response);  
                
                $this->token = ((isset($objectResponse->token) && $objectResponse->signup) ? $objectResponse->token: null);
                $this->tokenContents = ((isset($objectResponse->user->tokenAppContents) && $objectResponse->signup) ? $objectResponse->user->tokenAppContents.(isset($objectResponse->user->sandboxPassword)?':'. $objectResponse->user->sandboxPassword:''):null);

                if(is_null($this->token)){
                    $this->logConnect("response - token - is null - {$code}");
                    sleep(60);
                    $this->token();
                    $this->tokenAccount();
                }else{
                    $this->logConnect("response - token - unauthorized- {$code}");
                    $this->tokenAccount=0;
                }
            } else {

                if ($code == 401) {
                    $this->logConnect("response - token - too many request - {$code}");
                    sleep(60);
                    $this->token();
                    $this->tokenAccount();
                }else{
                    $this->token = null;
                }
            }        
        } catch (Exception $e) {
            $this->logConnect(" Error App - Token" . $e->getMessage());
        }
    }

    public function get(string $url): ?string
    {
        try{
            if (is_null($this->token)) $this->token();
            
            $curl = curl_init();
            curl_setopt_array($curl, array(
                CURLOPT_URL => $url,
                CURLOPT_RETURNTRANSFER => true,
                CURLOPT_ENCODING => '',
                CURLOPT_MAXREDIRS => 10,
                CURLOPT_TIMEOUT => 0,
                CURLOPT_FOLLOWLOCATION => true,
                CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                CURLOPT_CUSTOMREQUEST => 'GET',
            ));

            $response = curl_exec($curl);
            $code = curl_getinfo($curl, CURLINFO_HTTP_CODE);
            curl_close($curl);

            if ($code == 201 || $code == 200) {
                return $response;
            } else {
                if($code == 401){
                    $this->logConnect("response - unautorized - {$url} - {$code} - {$response}");
                    sleep(60);
                    $this->token();
                    $this->tokenAccount();
                    return $this->get($this->changeToken($url));
                }
                if ($code == 429) {
                    $this->logConnect("response - too many request - {$url} - {$code} - {$response}");
                    return null;
                }
            }   
        } catch (Exception $e) {
            $this->logConnect(" Error App - GET - " . $e->getMessage());
            return null;
        }
    }

    public function post($CURLOPT_POSTFIELDS = [], $ENDPOINT): ?object
    {
        try{
            if(is_null($this->token))$this->token();

            $this->logConnect("request - {$ENDPOINT} - ". json_encode($CURLOPT_POSTFIELDS, JSON_UNESCAPED_UNICODE | JSON_UNESCAPED_SLASHES));

            $curl = curl_init();
            curl_setopt_array($curl, array(
                CURLOPT_URL => "https://api.shopintegrate.net/api/{$ENDPOINT}",
                CURLOPT_RETURNTRANSFER => true,
                CURLOPT_ENCODING => '',
                CURLOPT_MAXREDIRS => 10,
                CURLOPT_TIMEOUT => 0,
                CURLOPT_FOLLOWLOCATION => true,
                CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
                CURLOPT_CUSTOMREQUEST => 'POST',
                CURLOPT_POSTFIELDS => json_encode($CURLOPT_POSTFIELDS,JSON_UNESCAPED_UNICODE|JSON_UNESCAPED_SLASHES),
                CURLOPT_HTTPHEADER => array(
                    'Content-Type: application/json',
                    "Authorization: Bearer {$this->token}"
                ),
            ));
            $response = curl_exec($curl);
            
            $code = curl_getinfo($curl, CURLINFO_HTTP_CODE);
            curl_close($curl);

            if ($code == 201 || $code == 200) {
                $objectResponse = json_decode($response);
                $this->logConnect("response POST - Success - {$code} - {$ENDPOINT} - {$objectResponse->total}");
                return $objectResponse;
            } else {            
                if ($code == 401) {
                    $this->logConnect("response - unauthorized - {$code} - {$ENDPOINT} ". json_encode($CURLOPT_POSTFIELDS, JSON_UNESCAPED_UNICODE | JSON_UNESCAPED_SLASHES)." - ". $response);
                    sleep(60);
                    $this->token();
                    $this->tokenAccount();
                    return $this->post($CURLOPT_POSTFIELDS, $ENDPOINT);
                }
                if ($code == 429) {
                    $this->logConnect("response - too many request - {$code} - {$ENDPOINT} " . json_encode($CURLOPT_POSTFIELDS, JSON_UNESCAPED_UNICODE | JSON_UNESCAPED_SLASHES)." - ".$response);
                    return null;
                }
            }   
        } catch (Exception $e) {
            $this->logConnect(" Error App - " . $e->getMessage());
            return null;
        }
        
    }

    public function changeToken(string $url): string {
        try{
            $segmentTokensUrl = explode('/', $url);
            $segExtUrl = explode('.', end($segmentTokensUrl));
            $extUrl = end($segExtUrl);
            array_pop($segmentTokensUrl);
            $getUrl = implode('/', $segmentTokensUrl);
            $resourceUrl = "{$getUrl}/{$this->tokenContents}.{$extUrl}";
            return $resourceUrl;
        } catch (Exception $e) {
            $this->logConnect(" Error App - changeToken - " . $e->getMessage());
            return $url;
        }
    }

    public function readContents($products): void
    {
        try{

            foreach($products->products as $product){

                if(isset($product->products->images)&&!empty($product->products->images)){                
                    $images = explode(',', $product->products->images);
                    foreach($images as $key=>$image){
                        $keyIndex = $key-1;
                        $keyImage = (($key==0)?'':"_{$keyIndex}");
                        $resourceImage = $this->get($this->changeToken($image));
                        if(!is_null($resourceImage)){file_put_contents(ROOT_CONTENTS_IMAGES."/{$product->sku}{$keyImage}.jpg",$resourceImage);}
                        sleep(1);
                    }
                }

                if(isset($product->products->sheet)&&!empty($product->products->sheet)){                
                    $resourceSheet = $this->get($this->changeToken($product->products->sheet));
                    if(!is_null($resourceSheet)){ file_put_contents(ROOT_CONTENTS_SHEETS."/{$product->sku}.html", $resourceSheet); }
                    sleep(1);
                }

            }

        } catch (Exception $e) {
            $this->logConnect(" Error App - readContents - " . $e->getMessage());
        }

    }

    public function set_token($token){$this->token = $token;}
    public function get_token(){return $this->token;}
    public function set_tokenContents($tokenContents){$this->tokenContents = $tokenContents;}
    public function get_tokenContents(){return $this->tokenContents;}
    public function set_tokenAccount($tokenAccount){$this->tokenAccount = $tokenAccount;}
    public function get_tokenAccount(){return $this->tokenAccount;}

}

try{
    $sync = new Sync(USER,PASS);

    /////////////CARGA INICIAL DE PRODUCTOS/////////////////////////////////
    /*
    $productsLoadInit = $sync->post(CRON_PRODUCTS_LOAD_INIT_QUERY, 'products');
    $sync->readContents($productsLoadInit);
    */
    //echo "Carga Inicial Products\\n";
    /*
    include "script-integración-plataforma.php";
    */
    ////////////////////////////////////////////////////////////////////////

    /////////////////////////////PRODUCTOS NUEVOS///////////////////////////
    $cron = new CronExpression(CRON_NEW_PRODUCTS);
    $task = function () use($sync) {
        echo "new Products\\n";
        
        $query= CRON_NEW_PRODUCTS_QUERY;
        $query["filters"]["createdAt"] = (new DateTime('now', new DateTimeZone(TIME_ZONE)))->sub(new DateInterval('P1D'))->format('Y-m-d');
        $productsNews = $sync->post($query,'products');
        
        $sync->readContents($productsNews);
        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////PRECIOS////////////////////////////////////
    $cron = new CronExpression(CRON_PRICES);
    $task = function () use ($sync) {
        echo "Prices\\n";
        /* 
        $pricesActive = $sync->post(CRON_PRICES_ACTIVE_QUERY, 'prices');
        $pricesInActive = $sync->post(CRON_PRICES_INACTIVE_QUERY, 'prices');     
        */
        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON IMAGENES ACTUALIZADAS O CARGA DE IMAGEN RECIENTE///////////
    $cron = new CronExpression(CRON_OPTIMIZED_IMAGES);
    $task = function () use ($sync) {
        echo "Images\\n";
        /* 
        $query = CRON_OPTIMIZED_IMAGES_QUERY;
        $query["filters"]["optimizedImg"] = (new DateTime('now', new DateTimeZone(TIME_ZONE)))->sub(new DateInterval('P1D'))->format('Y-m-d');
        $productsImagesOptimized = $sync->post($query, 'products'); 
        $sync->readContents($productsImagesOptimized);
        */
        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON FICHAS TÉCNICAS ACTUALIZADAS O CARGA DE FICHA RECIENTE//
    $cron = new CronExpression(CRON_OPTIMIZED_SHEETS);
    $task = function () use ($sync) {
        echo "Sheets\\n";
        /* 
        $query = CRON_OPTIMIZED_SHEETS_QUERY;
        $query["filters"]["optimizedSheets"] = (new DateTime('now', new DateTimeZone(TIME_ZONE)))->sub(new DateInterval('P1D'))->format('Y-m-d');
        $productsSheetsOptimized = $sync->post($query, 'products'); 
        $sync->readContents($productsSheetsOptimized);
        */

        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON TITULO. DESCR. CORTA, CATEGORIA, MARCA ACTAULIZADAS O CARGA RECIENTE///////////
    $cron = new CronExpression(CRON_OPTIMIZED);
    $task = function () use ($sync) {
        echo "Optimized\\n";
        /* 
        $query = CRON_OPTIMIZED_QUERY;
        $query["filters"]["optimized"] = (new DateTime('now', new DateTimeZone(TIME_ZONE)))->sub(new DateInterval('P1D'))->format('Y-m-d');
        $productsTitleDescCatManOptimized = $sync->post($query, 'products');
        */

        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ///////////////////////////////////////////////////////////////////////////////////////////////

    ///CATEGORIAS///////////
    $cron = new CronExpression(CRON_CATEGORIES);
    $task = function () use ($sync) {
        echo "Categorias\\n";
        
        $query = CRON_CATEGORIES_QUERY;
        $categories = $sync->post($query, 'categories');
        

        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ///////////////////////////////////////////////////////////////////////////////////////////////

    ///MARCAS///////////
    $cron = new CronExpression(CRON_MANUFACTURER);
    $task = function () use ($sync) {
        echo "Fabricantes\\n";
        
        $query = CRON_MANUFACTURER_QUERY;
        $manufacturer = $sync->post($query, 'manufacturer');
        

        /*
        include "script-integración-plataforma.php";
        */
    };
    $sync->schedule($task, $cron, TIME_ZONE);
    ///////////////////////////////////////////////////////////////////////////////////////////////

} catch (Exception $e) {
    $dateLog = date("Y-m-d");
    $logFile = fopen("logs/log-{$dateLog}.txt", 'a') or die("Error creando archivo");
    fwrite($logFile, "\\n" . date("d/m/Y H:i:s") . " Error App - " . 'Excepción capturada: ',  $e->getMessage(), "\\n") or die("Error escribiendo en el archivo");
    fclose($logFile);
}`]} />
											<DocumentationCode
												label={node}
												header={'Node Conector'}
												title={['Conector']}
												data={[
													`// 0.- cd proyect
// 1.- npm init
// 2.- npm i axios
// 3.- npm install moment --save
// 4.- npm install moment-timezone --save
// 5.- npm i node-cron
// 6.- copy proyect in connect.js
// 7.- node connect.js

var cron = require("node-cron");
const axios = require("axios");
const fs = require("fs");
const path = require("path");
const moment = require("moment-timezone");
const { execSync } = require("child_process");

//field          allowed values
//-----          --------------
//second         0-59
//minute         0-59
//hour           0-23
//day of month   1-31
//month          1-12 (or names, see below)
//day of week    0-7 (0 or 7 is Sunday, or use names)


const ROOT_CONTENTS_IMAGES = 'images';
const ROOT_CONTENTS_SHEETS = 'sheets';
const ROOT_LOGS = 'logs/';

fs.mkdirSync(\`./\${ROOT_CONTENTS_IMAGES}\`, { recursive: true });
fs.mkdirSync(\`./\${ROOT_CONTENTS_SHEETS}\`, { recursive: true });
fs.mkdirSync(\`./\${ROOT_LOGS}\`, { recursive: true });

const USER = 'superj@customer.com'; //<-------change
const PASS = "yIP9fj4I8g"; //<-------change

const TIME_ZONE = 'America/Mexico_City'; //<-------change

const CRON_NEW_PRODUCTS = '0 0 8,15 * * 0-5';
const CRON_PRODUCTS_LOAD_INIT_QUERY = { "report": "json", "filters": { "active": true  } };
const CRON_NEW_PRODUCTS_QUERY = { "report": "json", "filters": { "active": true, "createdAt": '' } };

const CRON_PRICES = '0 0 8,13,19 * * 0-5';
const CRON_PRICES_ACTIVE_QUERY = { "report": "json", "filters": { "active": true } };
const CRON_PRICES_INACTIVE_QUERY = { "report": "json", "filters": { "inactive": true } };

const CRON_OPTIMIZED_IMAGES = '0 0 11,20 * * 0-5';
const CRON_OPTIMIZED_IMAGES_QUERY = { "report": "json", "filters": { "active": true, "image": "images", "optimizedImg": '' } };

const CRON_OPTIMIZED_SHEETS = '0 0 10,19 * * 0-5';
const CRON_OPTIMIZED_SHEETS_QUERY = { "report": "json", "filters": { "active": true, "sheets": "sheets", "optimizedSheets": '' } };

const CRON_OPTIMIZED = '30 10,19 * * 1-5';
const CRON_OPTIMIZED_QUERY = { "report": "json", "filters": { "active": true, "optimized": '' } };

const CRON_CATEGORIES = '0 0 7,20 * * 0-5';
const CRON_CATEGORIES_QUERY = { "ingram": true, "rowsPerPage": 1000, "page": 0 };

const CRON_MANUFACTURER = '0 30 7,20 * * 0-5';
const CRON_MANUFACTURER_QUERY = { "ingram": true, "rowsPerPage": 1000, "page": 0 };

class Sync
{    
    
    constructor(user = undefined, pass = undefined) {
        this.tokenAccount = 0;
        this.token = null;
        this.tokenContents = null;
        this.user = user;
        this.pass = pass;
    }    
    
    async getToken()
    {
        try {

            const res = await axios({ method: 'post', url: 'https://api.shopintegrate.net/signin', data: { 'email': this.user, 'password': this.pass } }).then((res) => res);

            if (res.status == 201 || res.status == 200) {

                this.token = ((!!res.data?.token && res.data?.signup) ? res.data?.token : null);
                this.tokenContents = ((!!res.data?.user?.tokenAppContents && res.data?.signup) ? \`\${res.data?.user?.tokenAppContents}\${res.data?.user?.sandboxPassword?':'+res.data?.user?.sandboxPassword:''}\` : null);

                if (!this.token) {
                    this.logConnect(\`response - getToken - null - \${res.status}\`);
                    execSync("sleep 60");
                    await this.getToken();
                    this.tokenAccount();
                }else{
                    this.logConnect(\`response - getToken - success - \${res.status}\`);
                    this.tokenAccount=0;
                }

            } else {

                if (res.status == 401) {
                    this.logConnect(\`response - getToken - unautorized - \${res.status}\`);
                    execSync("sleep 60");
                    await this.getToken();
                    this.tokenAccount();
                }else{
                    this.token = null;
                }

            }    
            
        } catch (e) {

            this.logConnect(\`Error App - getToken - \${e.message}\`);

        }
    }

    logConnect(message) {
        try {

            const logs = fs.existsSync(\`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\`) ? fs.readFileSync(\`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\`) : '';
            fs.writeFileSync( \`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\`, \`\${logs}\\n\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")} \${message}\` );

        } catch (e) {

            const logs = fs.existsSync(\`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\`)?fs.readFileSync( \`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\` ):'';
            fs.writeFileSync( \`\${ROOT_LOGS}\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")}.txt\`, \`\${logs}\\n\${moment().tz(TIME_ZONE).format("YYYY-MM-DD")} Error App \${e.message}\` );
        }
    }

    tokenAccount()
    {
        try{
            this.tokenAccount = this.tokenAccount++;
            if(this.tokenAccount >= 5){ this.logConnect("max getToken - token"); process.exit(0);  }
        } catch (e) {
            this.logConnect(" Error App - tokenAccount - ".e.message);
        }
    }

    

    async get(url)
    {

        try {

            const res = await axios({ method: 'get', url: url, responseType: 'arraybuffer' });

            if (res.status == 201 || res.status == 200) {                
                return res.data;
            } else {            
                if (res.status == 401) {
                    this.logConnect(\`response - unautorized - \${res.status} - \${url}\`);
                    execSync("sleep 60");
                    await this.getToken();
                    this.tokenAccount();
                    return await this.get(url);
                }
                if (res.status == 429) {
                    this.logConnect(\`response - too many request - \${res.status} - \${url}\`);
                    return null;
                }
            }      

        } catch (e) {

            this.logConnect(\`Error App GET \${url} \${e.message}\`);
            return null;

        }

    }

    async post(CURLOPT_POSTFIELDS = [], ENDPOINT)
    {
        try {

            if (!this.token) await this.getToken();

            this.logConnect(\`request - \${ENDPOINT} \${JSON.stringify(CURLOPT_POSTFIELDS)}\`);           

            const res = await axios({
                method: 'post',
                headers: {
                    Authorization: \`Bearer \${this.token}\`,
                    "Content-Type": "application/json"
                },
                url: \`https://api.shopintegrate.net/api/\${ENDPOINT}\`,
                data: CURLOPT_POSTFIELDS
            });
           
            if (res.status == 201 || res.status == 200) {
                this.logConnect(\`response - POST - success \${res.status} - \${ENDPOINT} - \${res.data.total}\`);
                return res.data;
            } else {            
                if (res.status == 401) {
                    this.logConnect(\`response - POST - unautorized - \${res.status} - \${ENDPOINT} - \${JSON.stringify(CURLOPT_POSTFIELDS)} - \${JSON.stringify(res.data)}\`);
                    execSync("sleep 60");
                    await this.getToken();
                    this.tokenAccount();
                    return await this.post(CURLOPT_POSTFIELDS, ENDPOINT);
                }
                if (res.status == 429) {
                    this.logConnect(\`response - POST - too many request - {res.status} - \${ENDPOINT} - \${JSON.stringify(CURLOPT_POSTFIELDS)} - \${JSON.stringify(res.data)}\`);
                    return null;
                }
            }   

        } catch (e) {

            this.logConnect(\`Error App POST - \${ENDPOINT} - \${e}\`);
            return null;

        }
        
    }

    changeToken(url) {
        try {
            const segmentTokensUrl = url.split('/');
            const segExtUrl = segmentTokensUrl.slice(-1).pop().split('.');
            const extUrl = segExtUrl.slice(-1).pop();
            segmentTokensUrl.splice(segmentTokensUrl.length - 1);
            return \`\${segmentTokensUrl.join("/")}/\${this.tokenContents}.\${extUrl}\`;
        } catch (e) {
            this.logConnect(\`Error App - changeToken - \${e.message}\`);
            return url;
        }
    }

    async readContents(products)
    {
        try {
            for (let product in products["products"]) {

                if (!!products["products"][product]?.products?.images) {
                    const images = products["products"][product]?.products?.images.split(',');
                    for (let image in images){
                        const keyIndex = image-1;
                        const keyImage = ((image == 0) ? '' : \`_\${keyIndex}\`);
                        const resourceImage = await this.get(this.changeToken(images[image]));

                        if (!!resourceImage) { fs.writeFileSync(\`\${ROOT_CONTENTS_IMAGES}/\${products["products"][product].sku}\${keyImage}.jpg\`, resourceImage) }
                        execSync("sleep 1");
                    }
                }

                if (!!products["products"][product]?.products?.sheet) {
                    const resourceSheets = await this.get(this.changeToken(products["products"][product]?.products?.sheet));
                    if(!!resourceSheets){fs.writeFileSync(\`\${ROOT_CONTENTS_SHEETS}/\${products["products"][product].sku}.html\`,resourceSheets)}
                    execSync("sleep 1");
                }

            }

        } catch (e) {
            this.logConnect(\`Error App - readContents - \${e.message}\`);
        }

    }

    set token(token) {this._token = token;}
    get token() {return this._token;}
    set tokenContents(tokenContents) {this._tokenContents = tokenContents;}
    get tokenContents() {return this._tokenContents;}
    set tokenAccount(tokenAccount) {this._tokenAccount = tokenAccount;}
    get tokenAccount() {return this._tokenAccount;}

}

(async () => {
    const sync = new Sync(USER, PASS);

    /*
    /////////////CARGA INICIAL DE PRODUCTOS/////////////////////////////////
    const productsLoadInit = await sync.post(CRON_PRODUCTS_LOAD_INIT_QUERY, 'products');
    await sync.readContents(productsLoadInit);
    console.log("Carga Inicial Products\\n");
    
    //const classFunction= new ScriptClassFunction();    
    ////////////////////////////////////////////////////////////////////////
    */
    
    /////////////////////////////PRODUCTOS NUEVOS///////////////////////////
    try {    

        cron.schedule(CRON_NEW_PRODUCTS, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("new Products\\n");
        
                //let query = CRON_NEW_PRODUCTS_QUERY;
                //query["filters"]["createdAt"] = moment().tz(TIME_ZONE).subtract(1, "days").format("YYYY-MM-DD");
                //const productsNews = await sync.post(query,'products');
                
                //await await sync.readContents(productsNews);
            
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });

    } catch (e) {
        sync.logConnect(\`Error App - Products Nes - \${e.message}\`);
    }
    ////////////////////////////////////////////////////////////////////////

    ////////////////////////////PRECIOS////////////////////////////////////
    try {  
        cron.schedule(CRON_PRICES, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Prices\\n");
            
                //const pricesActive = await sync.post(CRON_PRICES_ACTIVE_QUERY, 'prices');
                //const pricesInActive = await sync.post(CRON_PRICES_INACTIVE_QUERY, 'prices');        
            
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Prices - \${e.message}\`);
    }
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON IMAGENES ACTUALIZADAS O CARGA DE IMAGEN RECIENTE///////////
    try {  
        cron.schedule(CRON_OPTIMIZED_IMAGES, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Images\\n");
        
                //let query = CRON_OPTIMIZED_IMAGES_QUERY;
                //query["filters"]["createdAt"] = moment().tz(TIME_ZONE).subtract(1, "days").format("YYYY-MM-DD");
                //const productsImages = await sync.post(query,'products');
                
                //await sync.readContents(productsImages);
            
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Iamges - \${e.message}\`);
    }
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON FICHAS TÉCNICAS ACTUALIZADAS O CARGA DE FICHA RECIENTE//
    try {  
        cron.schedule(CRON_OPTIMIZED_SHEETS, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Sheets\\n");
        
                //let query = CRON_OPTIMIZED_SHEETS_QUERY;
                //query["filters"]["createdAt"] = moment().tz(TIME_ZONE).subtract(1, "days").format("YYYY-MM-DD");
                //const productsSheets = await sync.post(query,'products');
                
                //await sync.readContents(productsSheets);
            
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Sheets - \${e.message}\`);
    }
    ////////////////////////////////////////////////////////////////////////

    ///PRODUCTOS CON TITULO. DESCR. CORTA, CATEGORIA, MARCA ACTAULIZADAS O CARGA RECIENTE///////////
    try {  
        cron.schedule(CRON_OPTIMIZED, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Titulos etc.\\n");
        
                //let query = CRON_OPTIMIZED_QUERY;
                //query["filters"]["createdAt"] = moment().tz(TIME_ZONE).subtract(1, "days").format("YYYY-MM-DD");
                //const productsTitulos = await sync.post(query,'products');
                
                //await sync.readContents(productsTitulos);
            
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Optimized - \${e.message}\`);
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////

    ///CATEGORIAS//////////////////////////////////////////////////////
    try {  
        cron.schedule(CRON_CATEGORIES, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Categorias\\n");
        
                //let query = CRON_CATEGORIES_QUERY;
                //const productsCategories = await sync.post(query,'categories');
                            
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Categories - \${e.message}\`);
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////

    ///MARCAS///////////
    try {  
        cron.schedule(CRON_MANUFACTURER, () => {
                if(fs.existsSync('exit_app.txt'))process.exit(0);
                
                console.log("Fabricantes\\n");
        
                //let query = CRON_MANUFACTURER_QUERY;
                //const productsManufacturer = await sync.post(query,'manufacturer');                          
                
                //const classFunction= new ScriptClassFunction();
                
        }, { scheduled: true, timezone: TIME_ZONE });
    } catch (e) {
        sync.logConnect(\`Error App - Manufacturer - \${e.message}\`);
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////
    
})()`,
												]}
											/>
											<DocumentationCode label={python} header={'Python Conector'} title={['Conector']} data={[]} />
											<DocumentationCode label={curl} header={'CURL Conector'} title={['conector']} data={[]} />
										</ListItem>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 12} timeout='auto' unmountOnExit></Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(0)}
							/>
							<ListItemText
								onClick={() => handleOpen(0)}
								sx={{ pl: 0, cursor: 'pointer' }}
								primary='POST /signin'
								secondary='Obtención de acceso, para consumir recursos [Duración del token 60 minutos]'
							/>
							<ListItemText
								style={{ overflowX: 'auto', maxWidth: '350px', scrollbarWidth: 'thin', justifyContent: 'flex-end' }}
								sx={{ pl: 0 }}
								primary={null}
								secondary={
									<React.Fragment>
										<ListItem>
											<DocumentationCode
												label={php}
												header={'PHP /signin'}
												title={['signin']}
												data={[
													`
<pre>$curl = curl_init();
curl_setopt_array($curl, array(
CURLOPT_URL => 'https://api.shopintegrate.net/signin',
CURLOPT_RETURNTRANSFER => true,
CURLOPT_ENCODING => '',
CURLOPT_MAXREDIRS => 10,
CURLOPT_TIMEOUT => 0,
CURLOPT_FOLLOWLOCATION => true,
CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
CURLOPT_CUSTOMREQUEST => 'POST',
CURLOPT_POSTFIELDS =>'{"email":"****@*******.com","password":********"}',
CURLOPT_HTTPHEADER => array(
'Content-Type: application/json'
),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;</pre>`,
												]}
											/>
											<DocumentationCode
												label={node}
												header={'Node /signin'}
												title={['signin']}
												data={[
													`<pre>const axios = require('axios');
let data = JSON.stringify({
"email": "***@*******.com",
"password": "********"
});

let config = {
method: 'post',
maxBodyLength: Infinity,
url: 'https://api.shopintegrate.net/signin',
headers: { 
'Content-Type': 'application/json'
},
data : data
};

axios.request(config)
.then((response) => {
console.log(JSON.stringify(response.data));
})
.catch((error) => {
console.log(error);
});</pre>`,
												]}
											/>
											<DocumentationCode
												label={python}
												header={'Python /signin'}
												title={['signin']}
												data={[
													`<pre>import requests
import json

url = "https://api.shopintegrate.net/signin"

payload = json.dumps({
"email": "*****@******.com",
"password": "*******"
})
headers = {
'Content-Type': 'application/json'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)</pre>`,
												]}
											/>
											<DocumentationCode
												label={curl}
												header={'CURL /signin'}
												title={['signin']}
												data={[
													`<pre>curl --location 'https://api.shopintegrate.net/signin' \
--header 'Content-Type: application/json' \
--data-raw '{"email":"****@*******.com","password":"*********"}'</pre>`,
												]}
											/>
										</ListItem>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 0} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /signin - Body Raw (Json)'}
									data={'{"email":"String()","password":"String()"}'}
									diccionario={<DiccionarioSingIn />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /signin - Body Raw (Json)'}
									data={'{"email":"*********","password":"*******"}'}
									diccionario={<DiccionarioSingIn />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /signin - Body Raw (Json)'}
									data={
										'{"user":{"id":"Int()","email":"String()","countryCode":"String()","tokenAppContents":"String()","segmentarIngram":"Boolean()","role":"String()"},"token":"String()","signup":"Boolean()"}'
									}
									diccionario={<DiccionarioSingIn />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /signin - Response Json'}
									data={
										'{"user":{"id":3,"email":"*****","countryCode":"***","tokenAppContents":"******","segmentarIngram":true,"role":"CUSTOMER"},"token":"******","signup":true}'
									}
									diccionario={<DiccionarioSingIn />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 1 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(1)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(1)}
								primary='GET /api/image'
								secondary='Obtención de imagen, tokenAppContents'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 1} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Query Param (Url Segment)'} />
								<ListItemText
									sx={{ pl: 4 }}
									primary='Segment'
									secondary={
										<React.Fragment>
											<div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
												{'/api/image/:sku/:index/:token/:width?'}
											</div>
										</React.Fragment>
									}
								/>
								<DocumentationJson
									label={'Tipos'}
									title={'GET /api/image/:sku/:index/:token/:width? - Query Param (Url Segment)'}
									data={
										'{"sku": "String() Mandatory", "index": "Int() Mandatory 0-9", "token": "String() tokenAppContents Mandatory", "width": "Number() Optional"}'
									}
									diccionario={<DiccionarioImages />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'GET /api/image/:sku/:index/:token/:width?'}
									data={'{"sku": "WESDE34", "index": 0, "token": "ssw22juw6dhehe", "width": 1000}'}
									diccionario={<DiccionarioImages />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Image (Binary)' />
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 2 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(2)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(2)}
								primary='GET /api/sheets'
								secondary='Obtención de ficha técnica, tokenAppContents'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 2} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Query Param (Url Segment)'} />
								<ListItemText
									sx={{ pl: 4 }}
									primary='Segment'
									secondary={
										<React.Fragment>
											<div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>{'/api/sheets/:sku/:token'}</div>
										</React.Fragment>
									}
								/>
								<DocumentationJson
									label={'Tipos'}
									title={'GET /api/sheets/:sku/:token - Query Param (Url Segment)'}
									data={'{"sku": "String() Mandatory", "token": "String() tokenAppContents Mandatory"}'}
									diccionario={<DiccionarioSheets />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'GET /api/sheets/:sku/:token'}
									data={'{"sku": "WESDE34", "token": "ssw22juw6dhehe"}'}
									diccionario={<DiccionarioSheets />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Text (Table HTML)' />
							</ListItem>
						</Collapse>

						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 3 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(3)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(3)}
								primary='GET /api/sheets/json'
								secondary='Obtención de ficha técnica formato Json, tokenAppContents'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 3} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Query Param (Url Segment)'} />
								<ListItemText
									sx={{ pl: 4 }}
									primary='Segment'
									secondary={
										<React.Fragment>
											<div style={{ overflowX: 'scroll', scrollbarWidth: 'none' }}>
												{'/api/sheets/json/:sku/:token'}
											</div>
										</React.Fragment>
									}
								/>
								<DocumentationJson
									label={'Tipos'}
									title={'GET /api/sheets/json/:sku/:token - Query Param (Url Segment)'}
									data={'{"sku": "String() Mandatory", "token": "String() tokenAppContents Mandatory"}'}
									diccionario={<DiccionarioSheetsJson />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'GET /api/sheets/json/:sku/:token'}
									data={'{"sku": "WESDE34", "token": "ssw22juw6dhehe"}'}
									diccionario={<DiccionarioSheetsJson />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json (Array Object)' />
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 4 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(4)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(4)}
								primary='POST /api/products'
								secondary='Obtención de productos, Si "report" existe, se omite el segmentado. Max respuesta 300 items por solicitud para segmentado'
							/>
							<ListItemText
								style={{ overflowX: 'auto', maxWidth: '350px', scrollbarWidth: 'thin', justifyContent: 'flex-end' }}
								sx={{ pl: 0 }}
								primary={null}
								secondary={
									<React.Fragment>
										<ListItem>
											<DocumentationCode
												label={php}
												header={'PHP /api/products'}
												title={[
													'Active News',
													'Active Empty Images',
													'Active Images',
													'Active Empty Sheet',
													'Active Sheet',
													'Optimized Title & Des. Short',
												]}
												data={[
													`
<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": "2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
													`<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
	"image":"notimages",
	"optimizedImg":"2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
													`<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"image":"images",
	"optimizedImg":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
													`<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"notsheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
													`<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"sheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
													`<pre>
$curl = curl_init();
curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"optimized":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));
$response = curl_exec($curl);
curl_close($curl);
echo $response;
</pre>`,
												]}
											/>
											<DocumentationCode
												label={node}
												header={'Node /api/products'}
												title={[
													'Active News',
													'Active Empty Images',
													'Active Images',
													'Active Empty Sheet',
													'Active Sheet',
													'Optimized Title & Des. Short',
												]}
												data={[
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": "2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
	"image":"notimages",
	"optimizedImg":"2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"image":"images",
	"optimizedImg":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"notsheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"sheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"optimized":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});
let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};
axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
												]}
											/>
											<DocumentationCode
												label={python}
												header={'Python /api/products'}
												title={[
													'Active News',
													'Active Empty Images',
													'Active Images',
													'Active Empty Sheet',
													'Active Sheet',
													'Optimized Title & Des. Short',
												]}
												data={[
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": "2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
	"image":"notimages",
	"optimizedImg":"2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"image":"images",
	"optimizedImg":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"notsheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"sheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
													`<pre>import requests
import json
url = "https://api.shopintegrate.net/api/products"
payload = json.dumps({
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"optimized":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}
response = requests.request("POST", url, headers=headers, data=payload)
print(response.text)
</pre>`,
												]}
											/>
											<DocumentationCode
												label={curl}
												header={'CURL /api/products'}
												title={[
													'Active News',
													'Active Empty Images',
													'Active Images',
													'Active Empty Sheet',
													'Active Sheet',
													'Optimized Title & Des. Short',
												]}
												data={[
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": "2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
	"image":"notimages",
	"optimizedImg":"2024-03-10",
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"image":"images",
	"optimizedImg":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"notsheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
    "price": null,
	"sheets":"sheets",
	"optimizedSheets":"2024-03-10",
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
    "price": null,
    "inventory": null,
	"optimized":"2024-03-10",
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
												]}
											/>
										</ListItem>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 4} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/products - Body Raw (Json)'}
									data={
										'{"report":"String() json or excel (optional)","filters": {"search": "String() wildcard (separated by commas)", "createdAt": "Date() Products New CurrentDate-2 Days", "optimizedImg": "Date()", "optimizedSheets": "Date()", "optimized": "Date()", "updatedAt": "Date()", "price": "Number()", "inventory": "Int()", "image":"String()","sheet":"String()","active": "Bool()", "inactive": "Boolean()", "categories": ["String()"], "manufacturer": ["String()"]},"rowsPerPage": "Int()", "page": "Int()"}'
									}
									diccionario={<DiccionarioProducts />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/products - Body Raw (Json)'}
									data={
										'{"report":"json/excel","filters": {"search": "Text to Find wildcard (sku,vendornumber,sku,vendornumber)", "createdAt": "2023-12-27T12:21:06.941Z Products New CurrentDate-2 Days", "optimizedImg": "2023-12-27T12:21:06.941Z Image Optimized CurrentDate-2 Days", "optimizedSheets": "2023-12-27T12:21:06.941Z Sheet Optimized CurrentDate-2 Days", "optimized": "2023-12-27T12:21:06.941Z Title, Descr. short CurrentDate-2 Days", "updatedAt": "2023-12-27T12:21:06.941Z", "price": 124563.25, "inventory": 10, "image":"notimages","sheet":"notsheets","active": true, "inactive": false, "categories": ["Almacenamiento"], "manufacturer": ["Apple"]},"rowsPerPage": 10, "page": 0}'
									}
									diccionario={<DiccionarioProducts />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json or Excel.xlsx' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/products - Json'}
									data={
										'{"totalPages":"Int()","total":"Int()","page":"Int()","products":[{"active":"String()","sku":"String()","price":"Float()","inventory":"Int()","priceApply":"Float()","currencyCode":"String()","updatedAt":"Date()","products":{"description":"String()","productDetailDescription":"String()","sheet": "String()","sheetJson": "String()","listPrimaryAttribute":"Array [String()]","technicalSpecifications":"Array[Json]","vendornumber": "String()", "upcnumber": "String()", "images": "String()", "categoriesIdIngram": "String()", "manufacturerIdIngram": "String()", "manufacturerIngram": { "id": "Int()","name": "String()"}, "categoriesIngram": { "id": "Int()", "nameTemp": "String()", "parent": { "id": "Int()", "name": "String()" } }}}]}'
									}
									diccionario={<DiccionarioProducts />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/products - Json'}
									data={
										'{"totalPages":7,"total":63,"page":1,"products":[{"active":"active","sku":"6048159","price":10066396,"inventory":4,"priceApply":11375027.48,"currencyCode":"MXP","updatedAt":"2023-12-27T12:21:06.941Z","products":{"description":"MBP 16 SB/12C/18C GPU/18G/512G-SPA","productDetailDescription":" MacBook Pro de 16 pulgadas: Chip M3 Pro de Apple con CPU de 12 núcleos y GPU de 18 núcleos, memoria unificada 18 GB, almacenamiento de 512 GB SSD - space black -MBP 16 SB/12C/18C GPU/18G/512G-SPA","sheet": "https://api.shopintegrate.net/api/sheets/3959824/xxxxxxxx.html","sheetJson": "https://api.shopintegrate.net/api/sheets/json/3959824/xxxxxxxx.json","listPrimaryAttribute":"Array [String()]","technicalSpecifications":[{"headerName":"Basic","attributeName":"Tipo de Producto","attributeDisplay":"Basic|Tipo de Producto|Portátil","attributeValue":"Portátil"}],"vendornumber": "MRW13E/A", "upcnumber": "014227888", "images": "https://api.shopintegrate.net/api/image/6067054/0/xxxxxxxxxx.jpg,https://api.shopintegrate.net/api/image/6067054/1/xxxxxxxx.jpg", "categoriesIdIngram": "Computadores->Portátiles / Laptops", "manufacturerIdIngram": "Apple", "manufacturerIngram": { "id": 230,"name": "Apple"}, "categoriesIngram": { "id": 6895, "nameTemp": "Portátiles / Laptops", "parent": { "id": 371, "name": "Computadores" } }}}]}'
									}
									diccionario={<DiccionarioProducts />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 5 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(5)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(5)}
								primary='POST /api/prices'
								secondary='Obtención de precios, Si "report" existe, se omite el segmentado. Max respuesta 300 items por solicitud para segmentado'
							/>
							<ListItemText
								style={{ overflowX: 'auto', maxWidth: '350px', scrollbarWidth: 'thin', justifyContent: 'flex-end' }}
								sx={{ pl: 0 }}
								primary={null}
								secondary={
									<React.Fragment>
										<ListItem>
											<DocumentationCode
												label={php}
												header={['PHP /api/prices']}
												title={['Report Json']}
												data={[
													`
<pre>
$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'https://api.shopintegrate.net/api/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
  "report": "json",
  "filters": {
    "search": null,
    "createdAt": "2024-03-10",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": ["Almacenamiento"],
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
}',
  CURLOPT_HTTPHEADER => array(
    'Content-Type: application/json',
    'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
</pre>`,
												]}
											/>
											<DocumentationCode
												label={node}
												header={['Node /api/prices']}
												title={['Report Json']}
												data={[
													`<pre>const axios = require('axios');
let data = JSON.stringify({
  "report": "json",
  "filters": {
    "search": "Laptop",
    "createdAt": "2024-03-01",
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": null
  },
  "rowsPerPage": 10,
  "page": 0
});

let config = {
  method: 'post',
  maxBodyLength: Infinity,
  url: 'https://api.shopintegrate.net/api/products',
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
  },
  data : data
};

axios.request(config)
.then((response) => {
  console.log(JSON.stringify(response.data));
})
.catch((error) => {
  console.log(error);
});</pre>`,
												]}
											/>
											<DocumentationCode
												label={python}
												header={['Python /api/prices']}
												title={['Report Json']}
												data={[
													`<pre>import requests
import json

url = "https://api.shopintegrate.net/api/products"

payload = json.dumps({
  "report": "json",
  "filters": {
    "search": "Laptop",
    "createdAt": None,
    "updatedAt": None,
    "price": None,
    "inventory": None,
    "active": True,
    "inactive": None,
    "categories": None,
    "manufacturer": [
      "Asus"
    ]
  },
  "rowsPerPage": 10,
  "page": 0
})
headers = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)
</pre>`,
												]}
											/>
											<DocumentationCode
												label={curl}
												header={['CURL /api/prices']}
												title={['Report Json']}
												data={[
													`<pre>curl --location 'https://api.shopintegrate.net/api/products' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjksImVtYWlsIjoiYmRpbXhAY3VzdG9tZXIuY29tIiwiY291bnRyeUNvZGUiOiJNWCIsInN1cGVyIjpmYWxzZSwic2VnbWVudGFySW5ncmFtIjp0cnVlLCJ0b2tlbkFwcENvbnRlbnRzIjoiQjZkVEJzN2ZNMiIsInJvbGUiOiJDVVNUT01FUiIsImlhdCI6MTcxMDMwODQ3OCwiZXhwIjoxNzEwMzEyMDc4fQ.Qo4XR0Q9lH-TVA_5JyEkSkrnf-y_bUQMIoKaQ-jaUQQ' \
--data '{
  "report": "excel",
  "filters": {
    "search": "Laptop",
    "createdAt": null,
    "updatedAt": null,
    "price": null,
    "inventory": null,
    "active": true,
    "inactive": null,
    "categories": null,
    "manufacturer": ["Asus"]
  },
  "rowsPerPage": 10,
  "page": 0
}'</pre>`,
												]}
											/>
										</ListItem>
									</React.Fragment>
								}
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 5} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/prices - Body Raw (Json)'}
									data={
										'{"currentIngram":"Boolean() Get Real Time Ingram Max 10 skus in search filters separate by comma","report":"String() json or excel <optional>","filters": {"search": "String()", "createdAt": "Date()", "updatedAt": "Date()", "price": "Number()", "inventory": "Int()", "active": "Bool()", "inactive": "Boolean()", "categories": ["String()"], "manufacturer": ["String()"]},"rowsPerPage": "Int()", "page": "Int()"}'
									}
									diccionario={<DiccionarioPrecios />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/prices - Body Raw (Json)'}
									data={
										'{"currentIngram":true,"report":"json/excel","filters": {"search": "Text to Find", "createdAt": "2023-12-27T12:21:06.941Z", "updatedAt": "2023-12-27T12:21:06.941Z", "price": 124563.25, "inventory": 10, "active": true, "inactive": false, "categories": ["Almacenamiento"], "manufacturer": ["Apple"]},"rowsPerPage": 10, "page": 0}'
									}
									diccionario={<DiccionarioPrecios />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json or Excel.xlsx' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/prices - Json/Excel'}
									data={
										'{"totalPages":"Int()","total":"Int()","page":"Int()","products":[{"ingram": {"sku": "String()","price": "Int()", "currencyCode":"String()","inventory": "Int()", "branch": [{"quantityAvailable": "Int()","warehouseId": "String()","location": "String()"}],"priceSpecial": [{"specialPriceQty": "Int()", "specialPriceEnd": "String()", "specialPriceEffective": "String()", "specialPriceDiscount": "Int()", "specialPriceDaysValidity": "Float()"}]},"status":"Boolean()","price":"Number()","priceApply":"Number()","currencyCode":"String()","priceSpecial":{"specialPriceQty": "Int()", "specialPriceEnd": "String()", "specialPriceEffective": "String()", "specialPriceDiscount": "Int()", "specialPriceDaysValidity": "Float()"},"inventory":"Int()","sku":"String()","updatedAt":"Date()","vendorNumber":"String()","daysOutStock":"Int()","br10":"Int()","brs": [{"id": "String()","name": "String()", "inventory": "Int()"}]}]}'
									}
									diccionario={<DiccionarioPrecios />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/prices -Json/Excel'}
									data={
										'{"totalPages":7,"total":67,"page":1,"products":[{"ingram": {"sku": "91401BL","price": 55739.49, "currencyCode":"MXP","inventory": 12, "branch": [{"quantityAvailable": 5,"warehouseId": "10","location": "Mexico DF"}],"priceSpecial": [{"specialPriceQty": 8, "specialPriceEnd": "2024-03-31", "specialPriceEffective": "2024-02-24", "specialPriceDiscount": 14338, "specialPriceDaysValidity": 22.69362634259259}]},"status":"active","price":10066396,"priceApply":11375027.48,"currencyCode":"MXP","priceSpecial": {"specialPriceQty": 8, "specialPriceEnd": "2024-03-31", "specialPriceEffective": "2024-02-24", "specialPriceDiscount": 14338, "specialPriceDaysValidity": 22.69362634259259},"inventory":2,"sku":"6048159","updatedAt":"2023-12-28T00:21:27.488Z","vendorNumber":"MRW13E/A","daysOutStock":-2,"br10": 229,"br55": 4,"br60": 2,"br80": 23,"brs": [{"id": "10","name": "CDMX", "inventory": 5}]}]}'
									}
									diccionario={<DiccionarioPrecios />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 6 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(6)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(6)}
								primary='POST /api/manufacturer'
								secondary='Obtención de fabricantes, Max respuesta 300 items por solicitud'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 6} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/manufacturer - Body Raw (Json)'}
									data={'{"filters": { "search": "String()" },"rowsPerPage": "Int()", "page": "Int()"}'}
									diccionario={<DiccionarioFabricantes />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/manufacturer - Body Raw (Json)'}
									data={'{"filters": { "search": "Viewsonic" },"rowsPerPage": 10, "page": 0}'}
									diccionario={<DiccionarioFabricantes />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/manufacturer - Json'}
									data={'{"total": "Int()","page": "Int()", "manufacturer": [{"id": "Int()", "name": "String()"}]}'}
									diccionario={<DiccionarioFabricantes />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/manufacturer -Json'}
									data={'{"total": 1,"page": 1, "manufacturer": [{"id": 382, "name": "Viewsonic"}]}'}
									diccionario={<DiccionarioFabricantes />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 7 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(7)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(7)}
								primary='POST /api/categories'
								secondary='Obtención de categorias, Max respuesta 300 items por solicitud'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 7} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/categories - Body Raw (Json)'}
									data={
										'{"filters": { "search": "String()", "categories": ["String()" ] }, "rowsPerPage": "Int()", "page": "Int()", "ingram": "Boolean()"}'
									}
									diccionario={<DiccionarioCategorias />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/categories - Body Raw (Json)'}
									data={
										'{"filters": { "search": "", "categories": ["Almacenamiento" ] }, "rowsPerPage": 10, "page": 0, "ingram": true}'
									}
									diccionario={<DiccionarioCategorias />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/categories - Json'}
									data={
										'{"total":"Int()","page":"Int()","categories":[{"id":"Int()","name":"String()","nameTemp":"String()","country":["String()"],"parentName":"String()","categoriesIngramAttributeProducts":"Array[String()]","listPrimaryAttribute":"Array[String()]","status":"Boolean()","children":[],"parent":{"id":"Int()","name":"String()","nameTemp":"String()","country":["String()"],"parentName":"String()","status":"Boolean()"},"categories":[{"categoriesId":"String()","categoriesIngramId":"String()","assignedAt":"Date()","customerId":"Int()","status":"Boolean()"},{"categoriesId":"Boolean()","categoriesIngramId":"String()","assignedAt":"Date()","customerId":"Int()","status":"Boolean()"},{"categoriesId":"String()","categoriesIngramId":"String()","assignedAt":"Date()","customerId":"Int()","status":"Boolean()"}]}]}'
									}
									diccionario={<DiccionarioCategorias />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/categories -Json'}
									data={
										'{"total":9,"page":1,"categories":[{"id":477,"name":"Almacenamiento->Sistemas e Infraestructura SAN","nameTemp":"Sistemas e Infraestructura SAN","country":["CO","CO"],"parentName":"Almacenamiento","categoriesIngramAttributeProducts":"Array[String()]","listPrimaryAttribute":"Array[String()]","status":true,"children":[],"parent":{"id":370,"name":"Almacenamiento","nameTemp":null,"country":["CO","MX"],"parentName":null,"status":true},"categories":[{"categoriesId":"Almacenamiento->Sistemas e Infraestructura SAN","categoriesIngramId":"Almacenamiento->Sistemas e Infraestructura SAN","assignedAt":"2023-10-25T12:18:38.874Z","customerId":1,"status":true},{"categoriesId":"Almacenamiento->Sistemas e Infraestructura SAN","categoriesIngramId":"Almacenamiento->Sistemas e Infraestructura SAN","assignedAt":"2023-10-25T12:18:38.874Z","customerId":24,"status":true},{"categoriesId":"Almacenamiento->Sistemas e Infraestructura SAN","categoriesIngramId":"Almacenamiento->Sistemas e Infraestructura SAN","assignedAt":"2023-10-25T12:18:38.874Z","customerId":28,"status":true}]}]}'
									}
									diccionario={<DiccionarioCategorias />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 8 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(8)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(8)}
								primary='GET /api/brslocations'
								secondary='Obtención de Localidades para almacenes'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 8} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json (Array [Object])' />
								<DocumentationJson
									label={'Tipos'}
									title={'GET /api/brslocations - Json'}
									data={
										'{"total": "Int()","page": "Int()", "brsLocations": [{"brId": "String()", "name": "String()", "alias": "String()"}]}'
									}
									diccionario={<DiccionarioBrsLocations />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'GET /api/brslocations - Json'}
									data={
										'{"total": 1,"page": 1, "brsLocations": [{"brId": "80", "name": "Guadalajara", "alias": "guadalajara"}]}'
									}
									diccionario={<DiccionarioBrsLocations />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 9 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(9)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(9)}
								primary='PUT /api/order'
								secondary='Colocación de orden en ingram'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 9} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'PUT /api/order - Body Raw (Json)'}
									data={
										'{"orderNumberClient": "String()","company":"String()","note":"String()","nameClient": "String()","street": "String()","colony": "String()","phoneNumber": "String()","city": "String()","state": "String()","cp": "String()","email": "String()","branch": "String()","products": [{"sku": "String()","qty": "Number()"}],"carrier": "String()"}'
									}
									diccionario={<DiccionarioOrders />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'PUT /api/order - Body Raw (Json)'}
									data={
										'{"orderNumberClient": "2000014","company":"String()","note":"String()","nameClient": "BDI","street": "Santa Clan 5000,interior 3355","colony": "Antonio de Guevara","phoneNumber": "9005147823","city": "San Luis","state": "SL","cp": "25689","email": "ingram@ingram.com","branch": "10","products": [{"sku": "A5200TG","qty": 1}],"carrier": "D1"}'
									}
									diccionario={<DiccionarioOrders />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Tipos'}
									title={'PUT /api/order - Json'}
									data={'{"orderNumberClient":"String()","orderNumberIngram":"String()","message":"String()"}'}
									diccionario={<DiccionarioOrders />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'PUT /api/order -Json'}
									data={
										'{"orderNumberClient":"23403939","orderNumberIngram":"10-12233-11","message":"{Api Rest BDI} Order Error Ingram"}'
									}
									diccionario={<DiccionarioOrders />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 10 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(10)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(10)}
								primary='POST /api/shipping'
								secondary='Obtención Costo de Envio'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 10} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/shipping - Body Raw (Json)'}
									data={
										'{"street": "String()","colony": "String()","phoneNumber": "String()","city": "String()","state": "String()","cp": "String()","products": [{"sku": "String()","qty": "Number()","branch": "String()","carrier": "String()"}]}'
									}
									diccionario={<DiccionarioShipping />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/shipping - Body Raw (Json)'}
									data={
										'{"street": "Santa Clan 5000,interior 3355", "colony": "Antonio de Guevara", "phoneNumber": "9005147823", "city": "San Luis", "state": "SL", "cp": "25689", "products": [{ "sku": "0B11059", "qty": 1, "branch":"10", "carrier":"D1"}]}'
									}
									diccionario={<DiccionarioShipping />}
								/>
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Tipos'}
									title={'POST /api/shipping - Json'}
									data={
										'{"freightEstimate":[{"branch": "String()", "branchName": "String()", "cost": "Float()", "label": "String()", "totalWeight": "Float()", "transitDays": "Float()", "carrierList": "Array List []"}],"currencyCode":"String()","totalFreightAmount":"Float()","totalTaxAmount":"Float()","totalFees":"Float()","totalNetAmount":"Float()","grossAmount":"Float()"}'
									}
									diccionario={<DiccionarioShipping />}
								/>
								<DocumentationJson
									label={'Ver'}
									title={'POST /api/shipping -Json'}
									data={
										'{"freightEstimate":[{"branch": "10", "branchName": "CD. México", "cost": 128, "label": "Ingram | CONSOLIDADO | CD. México", "totalWeight": 10, "transitDays": 0, "carrierList": []}],"currencyCode":"MX","totalFreightAmount":1200,"totalTaxAmount":200,"totalFees":0,"totalNetAmount":300,"grossAmount":8000}'
									}
									diccionario={<DiccionarioShipping />}
								/>
							</ListItem>
						</Collapse>
						<Divider />
						<ListItem>
							<ListItemText
								sx={{ cursor: 'pointer', width: '100%', maxWidth: 30, bgcolor: 'background.paper' }}
								primary={isOpenCollapse === 11 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
								onClick={() => handleOpen(11)}
							/>
							<ListItemText
								sx={{ cursor: 'pointer' }}
								onClick={() => handleOpen(11)}
								primary='GET /api/countrycode'
								secondary='Obtención Codigos de Ciudad'
							/>
						</ListItem>
						<Collapse in={isOpenCollapse === 11} timeout='auto' unmountOnExit>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Request' secondary={'Body Raw (Json)'} />
								<ListItemText sx={{ pl: 4 }} primary='Authorization' secondary={'Bearer Token'} />
							</ListItem>
							<ListItem>
								<ListItemText sx={{ pl: 4 }} primary='Response' secondary='Json' />
								<DocumentationJson
									label={'Ver'}
									title={'GET /api/countrycode - Json'}
									data={
										'{"AG": ["Aguascalientes"],"BC": ["Baja California Norte"],"BS": ["Baja California Sur"],"CA": ["Campeche"],"CS": ["Chiapas"],"CH": ["Chihuahua"],"CM": ["Ciudad de México"],"CO": ["Coahuila"],"CL": ["Colima"],"DU": ["Durango"],"GU": ["Guanajuato"],"GR": ["Guerrero"],"HI": ["Hidalgo"],"JA": ["Jalisco"],"ME": ["México"],"MI": ["Michoacán"],"MO": ["Morelos"],"NA": ["Nayarit"],"NL": ["Nuevo Leon"],"OA": ["Oaxaca"],"PU": ["Puebla"],"QU": ["Querétaro"],"RO": ["Quintana Roo"],"SL": ["San Luis Potosi"],"SI": ["Sinaloa"],"SO": ["Sonora"],"TB": ["Tabasco"],"TM": ["Tamaulipas"],"TL": ["Tlaxcala"],"VE": ["Veracruz"],"YU": ["Yucatán"],"ZA": ["Zacatecas"]}'
									}
									diccionario={<DiccionarioCountryCode />}
								/>
							</ListItem>
						</Collapse>
					</List>
				</Dialog>
			</>
		</React.Fragment>
	);
}