import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MenuIcon from '@mui/icons-material/Menu';
import DocumentationJson from './ApiDocumentationJson'
import DocumentationCode from './ApiDocumentationCode';

import php from '../../../assets/images/php.png';
import node from '../../../assets/images/node.png';
import python from '../../../assets/images/python.png';
import curl from '../../../assets/images/curl.png';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import NavigationIcon from '@mui/icons-material/Navigation';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import Fab from '@mui/material/Fab';

import { undefined, literal, object, string, TypeOf, custom, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import { putSupport, putSupportThunk } from '../../../store/user/action';



interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;	
}

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children?: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface ItemProps {
	getValues: Function;
	
}

interface ApiItemProps {	
	props?: ItemProps;	
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

let links = ["./txtCode/phpConnect.txt","./txtCode/nodeConnect.txt"]

export default function ApiDocumentation({ props }: ApiItemProps) {
	const [open, setOpen] = React.useState(false);
	const dispatch = useDispatch<any>();
	const userSchema = object({
		//countryCode: string().optional().nullable(),
		//emailSB: string().min(1, 'Email es requerido').email('Email es invalido')
		emailSB: string()
			.regex(
				new RegExp(/^([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6})*([,;][\s]*([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}))*$/i),
				'Email es invalido'
			)
			.min(1, 'Email es requerido'),
		subject: string(),
	});

	type IUser = TypeOf<typeof userSchema>;

	const defaultValues: IUser = {
		//countryCode:'',
		emailSB: '',
		subject:''
	};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IUser>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleClickOpen = () => {
		setOpen(true);
		props?.getValues(true);
	};

	const handleClose = () => {
		setOpen(false);
		props?.getValues(false);
	};

	const [value, setValue] = React.useState(0);

	

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};	

	const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);

	const handleOpen = (clickedIndex:any) => {
		if (isOpenCollapse === clickedIndex) {
			setIsOpenCollapse(null);
		} else {
			setIsOpenCollapse(clickedIndex);
			setOpenToolTip(true);
		}
	};

	const [openToolTip, setOpenToolTip] = React.useState(false);

	const onSubmitHandler: SubmitHandler<IUser> = (values: IUser) => {		
		dispatch(putSupport(values));
		handleClose();
	};

	return (
		<React.Fragment>
			<>
				<Fab color='secondary' variant='extended' onClick={handleClickOpen}>
					<SupportAgentIcon sx={{ mr: 1 }} />
					Soporte
				</Fab>
				<Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
					{/* 
					<Box sx={{ flexGrow: 1 }}>
							<AppBar sx={{ position: 'static' }}>
								<Toolbar>
									<IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
										<CloseIcon />
									</IconButton>
									
									<Button color='inherit' onClick={handleClose}>
										Web Services
									</Button>
									<Button color='inherit'>Diccionario</Button>
								</Toolbar>
							</AppBar>
						</Box> 
					*/}
					<Box sx={{ flexGrow: 1 }}>
						<AppBar position='static'>
							<Toolbar variant='dense'>
								<IconButton
									size='large'
									edge='start'
									color='inherit'
									onClick={handleClose}
									aria-label='menu'
									sx={{ mr: 2 }}
								>
									<CloseIcon />
								</IconButton>
								<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
									Soporte
								</Typography>
							</Toolbar>
						</AppBar>
					</Box>
					<FormProvider {...methods}>
						<Box
							display='flex'
							flexDirection='column'
							component='form'
							noValidate
							autoComplete='off'
							sx={{ padding: { sm: '1rem' } }}
							onSubmit={methods.handleSubmit(onSubmitHandler)}
						>
							<DialogContent>
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='emailSB'
										label={`Email's separados por coma`}
										multiline
										fullWidth
										variant='standard'
										{...methods.register('emailSB')}
										error={methods.formState.errors.emailSB ? true : false}
										helperText={methods.formState.errors.emailSB?.message}
									/>
								</FormControl>
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='subject'
										label={`Asunto`}
										multiline
										fullWidth
										variant='standard'
										{...methods.register('subject')}
										error={methods.formState.errors.subject ? true : false}
										helperText={methods.formState.errors.subject?.message}
									/>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button type='submit'>Enviar</Button>
							</DialogActions>
						</Box>
					</FormProvider>
				</Dialog>
			</>
		</React.Fragment>
	);
}