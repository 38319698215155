export interface OrdersSearch {
	search: string;
	rowsPerPage: number;
	page: number;
}

export enum OrdersActionTypes {
	GET_ORDERS = '@@orders/GET_ORDERS',
	GET_ORDERS_FAILURE = '@@orders/GET_ORDERS_FAILURE',
	POST_CRUD_ORDERS = '@@orders/POST_CRUD_ORDERS',
	POST_CRUD_ORDERS_FAILURE = '@@orders/POST_CRUD_ORDERS_FAILURE',
}

export interface errors {
	code?: number;
	message?: string;
}

export interface OrdersState {
	length: number;
	loading: boolean;
	data?: Orders[];
	datasearch?: Orders[];
	dataresponse?: any;
	errors?: errors;
}

export interface Orders {
	id?: number;
	name: string;
	tags: string;
}