import '@wangeditor/editor/dist/css/style.css'; // import css

import React, { useState, useEffect } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor';

import Button from '@mui/material/Button';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';



interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props: ItemProps;
	bdi?: string;
}

interface searchProps {
	value?: any;
	id?: any;
}

function RichText({ data, bdi, name, props }: CrudItemProps) {
	// editor instance
	const [editor, setEditor] = useState<IDomEditor | null>(null); // TS syntax
	// const [editor, setEditor] = useState(null)                  // JS syntax

	// editor content
	const [html, setHtml] = useState(bdi);

	const [numTables, setNumTables] = useState(1);
	const [numTablesDom, setNumTablesDom] = useState(0);

	const toolbarConfig: Partial<IToolbarConfig> = {}; // TS syntax
	// const toolbarConfig = { }                        // JS syntax

	/* toolbarConfig.toolbarKeys = [
		// menu key
		'code'
	]; */

	const editorConfig: Partial<IEditorConfig> = {
		// TS syntax
		// const editorConfig = {                       // JS syntax
		placeholder: 'Plain Text Conevrt HTML',
		/* MENU_CONF:{codeSelectLang:{
			codeLangs: [
				{ text: 'CSS', value: 'css' },
				{ text: 'HTML', value: 'html' },
				{ text: 'XML', value: 'xml' },
				// others...
			],
		}}, */
	};

	const handleReset = () => {
		setHtml('');
		setNumTablesDom(numTables);
	};

	const handleAddHTML = (html: any) => {
		var fixedstring;

		try {
			// If the string is UTF-8, this will work and not throw an error.
			fixedstring = decodeURIComponent(escape(html));
		} catch (e) {
			// If it isn't, an error will be thrown, and we can assume that we have an ISO string.
			fixedstring = html;
		}
		setHtml(
			fixedstring
				.replace(/<span\/?[^>]+(>|$)/gi, '')
				.replace(/<font\/?[^>]+(>|$)/gi, '')
				.replace(/<body\/?[^>]+(>|$)/gi, '')
				.replace(/<html\/?[^>]+(>|$)/gi, '')
		);
	};

	// Simulate ajax async set html
	useEffect(() => {
		if (numTablesDom > 1) {
			const htmlNew: string =
				'<table style="width: auto"><tbody><tr><th colspan="2" rowspan="1" width="auto"></th></tr><tr><td></td><td></td></tr><tr><td></td><td></td></tr><tr><td></td><td></td></tr><tr><td></td><td></td></tr><tr><td></td><td></td></tr></tbody></table>';
			setTimeout(() => {
				setHtml(htmlNew.repeat(numTablesDom));
			}, 1500);
		} else {
			setTimeout(() => {
				setHtml(data);
			}, 1500);
		}
	}, [data, numTablesDom]);

	// Timely destroy editor, important!
	useEffect(() => {
		return () => {
			if (editor == null) return;
			editor.destroy();
			setEditor(null);
		};
	}, [editor]);

	return (
		<>
			<div>
				<>BDI</>
				{bdi && (
					<div
						style={{ margin: '10px 0px 10px 0px', overflowY: 'scroll', height: '100px' }}
						dangerouslySetInnerHTML={{ __html: bdi && bdi }}
					></div>
				)}
				<FormControl sx={{ width: '100%' }}>
					<Button onClick={() => handleAddHTML(bdi)}>Agregar HTML</Button>
				</FormControl>
				<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
					<TextField
						margin='dense'
						id='images'
						label='HTML'
						multiline
						maxRows={6}
						fullWidth
						defaultValue={bdi}
						variant='standard'
						onChange={(e) =>
							setHtml(e.target.value.replace(/<span\/?[^>]+(>|$)/gi, '').replace(/<font\/?[^>]+(>|$)/gi, ''))
						}
					/>
				</FormControl>

				<FormControl sx={{ width: '35%' }}>
					<Button onClick={handleReset}>Agregar tabla</Button>
				</FormControl>
				<FormControl sx={{ width: '15%' }}>
					<TextField
						onChange={(e) => setNumTables(Number(e.target.value))}
						id='numtables'
						label='# Tablas'
						type='number'
						variant='standard'
					/>
				</FormControl>
			</div>
			<div style={{ border: '1px solid #ccc', zIndex: 100 }}>
				{/* <Toolbar
					editor={editor}
					defaultConfig={toolbarConfig}
					mode='default'
					style={{ borderBottom: '1px solid #ccc' }}
				/> */}
				<Editor
					defaultConfig={editorConfig}
					value={html}
					onCreated={setEditor}
					onChange={(editor) => props?.getValues(editor.getHtml())}
					mode='default'
					style={{ height: '300px', overflowY: 'hidden' }}
				/>
			</div>
			{/* <div style={{ marginTop: '15px' }}>{html}</div> */}
		</>
	);
}

export default RichText;
