import { LoadingActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { ProductsSearch } from "../products/types";
import axios from "axios";

import { LoadingState } from '../loading/types';


import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<LoadingState>>;

export const putLoading: ActionCreator<ThunkAction<void, ApplicationState, LoadingState, Action<string>>> = (loading) => {
	return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: LoadingActionTypes.PUT_LOADING,
				payload: loading,
			});
		} catch (e) {
			return dispatch({
				type: LoadingActionTypes.PUT_LOADING,
				payload: false,
			});
		}
	};
};
