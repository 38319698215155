export enum FilterActionTypes {
	PUT_FILTER = '@@products/PUT_FILTER',
	PUT_FILTER_FAILURE = '@@products/PUT_FILTER_FAILURE',
}

export interface FiltersState {
	//filtersGlb?: Filter[];
}

export interface Filter {
	id?: any;
	value?: any;
}