import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';

import App from './App';

import { APP_TITLE, APP_DESCRIPTION } from './utils/constants';

import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from 'history';
import configureStore from './configureStore';
import { Provider} from 'react-redux';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';


const history = createBrowserHistory();

const initialState: any = {};
const store = configureStore(history, initialState);

ReactDOM.render(
	<Provider store={store}>
		{/* <React.StrictMode> */}
		<Helmet>
			<title>{APP_TITLE}</title>
			<meta name='description' content={APP_DESCRIPTION} />
			<link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
			<meta name='viewport' content='initial-scale=1, width=device-width' />
		</Helmet>
		<Router>
			<App />
		</Router>
		{/* </React.StrictMode> */}
	</Provider>,
	document.getElementById('root')
);

serviceWorker.unregister();