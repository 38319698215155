import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';
import Autocomplete from '@mui/material/Autocomplete';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props: ItemProps;
	log?: any;
}

interface searchProps {
	value?: any;
	id?: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export const Logs = ({ log, name, data, props }: CrudItemProps) => {
	const dispatch = useDispatch<any>();
	const [search, setSearch] = React.useState('');

	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const [open, setOpen] = React.useState(false);

	const [filters, setFilters] = React.useState<any>({
		categories: [],
	});

	function serachData({ id, value }: searchProps) {
		setFilters({
			...filters,
			[id]: value,
		});
		if(value){props?.getValues(value);}
		
	}

	const handleLog = () => {
		props?.getValues('getLog');
	};

	const userSchema = object({
		categories: string().nullable().optional(),
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = () => {
		//methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		methods.reset(defaultValues);
		setFilters({
			...filters,
			categories: [],
		});
	};

	const onSubmitHandler: SubmitHandler<IFilter> = (values: IFilter) => {
		values.categories = filters.categories.map((item: any) => item.title);
		props?.getValues(values);
		handleClose();
	};

	return (
		<div>
			<Button variant='outlined' onClick={handleClickOpen}>
				{name}
			</Button>

			<Dialog fullWidth={true} maxWidth={'xl'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box display='flex' flexDirection='column' component='form' noValidate autoComplete='off'>
						<DialogContent>
							<FormControl sx={{ float: 'right', marginTop: { sm: '.5rem' }, width: '200px' }} size='small'>
								<Autocomplete
									id='user'
									isOptionEqualToValue={(option, value) => option.label === value.label}
									options={[
										...[{ id: '', label: 'Selecciona...' }],
										...(data?.logsDate?.map((m: any) => {
											return { id: m, label: m };
										}) || []),
									]}
									sx={{
										'& .MuiInputBase-input': {
											height: '.01px',
										},
									}}
									renderInput={(params) => <TextField {...params} label='Cliente' />}
									value={
										data?.logsDate
											?.filter((k: any) => k == filters?.log)
											.map((m: any) => {
												return { id: m, label: m };
											})[0] || { id: '', label: 'Selecciona...' } ||	undefined
									}
									onChange={(e: any, value: any) => {
										serachData({ id: 'log', value: value?.id });
									}}
								/>
							</FormControl>
							<FormControl sx={{ float: 'right', marginTop: { sm: '.5rem' }, width: '100%' }} size='small'>
								<Paper sx={{ width: '100%' }}>
									<Box>
										<TextField
											margin='dense'
											id='search'
											label='Buscar texto'
											type='text'
											variant='standard'
											onChange={(e) => setSearch(e.target.value)}
										/>
									</Box>
									<TableContainer sx={{ maxHeight: 340 }}>
										<Table stickyHeader aria-label='customized sticky table'>
											<TableHead>
												<TableRow>
													<StyledTableCell>Log</StyledTableCell>													
												</TableRow>
											</TableHead>
											<TableBody>
												{log
													?.filter((r: any) =>r.toLowerCase().includes(search.toLowerCase()))
													?.map((row: any,index:any) => (
														<StyledTableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
															<StyledTableCell component='th' scope='row'>
																{row}
															</StyledTableCell>															
														</StyledTableRow>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								</Paper>
							</FormControl>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button onClick={handleLog}>Ver Log</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};