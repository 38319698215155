import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';


import Box from '@mui/material/Box';
import { useMemo, useState, useEffect, Suspense } from 'react';


import { undefined, literal, object, string, TypeOf, custom, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import LinkMUI from '@mui/material/Link';

import { Dispatch } from 'redux';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { UserL, RulesPricesIva, RulesPricesUtility } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { putSandbox, putSandboxThunk } from '../../../store/user/action';

import { ThunkDispatch } from 'redux-thunk';

import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../store/index';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import ArrowRight from '@mui/icons-material/ArrowRight';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Home from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import Tooltip from '@mui/material/Tooltip';


export default function SandBoxAccess( ) {
	const [open, setOpen] = React.useState(false);
	const [aviso, setAviso] = React.useState('');
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();


	const sandbox = useSelector((state: ApplicationState) => state.user.sandbox);

	useEffect(() => {
		if (sandbox) {
			setAviso('Verifica la bandeja de entrada, esta en proceso de validación');
		}
		dispatchThunk(putSandboxThunk(false));		
	}, [dispatchThunk, sandbox]);

	const userSchema = object({
		//countryCode: string().optional().nullable(),
		//emailSB: string().min(1, 'Email es requerido').email('Email es invalido')
		emailSB: string().regex(new RegExp(/^([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6})*([,;][\s]*([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}))*$/i),'Email es invalido').min(1, 'Email es requerido'),
		companyName: string().optional().nullable(),
		IMCustomerNumber:string().optional().nullable()
	});

	type IUser = TypeOf<typeof userSchema>;

	const defaultValues: IUser = {
		//countryCode:'',
		emailSB: '',
		companyName:'',
		IMCustomerNumber:''
	};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IUser>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitHandler: SubmitHandler<IUser> = (values: IUser) => {
		/* values.categories = filters.categories.map((item: any) => item.title);
		values.categoriesExclude = filters.categoriesExclude.map((item: any) => item.title);
		values.manufacturer = filtersM.manufacturer.filter((item: any) => item.parent != item.title).map((i: any) => i.title);
		props?.getValues({ ...values, ...dataRules, ...{ secretOrder: secretOrder } });
		setSecretOrder(false); */
		dispatch(putSandbox(values));
	};

	const openInNewTab = (url: any) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<React.Fragment>
			{/* <LinkMUI href='#' variant='body2' onClick={handleClickOpen}>
				Solicita acceso
			</LinkMUI> */}
			<ListItem component='div' disablePadding>
				<ListItemButton sx={{ height: 56, color: '#FFFFFF' }} onClick={handleClickOpen}>
					<ListItemIcon>
						<Home color='primary' />
					</ListItemIcon>
					<ListItemText
						primary='Auth Reseller'
						primaryTypographyProps={{
							color: 'primary',
							fontWeight: 'medium',
							variant: 'body2',
						}}
					/>
				</ListItemButton>
				<Tooltip
					title='Auth Reseller'
					onClick={() => openInNewTab('https://developer.ingrammicro.com/reseller/register')}
				>
					<IconButton
						size='large'
						sx={{
							'& svg': {
								color: 'rgba(255,255,255,0.8)',
								transition: '0.2s',
								transform: 'translateX(0) rotate(0)',
							},
							'&:hover, &:focus': {
								bgcolor: 'unset',
								'& svg:first-of-type': {
									transform: 'translateX(-4px) rotate(-20deg)',
								},
								'& svg:last-of-type': {
									right: 0,
									opacity: 1,
								},
							},
							'&::after': {
								content: '""',
								position: 'absolute',
								height: '80%',
								display: 'block',
								left: 0,
								width: '1px',
								bgcolor: 'divider',
							},
						}}
					>
						<Settings />
						<ArrowRight sx={{ position: 'absolute', right: 4, opacity: 0 }} />
					</IconButton>
				</Tooltip>
			</ListItem>
			<ThemeProvider
				theme={createTheme({
					components: {
						MuiListItemButton: {
							defaultProps: {
								disableTouchRipple: true,
							},
						},
					},
					palette: {
						mode: 'light',
						primary: { main: '#000000' },
						background: { paper: '#FFFFFF' },
					},
				})}
			>
				<Dialog
					fullWidth={true}
					maxWidth={'sm'}
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'
				>
					<FormProvider {...methods}>
						<Box
							display='flex'
							flexDirection='column'
							component='form'
							noValidate
							autoComplete='off'
							sx={{ padding: { sm: '1rem' } }}
							onSubmit={methods.handleSubmit(onSubmitHandler)}
						>
							<DialogTitle id='alert-dialog-title'>Solicita acceso</DialogTitle>
							<DialogContent>
								{/* <FormControl
						sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
						size='small'
						//error={methods.formState.errors.countryCode ? true : false}
					>
						<InputLabel id='countryCode'>Codigo Ciudad</InputLabel>
						<Select
							//defaultValue={data?.countryCode || ''}
							labelId='countryCode'
							id='countryCode'
							label='Codigo Ciudad'
							{...methods.register('countryCode')}
						>
							<MenuItem value=''>
								<em>Seleccionar....</em>
							</MenuItem>
							<MenuItem value='US'>Miami</MenuItem>
							<MenuItem value='MX'>México</MenuItem>
							<MenuItem value='CO'>Colombia</MenuItem>
							<MenuItem value='CA'>Canada</MenuItem>
							<MenuItem value='PE'>Péru</MenuItem>
						</Select>
					</FormControl> */}
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='emailSB'
										label={`Email's separados por coma`}
										multiline
										fullWidth
										variant='standard'
										{...methods.register('emailSB')}
										error={methods.formState.errors.emailSB ? true : false}
										helperText={methods.formState.errors.emailSB?.message}
									/>
									<TextField
										margin='dense'
										id='companyName'
										label={`Nombre de la Compañia`}
										multiline
										fullWidth
										variant='standard'
										{...methods.register('companyName')}
									/>
									<TextField
										margin='dense'
										id='IMCustomerNumber'
										label={`IMCustomerNumber, xx-xxxxxx, Numero de Cliente Ingram`}
										multiline
										fullWidth
										variant='standard'
										{...methods.register('IMCustomerNumber')}
									/>
									<div>{aviso}</div>
								</FormControl>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleClose}>Cerrar</Button>
								<Button type='submit'>Solicitar</Button>
							</DialogActions>
						</Box>
					</FormProvider>
				</Dialog>
			</ThemeProvider>
		</React.Fragment>
	);
}
