import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AttributionIcon from '@mui/icons-material/Attribution';

interface CrudItemProps {
	listPrimaryAttribute?: any;
}

export default function PrimaryAttribute({ listPrimaryAttribute }: CrudItemProps)  {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<AttributionIcon style={{ cursor: 'pointer' }} onMouseOver={handleClickOpen}  />
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
				BackdropProps={{ style: { backgroundColor: 'transparent' } }}
			>
				<DialogTitle id='alert-dialog-title'>{"Atributos primarios del producto"}</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{listPrimaryAttribute.join(', ')}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
