import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import NoPhotographyRoundedIcon from '@mui/icons-material/NoPhotographyRounded';
import Badge from '@mui/material/Badge';

import EditIcon from '@mui/icons-material/Edit';

import AutocompletBoxGroup from '../Components/AutocompletBoxGroup';



interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name: string;
	data?: any;
	manufacturer?: any;
	categories?: any;
	props: ItemProps;
}

interface searchProps {
	value?: any;
	id?: any;
}


export const ItemEdit = ({ name, data, categories, manufacturer,props }: CrudItemProps) => {
	const dispatch = useDispatch<any>();	

	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const mapManufacturer = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.name.split(' ')[0].toUpperCase(),
					title: item?.name,
					id: item?.name,
					header: !item?.parentName,
				};
			}) || []
		);
	};
	
	const [open, setOpen] = React.useState(false);
	const [contents, setContents] = React.useState({ I:'imageCustomer', SH:'sheetHtmlCustomer', SJ:'sheetJsonCustomer', IA:'imageCustomerLockedCount', SHA:'sheetHtmlCustomerLockedCount', SJA:'sheetJsonCustomerLockedCount'});

	const [filters, setFilters] = React.useState<any>({
		[name]: data,
	});	

	function serachData({ id, value }: searchProps) {

		if (id.includes('ContentsCount')) {
			let dataChange: any = filters['ContentsCount'];
			dataChange[value.key]=value.value;
			setFilters({ ...filters, [id]: dataChange });
		}

		if (!id.includes('ContentsCount')) {
			setFilters({
				...filters,
				[id]: value
			});
		}
		
	}
	
	const userSchema = object({		
		categories: string().nullable().optional()
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {
		[name]:data
	};	

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});
	

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = () => {
		//methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		methods.reset(defaultValues);
		setFilters({
			...filters,
			categories:[]
		});
	};

	const handleSubmit = () => {
		props?.getValues(filters);
		setFilters({
			...filters, ['ContentsCount']: {
				imageCustomer: 0,
				sheetHtmlCustomer: 0,
				sheetJsonCustomer: 0,
				imageCustomerLockedCount: 0,
				sheetHtmlCustomerLockedCount: 0,
				sheetJsonCustomerLockedCount: 0,
				imageCustomerAll: false,
				sheetHtmlCustomerAll: false,
				sheetJsonCustomerAll: false,
				imageCustomerLockedCountAll: false,
				sheetHtmlCustomerLockedCountAll: false,
				sheetJsonCustomerLockedCountAll: false
			}
		});
		handleClose();
	};

	const handleSubmitBDI = () => {
		props?.getValues({ id: 'descriptionsBDI', value:data });
		handleClose();
	};

	const onSubmitHandler: SubmitHandler<IFilter> = (values: IFilter) => {		
		values.categories = filters.categories.map((item: any) => item.title);
		handleClose();
	};		

	return (
		<div>
			{/* <Button variant='outlined' >{name}*/}
			<EditIcon style={{ fontSize: 'medium', cursor: 'pointer' }} onClick={handleClickOpen} />
			{/* </Button> */}
			<Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						//onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							{['ContentsCount'].includes(name) && (
								<>
									<TableRow tabIndex={-1} key={1}>
										{Object.keys(contents).map((c: string, i: any) => {
											const contentsLabel: any = Object.values(contents);
											return (
												<TableCell key={`key${c}`} align={'center'} style={{ fontSize: '11px' }}>
													<TextField
														defaultValue={data[contentsLabel[i]] || 0}
														id={`${name}${c}`}
														label={c}
														variant='standard'
														onChange={(e) =>
															serachData({
																id: 'ContentsCount',
																value: { key: contentsLabel[i], value: Number(e.target.value) },
															})
														}
													/>
												</TableCell>
											);
										})}
									</TableRow>
									<TableRow tabIndex={-1} key={2}>
										{Object.keys(contents).map((c: string, i: any) => {
											const contentsLabel: any = Object.values(contents);
											return (
												<TableCell key={`key${c}2`} align={'center'} style={{ fontSize: '11px' }}>
													<Checkbox
														style={{ width: '6px' }}
														onClick={(e: any) =>
															serachData({
																id: 'ContentsCount',
																value: { key: `${contentsLabel[i]}All`, value: e.target.checked },
															})
														}
														id={`${name}${c}Check`}
													/>
												</TableCell>
											);
										})}
									</TableRow>
								</>
							)}
							{!['Fabricante', 'SubCategoria', 'ContentsCount'].includes(name) && (
								<TextField
									defaultValue={data}
									margin='dense'
									id={name}
									label={name}
									multiline
									maxRows={6}
									fullWidth
									variant='standard'
									onChange={(e) => serachData({ id: name, value: e.target.value })}
								/>
							)}
							{['Fabricante'].includes(name) && (
								<AutocompletBoxGroup
									props={{
										group: true,
										data: mapManufacturer(manufacturer),
										value: data,
										maxWidth: 200,
										id: 'manufacturer',
										title: 'Fabricante',
										onSearch: (search: searchProps) => serachData({ id: name, value: search.value.title }),
									}}
								/>
							)}
							{['SubCategoria'].includes(name) && (
								<AutocompletBoxGroup
									props={{
										group: true,
										data: mapCategories(categories),
										value: data,
										maxWidth: 200,
										id: 'categories',
										title: 'Categoria',
										onSearch: (search: searchProps) => serachData({ id: name, value: search.value.title }),
									}}
								/>
							)}
						</DialogContent>
						<DialogActions>
							{/* {!['Fabricante', 'SubCategoria'].includes(name) && (
								<Button sx={{ float: 'left' }} onClick={handleSubmitBDI}>
									BDI
								</Button>
							)} */}
							<Button onClick={handleClose}>Cerrar</Button>
							<Button onClick={handleSubmit}>Cargar</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};