import React from 'react';
import { Chart } from 'react-google-charts';
import Paper from '@mui/material/Paper';


export const dataActive = [
	['Task', 'Hours per Day'],
	['Con Imagen', 11],
	['Sin Imagen', 11],
];

export const dataInactive = [
	['Task', 'Hours per Day'],
	['Con Imagen', 11],
	['Sin Imagen', 11],
];

export const optionsActive = {
	title: 'Activos',
	//sliceVisibilityThreshold: 0.2, // 20%
	width: 500,
	height:250,
};

export const optionsInactive = {
	title: 'Inactivos',
	//sliceVisibilityThreshold: 0.2, // 20%
	width: 500,
	height:250,
};

export default function Dashboard() {
	return (
		<>
		<Paper
			sx={{
				display: 'flex',
				justifyContent: 'center',
				flexWrap: 'wrap',
				listStyle: 'none',
				p: 0.5,
				m: 0,
				height:300
			}}
			component='ul'
		>
			<Chart chartType='PieChart' data={dataActive} options={optionsActive} width={'50%'} height={'200px'} />
			<Chart chartType='PieChart' data={dataInactive} options={optionsInactive} width={'50%'} height={'200px'} />
		</Paper>
		<Paper
			sx={{
				display: 'flex',
				justifyContent: 'center',
				flexWrap: 'wrap',
				listStyle: 'none',
				p: 0.5,
				m: 0,
				height:300
			}}
			component='ul'
		>
			<Chart chartType='PieChart' data={dataActive} options={optionsActive} width={'50%'} height={'200px'} />
			<Chart chartType='PieChart' data={dataInactive} options={optionsInactive} width={'50%'} height={'200px'} />
			</Paper>
		</>
	);
}
