import { Reducer } from "redux";
import { HistoryAccessActionTypes, HistoryAccessState } from './types';
import { userReset } from '../user/action';

export const initialState: HistoryAccessState = {
	length: 1,
	data: undefined,
	datasearch: undefined,
	errors: { code: undefined, message: undefined },
	loading: false,
};

const reducer: Reducer<HistoryAccessState> =  (state = initialState, action)  => {
	switch (action.type) {
		case HistoryAccessActionTypes.GET_HISTORYACCESS: {
			return { ...state, datasearch:action.payload.historyaccess, data: action.payload.historyaccess, length: action.payload.total, errors: action.payload?.historyaccess ? undefined : { code:500, message:undefined } };
		}
		case HistoryAccessActionTypes.GET_HISTORYACCESS_FAILURE: {	
			return { ...state,  datasearch: undefined, data: undefined, length: 1, errors: { code:action.payload, message:undefined } };
		}
		default: {
			return state;
		}
	}
};

export { reducer as HistoryAccessReducer };