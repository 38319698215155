import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';


import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts } from '../../../store/products/action';
import { getManufacturer } from '../../../store/manufacturer/action';
import { getCategories } from '../../../store/categories/action';
import { userReset } from '../../../store/user/action';

import { Dispatch } from 'redux';

import { ManufacturerState, Manufacturer } from '../../../store/manufacturer/types';
import { ApplicationState } from '../../../store/index';

import AutocompletCheckBox from './AutocompletCheckBox';
import AutocompletCheckBoxGroup from './AutocompletCheckBoxGroup';
import DatePicker from './DatePicker';
import TextNumberBasic from './TextNumber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { putFilter } from '../../../store/filters/action';
import { putLoading } from '../../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { RulesPricesIva } from '../../../store/user/types';

import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';

import SaveIcon from '@mui/icons-material/Save';


interface CrudItemProps {
	usersSuper?: any;
	name?: string;
	data?: RulesPricesIva[];
	props: ItemProps;
}
interface ItemProps {
	deleteValues: Function;
	saveValues: Function;
}
const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};


interface Column {
	id:
		| undefined
		| 'row?.password'
		| 'row?.name'
		| 'row?.email'
		| 'row?.status'
		| 'row?.customer?.countryCode'
		| 'row?.customer?.email';

	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
	component?: boolean;
}

const columns: readonly Column[] = [
	{ id: undefined, label: 'Enviar', minWidth: 80, component: true },
	{ id: undefined, label: 'Eliminar', minWidth: 40, component: true },
	{
		id: 'row?.password',
		label: 'Pass',
		minWidth: 150,
	},
	{
		id: 'row?.name',
		label: 'Nombre',
		minWidth: 150,
	},
	{
		id: 'row?.email',
		label: 'Email',
		minWidth: 80,
	},
	{
		id: 'row?.status',
		label: 'Status',
		minWidth: 80,
	},
	{
		id: 'row?.customer?.countryCode',
		label: 'Ciudad Padre',
		minWidth: 80,
	},
	{
		id: 'row?.customer?.email',
		label: 'Email Padre',
		minWidth: 250,
	},
];

function Row(props: { usersSuper: any; rowp: any; deleteValues: Function; saveValues: Function }) {
	//const { rowp } = props;
	const [open, setOpen] = React.useState(false);
	const [name, setName] = React.useState('');
	const [pass, setPass] = React.useState('');
	
	return (
		<React.Fragment>
			{props.rowp?.map((row: any) => {
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column) => {
							if (column?.id) {
								const value = eval(column.id);
								switch (column.label) {
									case 'Pass': {
										return (
											<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
												<TextField
													margin='dense'
													id={column.id}
													defaultValue={value}
													onChange={(e) => setPass(e.target.value)}
													type='text'
													fullWidth
													variant='standard'
													InputProps={{
														endAdornment: pass.length>0&&(
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={() => { props?.saveValues({ field:'password',id: row.email, value: pass || 0 }); setPass(''); }}
																	edge='end'
																>
																	<SaveIcon />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</TableCell>
										);
									}
									case 'Nombre': {
										return (
											<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
												<TextField
													margin='dense'
													id={column.id}
													defaultValue={value}
													type='text'
													fullWidth
													variant='standard'
													onChange={(e) => setName(e.target.value)}
													InputProps={{
														endAdornment: name.length>0 && (
															<InputAdornment position='end'>
																<IconButton
																	aria-label='toggle password visibility'
																	onClick={(e: any) => { props?.saveValues({ field:'name',id: row.email, value: name || 0 }); setName(''); }}
																	edge='end'
																>
																<SaveIcon />	
																</IconButton>																
															</InputAdornment>
															
														),
													}}
												/>
											</TableCell>
										);
									}
									case 'Status': {
										return (
											<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
												<FormControl sx={{ width: '100%' }} size='small'>
													<Select
														defaultValue={value || ''}
														labelId='status'
														id='status'
														onChange={(e: any, value: any) => {
															props?.saveValues({ field: 'status', id: row.email, value: e.target.value || 0 });
														}}
													>
														<MenuItem value=''>
															<em>Seleccionar....</em>
														</MenuItem>
														<MenuItem value='ACTIVE'>Activo</MenuItem>
														<MenuItem value='INACTIVE'>Dasactivado</MenuItem>
														<MenuItem value='BLOCKED'>Bloqueado</MenuItem>
														<MenuItem value='DELETE'>Borrado</MenuItem>
													</Select>
												</FormControl>
											</TableCell>
										);
									}
									case 'Email Padre': {
										return (
											<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
												<FormControl sx={{ width: '100%' }} size='small'>
													<Autocomplete
														id='parentEmail'
														isOptionEqualToValue={(option, value) => option.label === value.label}
														options={[
															...[{ id: 0, label: 'Selecciona...' }],
															...(props.usersSuper?.map((m: any) => {
																return { id: m.id, label: m.email };
															}) || []),
														]}
														sx={{
															'& .MuiInputBase-input': {
																height: '.01px',
															},
														}}
														renderInput={(params) => <TextField {...params} label='Cliente' />}
														value={
															props.usersSuper
																?.filter((k: any) => k.email == value)
																.map((m: any) => {
																	return { id: m.id, label: m.email };
																})[0] || { id: 0, label: 'Selecciona...' } ||
															undefined
														}
														onChange={(e: any, value: any) => {
															props?.saveValues({ field:'customerId',id: row.email, value: value?.id || 0 });
														}}
													/>
												</FormControl>
											</TableCell>
										);
									}
									default: {
										return (
											<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
												{column.format ? column.format(value) : value}
											</TableCell>
										);
									}
								}
							} else {
								return (
									<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
										<Button
											key={column.id}
											variant='outlined'
											onClick={() => {
												props?.saveValues({ field: column.label.toLowerCase(), id: row.email, value: undefined || 0 });
											}}
										>
											{column.label}
										</Button>
									</TableCell>
								);
							}
						})}
					</TableRow>
				);
			})}
		</React.Fragment>
	);
}

export default function TableSandBox({ usersSuper, name, data, props}: CrudItemProps) {
	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<div>{name}</div>
			<TableContainer sx={{ maxHeight: 500 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row) => (
							<Row
								key={row.id}
								rowp={[row]}
								usersSuper={usersSuper}
								deleteValues={props.deleteValues}
								saveValues={props.saveValues}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
