import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';

import {
	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import DatePicker from '../Components/DatePicker';
import TextNumberBasic from '../Components/TextNumber';

import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';

import Badge from '@mui/material/Badge';
import RichText from '../Components/RichText';

import CodeIcon from '@mui/icons-material/Code';
import { getSheetsBDI } from '../../../store/products/action';
import { ApplicationState } from '../../../store/index';

import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';

import AttributionIcon from '@mui/icons-material/Attribution';


interface ItemProps {
	getValues: Function;
	putPrimaryAttribute: Function;
}

interface CrudItemProps {
	name?: string;
	data?: any;
	props?: ItemProps;
	user?: any;
	sku?: string;
	mfrpartnumber?: string;
	listPrimaryAttribute?: any;
}

interface searchProps {
	value?: any;
	id?: any;
}

export const ViewSheets = ({ listPrimaryAttribute, mfrpartnumber, sku, name, data, props, user }: CrudItemProps) => {
	const dispatch = useDispatch<any>();
	const bdisheets = useSelector((state: ApplicationState) => state.products).bdisheets || [];
	const [sheets, setSheets] = React.useState('');

	const save = (data: any) => {
		console.log(data);
	};

	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};

	const [open, setOpen] = React.useState(false);

	const [filters, setFilters] = React.useState<any>({});
	const [objJson, setObjJson] = React.useState<any>({});
	useEffect(() => {
		setObjJson(transformJson(data));
	}, [data]);

	function serachData({ id, value }: searchProps) {
		setFilters({
			...filters,
			[id]: value.length > 100 ? value : (data && transformSheet(data)) || null,
		});
	}

	const userSchema = object({
		categories: string().nullable().optional(),
	});

	// 👇 Infer the Schema to get the TS Type
	type IFilter = TypeOf<typeof userSchema>;

	const defaultValues: IFilter = {};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IFilter>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleChange = (even: any) => {
		console.log(even.value);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = () => {
		//methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleSubmit = () => {
		//console.log(filters);
		props?.getValues({ id: 'technicalSpecifications', value: filters.technicalSpecifications });
		handleClose();
	};

	const handleSubmitBDI = () => {
		//console.log(filters);
		props?.getValues({ id: 'technicalSpecificationsBDI', value: transformSheet(data) });
		handleClose();
	};
	const handleSubmitIngram = () => {
		//console.log(filters);
		props?.getValues({ id: 'technicalSpecificationsGetIngram', value: user.user.id });
	};
	const handleReset = () => {
		methods.reset(defaultValues);
		setFilters({
			...filters,
			categories: [],
		});
	};

	const onSubmitHandler: SubmitHandler<IFilter> = (values: IFilter) => {
		values.categories = filters.categories.map((item: any) => item.title);
		handleClose();
	};

	const handleFindSubmitBDI = () => {
		dispatch(getSheetsBDI({ country: user.user.countryCode.toLowerCase(), mfrpartnumber: mfrpartnumber }));
	};

	const handleSheets = (sheets: any) => {
		setSheets(sheets);
	};

	const transformSheet: any = (data: any) => {
		let jsonDescription: any = [];
		try {
			jsonDescription = data ? JSON.parse(data || '[]') : [];
		} catch (err) {
			jsonDescription = [];
		}
		let descriptionArray: any = [];
		let description: string = '';
		for (let itemDes in jsonDescription) {
			if (!descriptionArray[jsonDescription[itemDes].headerName]) {
				descriptionArray[jsonDescription[itemDes].headerName] = '';
			}
			descriptionArray[
				jsonDescription[itemDes].headerName
			] += `<tr style="font-weight: normal;"><td>${jsonDescription[itemDes].attributeName}</td><td>${jsonDescription[itemDes].attributeValue}</td></tr>`;
		}
		const keysDescriptions: any = Object.keys(descriptionArray);
		for (let itemDes in keysDescriptions) {
			description += `<tr style="font-weight: bold;"><th colspan="2">${keysDescriptions[itemDes]}</th></tr>${
				descriptionArray[keysDescriptions[itemDes]]
			}`;
		}
		description = `<table class="technical-specifications-table"><tbody>${description}</tbody></table>`;
		return description;
	};

	const transformJson: any = (data: any) => {
		let jsonDescription: any = [];
		try {
			jsonDescription = data ? JSON.parse(data || '[]') : [];
		} catch (err) {
			jsonDescription = [];
		}
		let descriptionArray: any = [];
		let description: any = {};
		for (let itemDes in jsonDescription) {
			if (!descriptionArray[jsonDescription[itemDes].headerName]) {
				descriptionArray[jsonDescription[itemDes].headerName] = '';
			}
			description[
				jsonDescription[itemDes].attributeName
			] = `${jsonDescription[itemDes].attributeName} - ${jsonDescription[itemDes].attributeValue}`;
		}
		return description;
	};

	return (
		<div>
			{/* <Button variant='outlined' > {name} */}
			{data ? (
				<Badge badgeContent={data?.length} color='success' max={99999}>
					<FormatAlignJustifyIcon style={{ cursor: 'pointer' }} onClick={handleClickOpen}/>
				</Badge>
			) : (
				<ContentPasteOffIcon style={{ cursor: 'pointer' }} onClick={handleClickOpen} />
			)}			
			{/* </Button> */}

			<Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						//onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							<>Ingram</>
							{data && user.user.super && (
								<div>
									<List style={{ margin: '10px 0px 10px 0px', overflowY: 'scroll', height: '100px' }}>
										{Object.keys(objJson).map((o: any) => {
											return (
												<ListItem
													button
													key={o}
													onClick={() =>
														props?.putPrimaryAttribute({ id: 'primaryAttribute', value: { sku: sku, title: o } })
													}
												>
													{objJson[o]}
												</ListItem>
											);
										})}
									</List>
								</div>
							)}
							{data && (
								<div
									style={{ margin: '10px 0px 10px 0px', overflowY: 'scroll', height: '100px' }}
									dangerouslySetInnerHTML={{ __html: data && transformSheet(data) }}
								></div>
							)}
							{/* 
							<TextField
								margin='dense'
								id='jsonEdit'
								label='HTML'
								multiline
								maxRows={6}
								fullWidth
								defaultValue={dangerouslySetInnerHTML={{
								__html: `<pre>${JSON.stringify(JSON.parse(data), null, 2)}</pre>`,
							}}}
								variant='standard'
								onChange={(e) =>
									setHtml(e.target.value.replace(/<span\/?[^>]+(>|$)/gi, '').replace(/<font\/?[^>]+(>|$)/gi, ''))
								}
							/> 
							*/}
							{user.user.super && (
								<RichText
									props={{ getValues: (values: any) => serachData({ id: 'technicalSpecifications', value: values }) }}
									data={data?.length > 0 ? transformSheet(data) : ''}
									bdi={sheets}
									name='Sheets'
								/>
							)}
							{(bdisheets &&
								Object.keys(bdisheets)?.map((m: any, key: number) => {
									return (
										<Button
											key={key}
											onClick={() => {
												handleSheets(bdisheets[m]);
											}}
										>
											{m}
										</Button>
									);
								})) || <></>}
						</DialogContent>

						<DialogActions>
							{/* {user.user.super && <Button onClick={handleSubmitIngram}>Buscar Ingram</Button>}
							{user.user.super && <Button onClick={handleFindSubmitBDI}>Buscar BDI</Button>}							
							{user.user.super && <Button onClick={handleSubmitBDI}>BDI</Button>} */}
							<Button onClick={handleClose}>Cerrar</Button>
							{user.user.super && <Button onClick={handleSubmit}>Cargar</Button>}
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};