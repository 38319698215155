export interface ManufacturerSearch {
	search: string;
	rowsPerPage: number;
	page: number;
}

export enum ManufacturerActionTypes {
	GET_MANUFACTURER = '@@manufacturer/GET_MANUFACTURER',
	GET_MANUFACTURER_FAILURE = '@@manufacturer/GET_MANUFACTURER_FAILURE',
	PUT_MANUFACTURER = '@@manufacturer/PUT_MANUFACTURER',
	PUT_MANUFACTURER_FAILURE = '@@manufacturer/PUT_MANUFACTURER_FAILURE',
}
export interface errors {
	code?: number;
	message?: string;
}

export interface ManufacturerState {
	length: number;
	loading: boolean;
	data?: Manufacturer[];
	datasearch?: Manufacturer[];
	errors?: errors;
}

export interface Manufacturer {
	id?: number;
	name: string;
	tags: string;
	image?: string;
}