export interface Brs {
	id: string,
	label: string,
	minWidth: number
}

export interface ProductsSearch {
	search: string;
	rowsPerPage: number;
	page: number;
}

export interface SheetsBDI {
	country: string;
	mfrpartnumber: string;
}

export interface ImagesBDI {
	country: string;
	mfrpartnumber: string;
}

export enum ProductsActionTypes {
	GET_PRODUCTS = '@@products/GET_PRODUCTS',
	GET_PRODUCTS_FAILURE = '@@products/GET_PRODUCTS_FAILURE',
	PUT_PRODUCTS = '@@products/PUT_PRODUCTS',
	PUT_PRODUCTS_FAILURE = '@@products/PUT_PRODUCTS_FAILURE',
	REPORT_PRODUCTS = '@@products/REPORT_PRODUCTS',
	REPORT_PRODUCTS_FAILURE = '@@products/REPORT_PRODUCTS_FAILURE',
	REPORT_STATUS_PRODUCTS = '@@products/REPORT_STATUS_PRODUCTS',
	GET_BDIIMAGES = '@@products/GET_BDIIMAGES',
	GET_BDIIMAGES_FAILURE = '@@products/GET_BDIIMAGES_FAILURE',
	GET_BDISHEETS = '@@products/GET_SHEETSIMAGES',
	GET_BDISHEETS_FAILURE = '@@products/GET_SHEETSIMAGES_FAILURE',
	GET_BRS = '@@products/GET_BRS',
	GET_BRS_FAILURE = '@@products/GET_BRS_FAILURE',
}

export interface ReportStatusState {
	reportstatus: boolean;
}
export interface errors {
	code?: number;
	message?: string;
}

export interface ProductsState {
	brs: any;
	length: number;
	loading: boolean;
	data?: Prices[];
	skusEmptyImage?: String[];
	reportStatus?: boolean;
	report?: any;
	errors?: errors;
	bdiimages?: any;
	bdisheets?: any;
}

export interface Manufacturer {
	id?: number;
	name: string;
	country: string;
}

export interface Category {
	id?: number;
	name: string;
	parentId: number;
	country: string;
}

export interface Categories {
	id?: number;
	name: string;
	parentId: number;
	country: string;
	parent: Category;
	children: Category;
}

export interface Products {
	id?: number;
	description: string;
	sku: string;
	mfrpartnumber: string;
	vendornumber: string;
	vendorname: string;
	upcnumber: string;
	categoriesId: number;
	manufacturerId: number;
	lineId: number;
	images: string;
	productDetailDescription: string;
	sheets: string;
	technicalSpecifications: string;
	country: string;
	status: string;
	weight: number;
	height: number;
	width: number;
	length: number;
	completedAt: Date;
	createdAt: Date;
	optimized: Date;
	manufacturer: Manufacturer;
	categories: Categories;
	contentsOther: any;
}

export interface Prices {
	price: number;
	inventory: number;
	sku: string;
	customerId: number;
	createdAt: Date;
	updatedAt: Date;
	br10: number;
	br20: number;
	br30: number;
	br40: number;
	br50: number;
	br60: number;
	br70: number;
	br80: number;
	br90: number;
	products: Products;
}
