import { UserActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";
import { UserLogin } from "../user/types";
import { User, UserL } from "../user/types";
import axios from "axios";

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void,ApplicationState, null, Action<User>>;

export const userReset: ActionCreator<ThunkAction<void, ApplicationState, User, Action<string>>> = (user) => {
  return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: UserActionTypes.GET_LOGIN_FAILURE,
				payload: null,
			});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.GET_LOGIN_FAILURE,
				payload: null,
			});
		}
	};
};

export const userTunk: ActionCreator<ThunkAction<void, ApplicationState, User, Action<string>>> = (user) => {
	return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: UserActionTypes.REFRESH_LOGIN,
				payload: user,
			});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.GET_LOGIN_FAILURE,
				payload: null,
			});
		}
	};
};

export const getLogs: ActionCreator<ThunkAction<void, ApplicationState, User, Action<any>>> = (file,id) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.get<any>(REACT_APP_API_URL + `/profile/customer/logs/${id}/${file}`, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response:any) => {
					return dispatch({
						type: UserActionTypes.GET_LOGS,
						payload: response.data,
					});
				})
				.catch((err:any) => {
					return dispatch({
						type: UserActionTypes.GET_LOGS_FAILURE,
						payload: 500,
					});
				});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.GET_LOGS_FAILURE,
				payload: 500,
			});
		}
	};
};

export const getUsers: ActionCreator<ThunkAction<void, ApplicationState, User, Action<User>>> = (profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<User>(REACT_APP_API_URL +`/profile/${profile.profile}`, profile, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({
						type: UserActionTypes.GET_USERS,
						payload: response.data,
					});
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.GET_LOGIN_FAILURE,
						payload: 500,
					});
				});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.GET_LOGIN_FAILURE,
				payload: 500,
			});
		}
	};
};

export const getLogin: ActionCreator<ThunkAction<void, ApplicationState, User, Action<User>>> = (user) => {
  return (dispatch: Dispatch)  => {
	  try {
			dispatch({type: UserActionTypes.FETCH_REQUEST, payload: null});
			return axios
					.post<User>(REACT_APP_API_URL + '/signin', {
						email: user.email,
						password: user.password,
					})
					.then((response) => {
						return dispatch({
							type: UserActionTypes.GET_LOGIN,
							payload: response.data,
						});
					})
					.catch((err) => {
						return dispatch({
							type: UserActionTypes.GET_LOGIN_FAILURE,
							payload: null,
						});
					});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.GET_LOGIN_FAILURE,
				payload: null,
			});
		}
	};
  };

  export const createUser: ActionCreator<ThunkAction<void, ApplicationState, UserL, Action<UserL>>> = (user, profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<User>(REACT_APP_API_URL + `/signup/${profile}`, user, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_USER, payload: response.data });
				})
				.catch((err) => {
					return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: err.status });
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: 500 });
		}
	};
};

export const updateUser: ActionCreator<ThunkAction<void, ApplicationState, UserL, Action<UserL>>> = (user, profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<User>(REACT_APP_API_URL + `/profile/${profile}`, user, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_USER, payload: response.data });
				})
				.catch((err) => {
					
					return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: err.status });
				});
		} catch (err) {
			return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: 500 });
		}
	};
};

export const crudUser: ActionCreator<ThunkAction<void, ApplicationState, UserL, Action<UserL>>> = (user, profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<User>(REACT_APP_API_URL + `/profile/crud/${profile}`, user, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_USER, payload: response.data });
				})
				.catch((err) => {
					return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: { error: { message: err.response.data.error, code: err.response.status } } });
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.PUT_USER_FAILURE, payload: 500 });
		}
	};
};

export const productsSegment: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (segmentation, profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<any>(REACT_APP_API_URL + `/products/segment`, segmentation, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.GET_PRODUCTS_SEGMENT, payload: response.data });
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.GET_PRODUCTS_SEGMENT_FAILURE,
						payload: { error: { message: err.response.data.error, code: err.response.status } },
					});
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.GET_PRODUCTS_SEGMENT_FAILURE, payload: 500 });
		}
	};
};

export const productsCount: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (segmentation, profile) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<any>(REACT_APP_API_URL + `/products/count`, segmentation, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.GET_PRODUCTS_COUNT, payload: response.data });
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.GET_PRODUCTS_COUNT_FAILURE,
						payload: { error: { message: err.response.data.error, code: err.response.status } },
					});
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.GET_PRODUCTS_COUNT_FAILURE, payload: 500 });
		}
	};
};

export const putSandbox: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (sandbox) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<User>(REACT_APP_API_URL + `/profile/sandbox/get`, sandbox)
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_SANDBOX, payload: response.data });
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.PUT_SANDBOX_FAILURE,
						payload: false,
					});
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.PUT_SANDBOX_FAILURE, payload: false });
		}
	};
};

export const putSupport: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (sandbox) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<User>(REACT_APP_API_URL + `/profile/support`, sandbox)
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_SUPPORT, payload: response.data });
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.PUT_SUPPORT_FAILURE,
						payload: false,
					});
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.PUT_SUPPORT_FAILURE, payload: false });
		}
	};
};

export const putSandBoxData: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = (sandbox) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.put<User>(REACT_APP_API_URL + `/profile/sandbox/data`, sandbox, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: UserActionTypes.PUT_SANDBOX_DATA, payload: response.data, delete: ['eliminar'].includes(sandbox.field) });
				})
				.catch((err) => {
					return dispatch({
						type: UserActionTypes.PUT_SANDBOX_FAILURE,
						payload: false,
					});
				});
		} catch (e) {
			return dispatch({ type: UserActionTypes.PUT_SANDBOX_FAILURE, payload: false });
		}
	};
};

export const putSandboxThunk: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = () => {
	return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: UserActionTypes.PUT_SANDBOX_FAILURE,
				payload: false,
			});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.PUT_SANDBOX_FAILURE,
				payload: false,
			});
		}
	};
};

export const putSupportThunk: ActionCreator<ThunkAction<void, ApplicationState, any, Action<any>>> = () => {
	return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: UserActionTypes.PUT_SUPPORT_FAILURE,
				payload: false,
			});
		} catch (e) {
			return dispatch({
				type: UserActionTypes.PUT_SUPPORT_FAILURE,
				payload: false,
			});
		}
	};
};