import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { fontWeight } from '@mui/system';

import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function createData(endpoint: string, update: string, type:string, action: string, reque: string, title: string, def: string, link: string) {
	return { endpoint, update, type,action, reque, title, def, link };
}

const rows = [
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'street', 'Descripción de la calle', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'colony', 'Descripción de la colonia', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Request Body', 'Opcional', 'phoneNumber', 'Numero de telefono', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'city', 'Nombre de la ciudad', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'state', 'Codigo del estado, desde api/countrycode', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'cp', 'Codigo postal', ''),
	createData('POST api/shipping', 'Abr/2024', 'Array [Object products]', 'Request Body', 'Si', 'products', 'Lista de productos', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Request Body', 'Si', 'products[].sku', 'Identificador unico ingram', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Si', 'products[].qty', 'Cantidad de productos', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'products[].branch', 'Almacen desde donde se sirve', 'Si se omite, se sirve de almacen princiapl'),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Request Body', 'Opcional', 'products[].carrier', 'Paqueteria o metodo de envio', 'Si se omite, se sirve desde Estafeta o "E1"'),

	createData('POST api/shipping', 'Abr/2024', 'Array [Object freightEstimate]', 'Response', '-', 'freightEstimate', '', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Response', '-', 'freightEstimate[].branch', 'Codigo del almacen a servir', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Response', '-', 'freightEstimate[].branchName', 'Nombre del almacen a servir', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'freightEstimate[].cost', '-', 'Costo total del envio', '', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Response', '-', 'freightEstimate[].label', 'Etiqueta de envio', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'freightEstimate[].totalWeight', 'Peso total del envio', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'freightEstimate[].transitDays', 'Dias transcurridos hasta la entrega', ''),
	createData('POST api/shipping', 'Abr/2024', 'Array [String]', 'Response', '-', 'freightEstimate[].carrierList', 'Lista de paqueterias o metodo de envio disponibles', ''),
	createData('POST api/shipping', 'Abr/2024', 'String', 'Response', '-', 'currencyCode', 'Tipo de moneda', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'totalFreightAmount', 'Costo de envio', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'totalTaxAmount', 'Costo de iva', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'totalFees', 'Costo total por manejo de transacción', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'totalNetAmount', 'Costo total de productos, antes de iva y costo de envio', ''),
	createData('POST api/shipping', 'Abr/2024', 'Number', 'Response', '-', 'grossAmount', 'Costo total de la compra incluye iva y costo de envio', ''),
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
		fontSize: 10,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 12,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function BasicTable() {
	const [search, setSearch] = React.useState('');
	const [valueRadio, setValueRadio] = React.useState('');
	const [valueRadioNodos, setValueRadioNodos] = React.useState('Todos');
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValueRadio((event.target as HTMLInputElement).value);
	};
	

	const handleChangeNodos = (event: SelectChangeEvent) => {
		setValueRadioNodos(event.target.value as string);
	};


	return (
		<Paper sx={{ width: '100%' }}>
			<Box>
				<FormControl>
					<TextField
						margin='dense'
						id='search'
						label='Buscar texto'
						type='text'
						variant='standard'
						onChange={(e) => setSearch(e.target.value)}
					/>
				</FormControl>
				<FormControl>
					<InputLabel id='demo-simple-select-label'>Nodos</InputLabel>
					<Select
						labelId='demo-simple-select-label'
						id='demo-simple-select'
						value={valueRadioNodos}
						label='Nodos'
						onChange={handleChangeNodos}
						sx={{ width: 'auto' }}
					>
						<MenuItem value={'Todos'}>Todos</MenuItem>
						<MenuItem value={'products[].'}>products[].</MenuItem>
						<MenuItem value={'freightEstimate[].'}>freightEstimate[].</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<FormLabel id='demo-row-radio-buttons-group-label'>Acciones</FormLabel>
					<RadioGroup
						row
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={valueRadio}
						onChange={handleChange}
					>
						<FormControlLabel value='Response' control={<Radio />} label='Response' />
						<FormControlLabel value='Request' control={<Radio />} label='Request' />
						<FormControlLabel value='' control={<Radio />} label='Todo' />
					</RadioGroup>
				</FormControl>
			</Box>
			<TableContainer sx={{ maxHeight: 340 }}>
				<Table stickyHeader aria-label='customized sticky table'>
					<TableHead>
						<TableRow>
							<StyledTableCell>EndPoint</StyledTableCell>
							<StyledTableCell align='right'>Update</StyledTableCell>
							<StyledTableCell align='right'>Tipo</StyledTableCell>
							<StyledTableCell align='right'>Acción</StyledTableCell>
							<StyledTableCell align='right'>Requerido</StyledTableCell>
							<StyledTableCell align='right'>Titulo (Campo en Acción)</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Definición
							</StyledTableCell>
							<StyledTableCell style={{ maxWidth: 300 }} align='right'>
								Enlace
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows
							.filter((r: any) =>
								`${r.reque} ${r.title} ${r.type} ${r.def} ${r.link}`.toLowerCase().includes(search.toLowerCase())
							)
							.filter((r: any) => `${r.action}`.toLowerCase().includes(valueRadio.toLowerCase()))
							.filter((r: any) =>
								valueRadioNodos.replace('Todos', '') !== 'products[].'
									? `${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos', '').toLowerCase())
									: `${r.title}`.toLowerCase().includes(valueRadioNodos.replace('Todos', '').toLowerCase()) &&
									  !`${r.title}`.toLowerCase().includes('freightEstimate[].'.toLowerCase())
							)
							.map((row: any) => (
								<StyledTableRow key={row.endpoint} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<StyledTableCell component='th' scope='row'>
										{row.endpoint}
									</StyledTableCell>
									<StyledTableCell align='right'>{row.update}</StyledTableCell>
									<StyledTableCell align='right'>{row.type}</StyledTableCell>
									<StyledTableCell align='right'>{row.action}</StyledTableCell>
									<StyledTableCell align='right'>{row.reque}</StyledTableCell>
									<StyledTableCell align='right'>{row.title}</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.def}
									</StyledTableCell>
									<StyledTableCell style={{ maxWidth: 300 }} align='right'>
										{row.link}
									</StyledTableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
