import { Reducer } from 'redux';
import { ProductsActionTypes, ProductsState } from './types';
import { userReset } from '../user/action';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/index';




export const initialState: ProductsState = {
	length: 1,
	data: undefined,
	skusEmptyImage: undefined,
	report: undefined,
	reportStatus: false,
	bdiimages: undefined,
	bdisheets: undefined,
	brs:undefined,
	errors: { code: undefined, message: undefined },
	loading: false,
};



const reducer: Reducer<ProductsState> = (state = initialState, action) => {	
	switch (action.type) {
		case ProductsActionTypes.GET_PRODUCTS: {
			return {
				...state,
				skusEmptyImage: action.payload?.skusEmptyImage,
				data: action.payload.products,
				length: action.payload.total,
				errors: action.payload?.products ? undefined : { code: 500, message: undefined },
			};
		}
		case ProductsActionTypes.PUT_PRODUCTS: {
			console.log(state.data);
			const response: any = action.payload.response;
			return {
				...state,
				data: state.data?.map((item: any) => {

					if (!['excludeSyncShop'].includes(action.payload.product.id)) {

						for (let elem in response) {

							if (response[elem].sku == item.sku) {
								if (['categoriesIdIngram', 'manufacturerIdIngram'].includes(action.payload.product.id)) {

									if (['categoriesIdIngram'].includes(action.payload.product.id)) {
										item.products[action.payload.product.id] = response[elem][action.payload.product.id];
										item.products['categoriesIngram'].name = response[elem][action.payload.product.id];
									}

									if (['manufacturerIdIngram'].includes(action.payload.product.id)) {
										item.products[action.payload.product.id] = response[elem][action.payload.product.id];
										item.products['manufacturerIngram'].name = response[elem][action.payload.product.id];
									}

								} else {									
									item.products[action.payload.product.id] = response[elem][action.payload.product.id];								
								}
							}
						}

					} else {

						const productsExcludes: any = action.payload.product.sku;
						for (let elem in productsExcludes) {							
							if (productsExcludes[elem] === item.sku) {
								item[action.payload.product.id] = !action.payload.product.value;
							}
							if (action.payload.product.sku.length>1) {
								if (item.sku === action.payload.product.skuSelect) {
									item[action.payload.product.id] = !action.payload.product.value;
								}
							}
						}

					}

					return item;
				}),
			};
		}
		case ProductsActionTypes.GET_PRODUCTS_FAILURE: {
			return { ...state, data: undefined, length: 1, errors: { code: action.payload, message: undefined } };
		}
		case ProductsActionTypes.REPORT_PRODUCTS: {
			return { ...state, report: action.payload.response, reportStatus: true, loading: false };
		}
		case ProductsActionTypes.REPORT_PRODUCTS_FAILURE: {
			return { ...state, report: null, reportStatus: false, loading: false };
		}		
		case ProductsActionTypes.GET_BDIIMAGES: {
			return { ...state, bdiimages: action.payload, loading: false };
		}
		case ProductsActionTypes.GET_BDISHEETS: {
			return { ...state, bdisheets: action.payload, loading: false };
		}
		case ProductsActionTypes.REPORT_STATUS_PRODUCTS: {
			return { ...state, reportStatus: action.payload };
		}
		case ProductsActionTypes.GET_BRS: {
			return { ...state, brs:action.payload };
		}
		case ProductsActionTypes.GET_BRS_FAILURE: {	
			return { ...state,  brs: undefined };
		}
		default: {
			return state;
		}
	}
};

export { reducer as ProductsReducer };
