import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { History } from "history";

// import inventorySaga from "./inventory/sagas";
/* import { InventoryReducer } from "./inventory/reducer";
import { InventoryState } from "./inventory/types"; */

// import cartSaga from "./cart/sagas";
/* import { cartReducer } from "./cart/reducer";
import { cartState } from "./cart/types";*/

import { UserReducer } from "./user/reducer";
import { UserState } from "./user/types"; 

import { ProductsReducer } from './products/reducer';
import { ProductsState } from './products/types'; 

import { ManufacturerReducer } from './manufacturer/reducer';
import { ManufacturerState } from './manufacturer/types'; 

import { OrdersReducer } from './orders/reducer';
import { OrdersState } from './orders/types'; 

import { HistoryAccessReducer } from './historyaccess/reducer';
import { HistoryAccessState } from './historyaccess/types'; 

import { CategoriesReducer } from './categories/reducer';
import { CategoriesState } from './categories/types'; 

import { FiltersReducer } from './filters/reducer';
import { FiltersState } from './filters/types'; 

import { LoadingReducer } from './loading/reducer';
import { LoadingState } from './loading/types'; 

import { RouterState } from "connected-react-router";

export interface ApplicationState {
	user: UserState;
	products: ProductsState;
	manufacturer: ManufacturerState;
	orders: OrdersState;
	historyaccess: HistoryAccessState;
	categories: CategoriesState;
	filters: FiltersState;
	router: RouterState;
	loading: LoadingState;
}

export const createRootReducer = (history: History) =>
	combineReducers({
		user: UserReducer,
		products: ProductsReducer,
		manufacturer: ManufacturerReducer,
		orders: OrdersReducer,
		historyaccess: HistoryAccessReducer,
		categories: CategoriesReducer,
		filters: FiltersReducer,
		loading: LoadingReducer,
		router: connectRouter(history),
	});
