import { useState } from 'react';
import { List, Divider, Collapse } from '@mui/material';

import { RouteItem } from './RouteItem';
import { SignOutRoute } from './SignOutRoute';

import { routes } from '../../../config';
import { Route } from '../../../types';

import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/index';

import { loadState } from '../../../utils/localstorage';
import { UserState, UserLogin } from '../../../store/user/types';


export const Routes = () => {
	const [routesState, setRoutesStage] = useState<Route[]>(routes);
	let user = useSelector((state: ApplicationState) => state.user.data);
	let userLocal: UserLogin = loadState();

	const handleMenuClick = (route: Route) => {
		const items = routesState.map((item) => {
			if (item.key === route.key) {
				item.expanded = !item.expanded;
			}
			return item;
		});
		setRoutesStage(items);
	};

	return (
		<>
			<List component='nav' sx={{ height: '100%' }}>
				{routesState.map((route: Route) => {
					const exactCategoryRoute: any = route.key.split('-');
					if (route.rol === user.user.role || route.rol === userLocal.user.role) {
						/* if (user?.user?.category) {
							return (exactCategoryRoute.includes('EXACT') && exactCategoryRoute.includes(user?.user?.category)) ?(
								<div key={route.key}>
									{route.subRoutes ? (
										<>
											<RouteItem key={`${route.key}`} route={route} hasChildren handleMenuClick={handleMenuClick} />
											<Collapse in={route.expanded} timeout='auto' unmountOnExit>
												<List component='div' disablePadding>
													{route.subRoutes.map((sRoute: Route) => (
														<RouteItem key={`${sRoute.key}`} route={sRoute} nested />
													))}
												</List>
											</Collapse>
										</>
									) : (
										<RouteItem key={route.key} route={route} nested={false} />
									)}
									{route.appendDivider && <Divider />}
								</div>
							):undefined;
						}else{ */
							return !exactCategoryRoute.includes('EXACT') ||
								   (user?.user?.category && exactCategoryRoute.includes('EXACT') && exactCategoryRoute.includes(user?.user?.category)) ||
								   (exactCategoryRoute.includes('EXACT') && exactCategoryRoute.includes('SUPER') && user.user.super)
								? (
										<div key={route.key}>
											{route.subRoutes ? (
												<>
													<RouteItem key={`${route.key}`} route={route} hasChildren handleMenuClick={handleMenuClick} />
													<Collapse in={route.expanded} timeout='auto' unmountOnExit>
														<List component='div' disablePadding>
															{route.subRoutes.map((sRoute: Route) => (
																<RouteItem key={`${sRoute.key}`} route={sRoute} nested />
															))}
														</List>
													</Collapse>
												</>
											) : (
												<RouteItem key={route.key} route={route} nested={false} />
											)}
											{route.appendDivider && <Divider />}
										</div>
									) : undefined;
						//}
					}
				})}
			</List>
			<SignOutRoute />
		</>
	);
};
