import { CategoriesActionTypes } from "./types";

import { ActionCreator, Action, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../index";

import { CategoriesSearch } from "../categories/types";
import axios from "axios";

import { userReset } from '../user/action';

import { getAuthorization } from '../../utils/localstorage';

const { REACT_APP_API_URL = 'http://localhost:3003' } = process.env;

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<CategoriesSearch>>;

export const getCategories: ActionCreator<ThunkAction<void, ApplicationState, CategoriesSearch, Action<CategoriesSearch>>> = (categories) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<CategoriesSearch>(REACT_APP_API_URL + '/categories', categories, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					if (categories.ingram) {
						return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_INGRAM_SEARCH, payload: response?.data });
					} else {
						return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES, payload: response?.data });
					}
				})
				.catch((err) => {
					return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
				});        
		} catch (e) {
			return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
		}
	};
};

export const getCategoriesSearch: ActionCreator<
	ThunkAction<void, ApplicationState, CategoriesSearch, Action<CategoriesSearch>>
> = (categories) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<CategoriesSearch>(REACT_APP_API_URL + '/categories', categories, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_SEARCH, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
				});
		} catch (e) {
			return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
		}
	};
};

export const getCategoriesIngramSearch: ActionCreator<
	ThunkAction<void, ApplicationState, CategoriesSearch, Action<CategoriesSearch>>
> = (categories) => {
	return (dispatch: Dispatch) => {
		try {
			return axios
				.post<CategoriesSearch>(REACT_APP_API_URL + '/categories', categories, {
					headers: {
						Accept: 'application/json',
						Authorization: getAuthorization(),
						'Content-Type': 'application/json',
					},
				})
				.then((response) => {
					return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_INGRAM, payload: response?.data });
				})
				.catch((err) => {
					return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
				});
		} catch (e) {
			return dispatch({ type: CategoriesActionTypes.GET_CATEGORIES_FAILURE, payload: 500 });
		}
	};
	};

	export const crudCategories: ActionCreator<ThunkAction<void, ApplicationState, CategoriesSearch, Action<CategoriesSearch>>> = (categories) => {
		return (dispatch: Dispatch) => {
			try {
				return axios
					.put<CategoriesSearch>(REACT_APP_API_URL + `/categories/crud`, categories, {
						headers: {
							Accept: 'application/json',
							Authorization: getAuthorization(),
							'Content-Type': 'application/json',
						},
					})
					.then((response) => {
						if (['changeIngram'].includes(categories.type)) {
							return dispatch({ type: CategoriesActionTypes.PUT_CATEGORIES_INGRAM, payload: response.data });
						}
						if (categories.categoryNameIngram) {
							return dispatch({ type: CategoriesActionTypes.PUT_CATEGORIES, payload: response.data });
						}
						if (categories?.addCategory) {
							return dispatch({ type: CategoriesActionTypes.PUT_CATEGORIES_ADD, payload: response.data });
						}
					})
					.catch((err) => {
						return dispatch({ type: CategoriesActionTypes.PUT_CATEGORIES_FAILURE, payload: 500 });
					});
			} catch (e) {
				return dispatch({ type: CategoriesActionTypes.PUT_CATEGORIES_FAILURE, payload: 500 });
			}
		};
	};