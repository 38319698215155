import { Reducer } from "redux";
import { LoadingActionTypes, LoadingState } from './types';

export const initialState: LoadingState = {	
	loading: false,
};

const reducer: Reducer<LoadingState> = (state = initialState, action) => {
	switch (action.type) {
		case LoadingActionTypes.PUT_LOADING: {
			return { ...state, loading: action.payload };
		}
		default: {
			return state;
		}
	}
};

export { reducer as LoadingReducer };