import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import { UserL, RulesPricesIva, RulesPricesUtility } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, custom, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletCheckBoxGroup from '../Components/AutocompletCheckBoxGroup';
import TableRulesIva from '../Components/TableRulesIva';
import TableRulesUtility from '../Components/TableRulesUtility';
import TableSandBox from '../Components/TableSandBox';
import TableProductsSegment from '../Components/TableProductsSegment';

import AutocompletBoxGroup from '../Components/AutocompletBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';
import { bool } from 'yup';
import { getManufacturer } from '../../../store/manufacturer/action';

import {	
	getCategoriesSearch,
	getCategoriesIngramSearch,
} from '../../../store/categories/action';

import { Dispatch } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/index';

import VpnKeyIcon from '@mui/icons-material/VpnKey';
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOutlined';

import ProductsSegment from '../Dialogs/ProductsSegment';


interface ItemProps {
	getValues: Function;
	getValuesSandBox: Function;
	getProductsCount: Function;
	getProductsSegment: Function;
}

interface CrudItemProps {
	usersSuper:any;
	user?: any;
	name?: string;
	data?: UserL;
	profile?: string;
	role?: string;
	props: ItemProps;
	categories: CategoriesState;
	manufacturer: ManufacturerState;
}

interface searchProps {
	value?: any;
	id?: any;
}

/* 
const userSchema = object({
	id: number().nullable().optional(),
	name: string().nullable().optional(),
	username: string().nullable().optional(),
	clientId: string().nullable().optional(),
	clientSecret: string().nullable().optional(),
	customerNumber: string().nullable().optional(),
	senderId: string().nullable().optional(),
	userBD: string().nullable().optional(),
	passBD: string().nullable().optional(),
	nameBD: string().nullable().optional(),
	hostEmail: string().nullable().optional(),
	brsExcludes: string().nullable().optional(),
	userEmail: string().nullable().optional(),
	passEmail: string().nullable().optional(),
	paramSpecial: string().nullable().optional(),
	skusSegmentation: string().nullable().optional(),
	toEmail: string().nullable().optional(),
	urlApiApp: string().nullable().optional(),
	idApiApp: string().nullable().optional(),
	secretApiApp: string().nullable().optional(),
	typeApp: string().nullable().optional(),
	timeProductsCron: string().nullable().optional(),
	timePricesCron: string().nullable().optional(),
	timeContentsImages: string().nullable().optional(),
	timeContentsSheets: string().nullable().optional(),
	timeContentsTitles: string().nullable().optional(),
	timeRefactor: string().nullable().optional(),
	countryCode: string().nullable().optional(),
	categories: string().nullable().optional(),
	categoriesExclude: string().nullable().optional(),
	manufacturer: string().nullable().optional(),
	url: string().nullable().optional(),
	activeOnly: boolean().optional(),
	setImages: boolean().optional(),
	setSheets: boolean().optional(),
	setStock: boolean().optional(),
	setPrices: boolean().optional(),
	onlyManufacturerSegmentation: boolean().optional(),
	setDescriptions: boolean().optional(),
	showBr: boolean().optional(),
	segmentarIngram: boolean().optional(),
	iva: number().nullable().optional(),
	utility: number().nullable().optional(),
	email: string().nullable().optional(),
	password: string().nullable().optional(),		
	status: string().nullable().optional(),
}); */

export let CrudUser = ({ usersSuper, user, name, data, profile, role, props, categories, manufacturer }: CrudItemProps) => {
	
	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: `${item?.name}`,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
					count: item?.count || null,
				};
			}) || []
		);
	};

	const mapManufacturer = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: `${item?.name.split(' ')[0].toUpperCase()}`,
					title: `${item?.name}`,
					id: item?.name,
					header: !item?.parentName,
					count: item?.count || null
				};
			}) ||
			/* .filter((element: any) => {
					const isDuplicate = uniqueIds.includes(element.id);
					if (!isDuplicate) {
						uniqueIds.push(element.id);
						return true;
					}
					return false;
				}) */ []
		);
	};

	const { users, length, errors, loading, productsCount, productsCountCustomer, productsSegment, productsSegmentCount } = useSelector((state: ApplicationState) => state.user);
	const dispatch = useDispatch<any>();

	const [errorRules, setErrorRules] = React.useState(false);
	const [errorRulesNum, setErrorRulesNum] = React.useState(false);

	const [errorSegmentCategories, setErrorSegmentCategories] = React.useState({status:false,message:''});
	const [errorSegmentManufacturer, setErrorSegmentManufacturer] = React.useState({ status: false, message: '' });

	const [open, setOpen] = React.useState(false);
	const [initCat, setInitCat] = React.useState(false);
	const [input, setInput] = React.useState({
		showPassword: false,
	});

	const [secretOrder, setSecretOrder] = React.useState(false);

	const [openDialogProducts, setOpenDialogProductsState] = React.useState<any>({ status: false, userId: null, segment:null});
	

	const [tokenContents, setTokenContents] = React.useState(false);
	const [statusCrudUser, setStatusCrudUser] = React.useState<any>({});
	const [statusCron, setStatusCron] = React.useState<any>({});	

	const [filters, setFilters] = React.useState<any>({
		/* categories: data?.segmentarIngram
			? data?.categoriesIngramSegmentation
				? mapCategories(data?.categoriesIngramSegmentation.map((item: any) => item.categories))
				: []
			: data?.categoriesSegmentation
			? mapCategories(data?.categoriesSegmentation.map((item: any) => item.categories))
			: [], */
		categories: [],
		categoriesExclude: [],
	});

	const [filtersM, setFiltersM] = React.useState<any>({
		/* manufacturer: data?.manufacturerSegmentation
			? mapManufacturer(data?.manufacturerSegmentation.map((item: any) => item.manufacturer))
			: [], */
		manufacturer: [],
	});

	const [dataRules, setDataRules] = React.useState<any>({
		rulesPricesIva: [],
		rulesPricesUtility: [],
	});

	const defaultRules: any = {
		manufacturerId: null,
		categoriesIngramId: null,
		customerId: data?.id || 0,
		type: 'IVA',
		key: 'MINOREQUAL',
		keyWord: null,
		value: null,
		valueApply: null,
		priority: null,
	};

	const [valueRule, setValueRule] = React.useState<any>(defaultRules);

	//const [checkedCat, setCheckedCat] = React.useState(data?.segmentarIngram||false);	
	
	useEffect(() => {
		setStatusCrudUser({
			marketPlacesNotSuper: data?.typeApp?.includes('MARKETPLACE')&&!user?.super,
			userSuper: user?.super,
			profileCustomer: profile == 'customer',
			profileCustomerRolServiceUserSuper: profile == 'customer' && role == 'SERVICE' && user?.super,
			profileCustomerRolService: profile == 'customer' && role == 'SERVICE',
			profileServiceRoleServiceUserSuper: profile == 'service' && role == 'SERVICE' && user?.super,
		});
	}, [user,profile,role,data?.typeApp]);

	useEffect(() => {		

		setFilters({
			//...filters,
			categories: data?.segmentarIngram
				? data?.categoriesIngramSegmentation
					? mapCategories(data?.categoriesIngramSegmentation.map((item: any) => item.categories))
					: []
				: data?.categoriesSegmentation
				? mapCategories(data?.categoriesSegmentation.map((item: any) => item.categories))
				: [],
			categoriesExclude: data?.segmentarIngram
				? data?.categoriesExcludeIngramSegmentation
					? mapCategories(data?.categoriesExcludeIngramSegmentation.map((item: any) => item.categories))
					: []
				: data?.categoriesExcludeSegmentation
				? mapCategories(data?.categoriesExcludeSegmentation.map((item: any) => item.categories))
				: [],
		});

		setFiltersM({
			//...filtersM,
			manufacturer: data?.manufacturerSegmentation
				? mapManufacturer(data?.manufacturerSegmentation.map((item: any) => item.manufacturer))
				: [],
		});

		setDataRules({
			//...dataRules,
			rulesPricesIva: data?.rulesPricesIva ? data?.rulesPricesIva : [],
			rulesPricesUtility: data?.rulesPricesUtility ? data?.rulesPricesUtility : [],
		});

		setStatusCron({
			urlApiApp: (data?.urlApiApp ? ((data?.urlApiApp.length <= 0) ? false : false) : false),
			idApiApp: (data?.idApiApp ? ((data?.idApiApp.length <= 0) ? false : false) : false),
			secretApiApp: (data?.secretApiApp ? ((data?.secretApiApp.length <= 0) ? false : false) : false),
			timePricesCron: (data?.timePricesCron ? ((data?.timePricesCron.length <= 0) ? false : false) : false),
			timePricesShopOnlyCron: (data?.timePricesShopOnlyCron ? ((data?.timePricesShopOnlyCron.length <= 0) ? false : false) : false),
			timeProductsCron: (data?.timeProductsCron ? ((data?.timeProductsCron.length <= 0) ? false : false) : false),
			timeProductsShopOnlyCron: (data?.timeProductsShopOnlyCron ? ((data?.timeProductsShopOnlyCron.length <= 0) ? false : false) : false),
			timeContentsImages: (data?.timeContentsImages ? ((data?.timeContentsImages.length <= 0) ? false : false) : false),
			timeContentsSheets: (data?.timeContentsSheets ? ((data?.timeContentsSheets.length <= 0) ? false : false) : false),
			timeContentsTitles: (data?.timeContentsTitles ? ((data?.timeContentsTitles.length <= 0) ? false : false) : false),
			timeRefactor: (data?.timeRefactor ? ((data?.timeRefactor.length <= 0) ? false : false) : false)
		});

	}, [data]);

		
	
	const getProductsCount: any = (filtersManufacturer:any,filtersCategories:any,init:boolean) => {
		const categoriesAll: any = categories.datasearch?.map((k: any) => k.name) || [];
		const manufacturerAll: any = manufacturer.datasearch?.map((k: any) => k.name) || [];
		
		const categoriesSeg: any = Array.from(new Set(filtersCategories.filter((item: any) => categoriesAll.includes(item.title)).map((i: any) => i.title)));		
		const manufacturerSeg: any = Array.from(new Set(filtersManufacturer.filter((item: any) => manufacturerAll.includes(item.title)).map((i: any) => i.title)));

		props?.getProductsCount({ userId: data?.id, manufacturerSeg, categoriesSeg, idSegmentarIngram: data?.id, segmentarIngram: data?.segmentarIngram, init:init });

		/* 
			console.log(manufacturerSeg);
			console.log(categoriesSeg); 
		*/
	}

	// 👇 Infer the Schema to get the TS Type

	const userSchema = object({
		id: data ? number() : number().nullable().optional(),
		parentId: number().nullable().optional(),
		name:
			data?.name || data?.name == null || !data
				? string().min(5, 'Nombre minimo 5 caracteres')
				: string().nullable().optional(),
		username:
			data?.username || data?.username == null || !data
				? string().min(5, 'Nombre de usuario minimo 5 caracteres')
				: string().nullable().optional(),
		countryCode:
			role == 'SERVICE' && (data?.countryCode || data?.countryCode == null || !data)
				? string().min(1, 'Status es requerido')
				: string().nullable().optional(),
		iva:
			profile == 'customer'
				? number().lte(100, 'Menor de 101').gte(0, 'Mayor igual a 0')
				: number().nullable().optional(),
		utility:
			profile == 'customer'
				? number().lte(100, 'Menor de 101').gte(0, 'Mayor igual a 0')
				: number().nullable().optional(),
		maxProductsSegment:
			profile == 'customer' && user?.super ? number().gte(0, 'Mayor igual a 0') : number().nullable().optional(),
		maxPricesShop:
			profile == 'customer' ? number().gte(0, 'Mayor igual a 0') : number().nullable().optional(),
		email:
			data?.email || data?.email == null || !data
				? string().min(1, 'Email es requerido').email('Email es invalido')
				: string().nullable().optional(),
		password: data
			? string().nullable().optional()
			: string()
					.min(1, 'Password es requerido')
					.min(4, 'Password minimo 4 caracteres')
					.max(32, 'Password maximo 32 caracteres'),
		status:
			role == 'SERVICE' && (data?.status || data?.status == null || !data) && user?.super
				? string().min(1, 'Status es requerido')
				: string().nullable().optional(),
		clientId: string().nullable().optional(),
		clientSecret: string().nullable().optional(),
		customerNumber: string().nullable().optional(),
		senderId: string().nullable().optional(),
		userBD: string().nullable().optional(),
		passBD: string().nullable().optional(),
		nameBD: string().nullable().optional(),
		hostEmail: string().nullable().optional(),
		brsExcludes: string().nullable().optional(),
		userEmail: string().nullable().optional(),
		passEmail: string().nullable().optional(),
		paramSpecial: string().nullable().optional(),
		skusSegmentation: string().nullable().optional(),
		toEmail: string().nullable().optional(),
		userAdminApp: string().nullable().optional(),
		passAdminApp: string().nullable().optional(),
		urlApiApp: string().nullable().optional(),
		idApiApp: string().nullable().optional(),
		secretApiApp: string().nullable().optional(),
		typeApp: string().nullable().optional(),
		timeProductsCron: string().nullable().optional(),
		timePricesCron: string().nullable().optional(),
		timePricesShopOnlyCron: string().nullable().optional(),
		timeProductsShopOnlyCron: string().nullable().optional(),
		timeContentsImages: string().nullable().optional(),
		timeContentsSheets: string().nullable().optional(),
		secretAppOrder: string().nullable().optional(),
		tokenAppContents: string().nullable().optional(),
		timeContentsTitles: string().nullable().optional(),
		timeRefactor: string().nullable().optional(),
		categories: string().nullable().optional(),
		categoriesExclude: string().nullable().optional(),
		manufacturer: string().nullable().optional(),
		url: string().nullable().optional(),
		activeOnly: boolean().optional(),
		setImages: boolean().optional(),
		setSheets: boolean().optional(),
		setStock: boolean().optional(),
		setPrices: boolean().optional(),
		onlyManufacturerSegmentation: boolean().optional(),
		deleteOutStock: boolean().optional(),
		setDescriptions: boolean().optional(),
		setRecategorizeDescription: boolean().optional(),
		setRecategorized: boolean().optional(),
		showBr: boolean().optional(),
		segmentarIngram: boolean().optional(),
	});

	type IUser = TypeOf<typeof userSchema>;

	const defaultValues: IUser = data || {
		id: 0,
		parentId: null,
		name: '',
		clientId: '',
		clientSecret: '',
		customerNumber: '',
		countryCode: null,
		senderId: '',
		userBD: '',
		passBD: '',
		nameBD: '',
		hostEmail: '',
		userEmail: '',
		passEmail: '',
		skusSegmentation: '',
		showBr: false,
		brsExcludes: '',
		secretAppOrder: '',
		tokenAppContents: '',
		toEmail: '',
		userAdminApp: '',
		passAdminApp: '',
		urlApiApp: '',
		idApiApp: '',
		secretApiApp: '',
		typeApp: null,
		paramSpecial: '',
		timeProductsCron: '',
		timePricesCron: '',
		timePricesShopOnlyCron: '',
		timeProductsShopOnlyCron: '',
		timeContentsImages: '',
		timeContentsSheets: '',
		timeContentsTitles: '',
		timeRefactor: '',
		username: '',
		password: '',
		url: '',
		iva: 0,
		utility: 0,
		maxProductsSegment: 0,
		maxPricesShop: 0,
		status: null,
		email: '',
		segmentarIngram: false,
		setDescriptions: false,
		activeOnly: false,
		setImages: false,
		setSheets: false,
		setStock: false,
		setPrices: false,
		onlyManufacturerSegmentation: false,
		deleteOutStock: false,
		//categoriesSegmentation: [],
		//categoriesIngramSegmentation: [],
		//categoriesExcludeSegmentation: [],
		//categoriesExcludeIngramSegmentation: [],
		//manufacturerSegmentation: [],
		//rulesPricesIva: [],
		//rulesPricesUtility: [],
	};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IUser>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});	
	
	useEffect(() => {
		if (errors?.code) {
			methods.setError(errors?.message?.field, { type: 'custom', message: errors?.message?.message });
		} else {
			handleClose();
		}
		
	}, [errors, methods]);		

	useEffect(() => {
		
		setErrorSegmentCategories({
			status: filters?.categories <= 0 && filtersM?.manufacturer <= 0 && methods.watch('typeApp') === 'WOOCOMMERCE',
			message: filters?.categories <= 0 && filtersM?.manufacturer <= 0 && methods.watch('typeApp') === 'WOOCOMMERCE' ? 'Empty, Seleccione segmentación' : '',
		});
		setErrorSegmentManufacturer({
			status: filtersM?.manufacturer <= 0 && filters?.categories <= 0 && methods.watch('typeApp') === 'WOOCOMMERCE',
			message: filtersM?.manufacturer <= 0 && filters?.categories <= 0 && methods.watch('typeApp') === 'WOOCOMMERCE' ? 'Empty, Seleccione segmentación' : '',
		});

	}, [methods, filters?.categories, filtersM?.manufacturer]);

	/* useEffect(() => {
		if (openDialogProducts?.userId && openDialogProducts?.segment) {
			props?.getProductsSegment(openDialogProducts);
		}
	}, [openDialogProducts]); */

	const setOpenDialogProducts: any = (values: any) => {
		setOpenDialogProductsState(values);
		if (values?.userId && values?.segment) {
			props?.getProductsSegment(values);
		}
	};

	const handleClickShowPassword = () => {
		setInput({
			...input,
			showPassword: !input.showPassword,
		});
	};

	const handleClickSecretOrders = () => {
		setSecretOrder(!secretOrder);
	};

	const handleClickTokenContents = () => {
		setTokenContents(!tokenContents);
	};

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleMouseDownSecretOrder = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleMouseDownTokenContents = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
	};

	const handleClickOpen = async () => {
		
		if (role == 'CUSTOMER' || profile == 'customer') {
			
			dispatch(
				getCategoriesSearch({
					idSegmentarIngram: data?.id,
					country: data?.countryCode,
					search: null,
				})
			);
			
			dispatch(
				getManufacturer({
					country: data?.countryCode,
					search: null,
				})
			);

			getProductsCount(
				data?.manufacturerSegmentation
					? mapManufacturer(data?.manufacturerSegmentation.map((item: any) => item.manufacturer))
					: [],
				data?.segmentarIngram
					? data?.categoriesIngramSegmentation
						? mapCategories(data?.categoriesIngramSegmentation.map((item: any) => item.categories))
						: []
					: data?.categoriesSegmentation
					? mapCategories(data?.categoriesSegmentation.map((item: any) => item.categories))
						: []
				,true
			);	
			
		}

		methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		//if (!errors?.code) {
		setOpen(false);
		//}
	};

	const handleAddRule = () => {

		let valueRuleNew: any = [];
		if (valueRule.type == 'IVA') {

			let maxId: any = 0;
			if (dataRules.rulesPricesIva.length > 0) { maxId = Math.max(...dataRules.rulesPricesIva.map((n: any) => n.id)); }

			if ( !valueRule?.manufacturerId && !valueRule?.categoriesIngramId ) {
				setErrorRules(true);
				return;
			} else {
				setErrorRules(false);
			}

			if ((!Number(valueRule.value)&&valueRule.value!='0') || (valueRule.valueApply!='0'&&!Number(valueRule.valueApply))) {		
				
					setErrorRulesNum(true);
					return;
			} else {
				setErrorRulesNum(false);
			}
			
			valueRuleNew = [
				{
					id: valueRule?.edit ? valueRule.id : maxId + 1,
					manufacturerId: valueRule?.manufacturerId?.title || valueRule?.manufacturerId||null,
					categoriesIngramId: valueRule?.categoriesIngramId?.title || valueRule?.categoriesIngramId||null,
					customerId: valueRule.customerId,
					type: valueRule.key=='UTILITY'?'UTILITY':valueRule.type,
					key: valueRule.type=='UTILITY'?'UTILITY':valueRule.key,
					keyWord: valueRule.keyWord,
					value: valueRule.value,
					valueApply: valueRule.valueApply,
					priority: valueRule.priority,
				},
			];

			setDataRules({
				...dataRules,
				rulesPricesIva: valueRule?.edit
					? dataRules.rulesPricesIva.map((r: any) => (r.id !== valueRule.id)?r:valueRuleNew[0])
					: [...dataRules.rulesPricesIva, ...valueRuleNew],
			});

		}

		valueRuleNew = [];
		if (valueRule.type == 'UTILITY') {	
			
			let maxId: any = 0;
			if (dataRules.rulesPricesUtility.length > 0) { maxId = Math.max(...dataRules.rulesPricesUtility.map((n: any) => n.id)); }

			if (!valueRule?.manufacturerId && !valueRule?.categoriesIngramId) {
				setErrorRules(true);
				return;
			} else {
				setErrorRules(false);
			}

			if (!Number(valueRule.valueApply)&&valueRule.valueApply!='0') {
				setErrorRulesNum(true);
				return;
			} else {
				setErrorRulesNum(false);
			}

			valueRuleNew = [
				{
					id: valueRule?.edit ? valueRule.id : maxId + 1,
					manufacturerId: valueRule?.manufacturerId?.title || valueRule?.manufacturerId||null,
					categoriesIngramId: valueRule?.categoriesIngramId?.title || valueRule?.categoriesIngramId||null,
					customerId: valueRule.customerId,
					type: valueRule.key == 'UTILITY' ? 'UTILITY' : valueRule.type,
					key: valueRule.type == 'UTILITY' ? 'UTILITY' : valueRule.key,
					keyWord: valueRule.keyWord,
					value: valueRule.value,
					valueApply: valueRule.valueApply,
					priority: valueRule.priority,
				},
			];			

			setDataRules({
				...dataRules,
				rulesPricesUtility: valueRule?.edit
					? dataRules.rulesPricesUtility.map((r: any) => (r.id !== valueRule.id)?r:valueRuleNew[0])
					: [...dataRules.rulesPricesUtility, ...valueRuleNew]
			});

		}

		resetRules();
		setErrorRules(false);
		setErrorRulesNum(false);
	};

	const onSubmitHandler: SubmitHandler<IUser> = (values: IUser) => {
		if (!errorSegmentCategories.status || !errorSegmentManufacturer.status) {
			values.categories = filters.categories.map((item: any) => item.title);
			values.categoriesExclude = filters.categoriesExclude.map((item: any) => item.title);

			const categoriesAll: any = categories.datasearch?.map((k: any) => k.name) || [];
			const manufacturerAll: any = manufacturer.datasearch?.map((k: any) => k.name) || [];
			
			const categoriesSeg: any = Array.from(new Set(filters.categories.filter((item: any) => categoriesAll.includes(item.title)).map((i: any) => i.title)));
			const categoriesExcludeSeg: any = Array.from(new Set(filters.categoriesExclude.filter((item: any) => categoriesAll.includes(item.title)).map((i: any) => i.title)));
			
			const manufacturerSeg: any = Array.from(new Set(filtersM.manufacturer.filter((item: any) => manufacturerAll.includes(item.title)).map((i: any) => i.title)));
			
			values.manufacturer = manufacturerSeg;
			values.categories = categoriesSeg;
			values.categoriesExclude = categoriesExcludeSeg;

		
			props?.getValues({ ...values, ...dataRules, ...{ secretOrder: secretOrder }, ...{ tokenContents: tokenContents } });
			setSecretOrder(false);
		}
	};

	const handleChangeCat = (event?: boolean) => {

		setInitCat(true);
		/* console.log(event);
		if (event) { */
		dispatch(
			getCategoriesSearch({
				country: data?.countryCode,
				search: null,
				ingram: event,
			})
		);
		/* } */
		setFilters({
			...filters,
			categories:
				data?.segmentarIngram == event
					? data?.segmentarIngram
						? data?.categoriesIngramSegmentation
							? mapCategories(data?.categoriesIngramSegmentation.map((item: any) => item.categories))
							: []
						: data?.categoriesSegmentation
						? mapCategories(data?.categoriesSegmentation.map((item: any) => item.categories))
						: []
					: [],
		});

	};

	const resetRules = () => {
		setValueRule(defaultRules);
	};

	async function serachData({ id, value }: searchProps) {
		setFilters({ ...filters, [id]: value });
		if (role == 'CUSTOMER' || profile == 'customer') {
			getProductsCount(filtersM.manufacturer, value,false);
		}
	}

	async function serachDataM({ id, value }: searchProps) {
		setFiltersM({ ...filtersM, [id]: value });
		if (role == 'CUSTOMER' || profile == 'customer') {
			getProductsCount(value, filters.categories,false);
		}
	}

	function serachDataRule({ id, value }: searchProps) {
		setValueRule({ ...valueRule, [id]: value });	
		setErrorRules(false);
		setErrorRulesNum(false);
	}

	function deleteRuleData(value: any, type: string) {
		if (type == 'iva') {
			setDataRules({
				...dataRules,
				rulesPricesIva: dataRules.rulesPricesIva?.filter((item: any) => item.id != value.id),
			});
		}
		if (type == 'utility') {
			setDataRules({
				...dataRules,
				rulesPricesUtility: dataRules.rulesPricesUtility?.filter((item: any) => item.id != value.id),
			});
		}
	}

	function editRuleData(value: any, type: string) {
		if (type == 'iva') {
			value['edit'] = true;
			setValueRule(value);
			/* setDataRules({
				...dataRules,
				rulesPricesIva: dataRules.rulesPricesIva?.filter((item: RulesPricesIva) => item.id != value.id),
			}); */
		}
		if (type == 'utility') {
			value['edit'] = true;
			setValueRule(value);
			/* setDataRules({
				...dataRules,
				rulesPricesUtility: dataRules.rulesPricesUtility?.filter((item: RulesPricesIva) => item.id != value.id),
			}); */
		}
	}

	function saveDataSandBox(value: any, type: string) {
		props?.getValuesSandBox(value);
	}

	function deleteDataSandBox(value: any, type: string) {		
		props?.getValuesSandBox(value);
	}

	function sendData(values: any) {
		
	}
	function setTableSelectSegment(values: any) {

		if (values.type === 'manufacturer') {
			const listManufacturer: any = mapManufacturer(manufacturer.datasearch);
			const listManufacturerCurrent: any = filtersM.manufacturer.map((m: any) => m.title);
			serachDataM({
				id: values.type,
				value: listManufacturerCurrent.includes(values.values) ? filtersM.manufacturer.filter((m: any) => m.title !== values.values) : filterDuplicate([...filtersM.manufacturer, ...listManufacturer.filter((m: any) => m.title == values.values)])
			});
		}

		if (values.type === 'categories') {
			const listCategories: any = mapCategories(data?.segmentarIngram && !initCat ? categories.dataIngram : categories.datasearch);
			const listCategoriesCurrent: any = filters.categories.map((c: any) => c.title);
			serachData({
				id: values.type,
				value: listCategoriesCurrent.includes(values.values) ? filters.categories.filter((c: any) => c.title !== values.values) : filterDuplicate([...filters.categories, ...listCategories.filter((c: any) => c.title == values.values)]) 
			});
		}

	}

	const filterDuplicate = (array: any) => {
		let hash: any = {};
		array = array.filter((o: any) => (hash[o.title] ? false : (hash[o.title] = true)));
		return array;
	};

	return (
		<div>
			<Button variant='outlined' onClick={handleClickOpen}>
				{name}
			</Button>

			<Dialog
				open={open}
				maxWidth={'sm'}
				sx={{
					zIndex: (theme) => theme.zIndex.drawer + 1,
				}}
			>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							<ProductsSegment
								props={{
									getValues: (values: any) => sendData(values),
									getOpenDialog: (values: any) =>
										setOpenDialogProducts({ status: values.status, userId: null, segment: null }),
									getSelectSegment: (values: any) => setTableSelectSegment(values),
									getSelectSegmentPagination: (values: any) => setOpenDialogProducts(values),
								}}
								name={`Productos a segmentar`}
								openDialog={openDialogProducts.status}
								openDialogProducts={openDialogProducts}
								productsSegment={productsSegment}
								productsSegmentCount={productsSegmentCount}
								categories={filters?.categories.map((c: any) => c.title) || []}
								manufacturer={filtersM?.manufacturer.map((m: any) => m.title) || []}
							/>
							{(data?.id || !data) && (
								<TextField
									disabled
									margin='dense'
									id='id'
									label='ID de usuario'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('id')}
									error={methods.formState.errors.id ? true : false}
									helperText={methods.formState.errors.id?.message}
								/>
							)}
							{(data?.parentId || !data) && (
								<TextField
									margin='dense'
									id='parentId'
									label='ID de usuario Padre'
									type='text'
									fullWidth
									variant='standard'
									//{...methods.register('parentId')}
									//error={methods.formState.errors.parentId ? true : false}
									//helperText={methods.formState.errors.parentId?.message}
								/>
							)}
							{(data?.name || !data) && (
								<TextField
									margin='dense'
									id='name'
									label='Nombre'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('name')}
									error={methods.formState.errors.name ? true : false}
									helperText={methods.formState.errors.name?.message}
								/>
							)}
							{(data?.username || !data) && (
								<TextField
									margin='dense'
									id='username'
									label='Nombre de usuario'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('username')}
									error={methods.formState.errors.username ? true : false}
									helperText={methods.formState.errors.username?.message}
								/>
							)}
							{(data?.email || !data) && (
								<TextField
									/* onKeyPress={(ev) => {
										console.log(`Pressed keyCode ${ev.key}`);
										console.log(methods.getFieldState('email'));
										if (ev.key === 'Enter') {
											// Do code here
											ev.preventDefault();
										}
									}} */
									margin='dense'
									id='email'
									label='Correo'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('email')}
									error={methods.formState.errors.email ? true : false}
									helperText={methods.formState.errors.email?.message}
								/>
							)}
							{(data?.url || !data) && (
								<TextField
									margin='dense'
									id='url'
									label='Url'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('url')}
									error={methods.formState.errors.url ? true : false}
									helperText={methods.formState.errors.url?.message}
								/>
							)}

							<FormControl
								fullWidth
								sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
								error={!data && methods.formState.errors?.password ? true : false}
							>
								<InputLabel id='password'>Contraseña</InputLabel>
								<OutlinedInput
									id='filled-adornment-password'
									type={input.showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge='end'
											>
												{input.showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									}
									{...methods.register('password')}
								/>
								<FormHelperText>{!data && methods.formState.errors?.password?.message}</FormHelperText>
							</FormControl>

							{((data?.status && role == 'SERVICE') || !data) && (
								<FormControl
									sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
									size='small'
									error={methods.formState.errors.status ? true : false}
								>
									<InputLabel id='state'>Status</InputLabel>
									<Select
										disabled={!user?.super}
										defaultValue={data?.status || 'INACTIVE'}
										labelId='status'
										id='status'
										label='Estado'
										{...methods.register('status')}
									>
										<MenuItem value=''>
											<em>Seleccionar....</em>
										</MenuItem>
										<MenuItem value='ACTIVE'>Activo</MenuItem>
										<MenuItem value='INACTIVE'>Dasactivado</MenuItem>
										<MenuItem value='BLOCKED'>Bloqueado</MenuItem>
										<MenuItem value='DELETE'>Borrado</MenuItem>
									</Select>
									<FormHelperText>{methods.formState.errors?.status?.message}</FormHelperText>
								</FormControl>
							)}

							{statusCrudUser.profileCustomerRolService && (
								<TextField
									margin='dense'
									id='client_id'
									label='ID Cliente "client_id" Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('clientId')}
									error={methods.formState.errors.clientId ? true : false}
									helperText={methods.formState.errors.clientId?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolService && (
								<TextField
									margin='dense'
									id='client_secret'
									label='Clave Secreta "client_secret" Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('clientSecret')}
									error={methods.formState.errors.clientSecret ? true : false}
									helperText={methods.formState.errors.clientSecret?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolService && (
								<TextField
									margin='dense'
									id='IM-CustomerNumber'
									label='"IM-CustomerNumber" Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('customerNumber')}
									error={methods.formState.errors.customerNumber ? true : false}
									helperText={methods.formState.errors.customerNumber?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolService && (
								<TextField
									margin='dense'
									id='IM-SenderID'
									label='Nombre "IM-SenderID" de Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('senderId')}
									error={methods.formState.errors.senderId ? true : false}
									helperText={methods.formState.errors.senderId?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolService && (
								<TextField
									margin='dense'
									id='brsExcludes'
									label='Almacenes a excluir'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('brsExcludes')}
								/>
							)}
							{role == 'SERVICE' && (data?.countryCode || data?.countryCode == null || !data) && (
								<FormControl
									sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
									size='small'
									error={methods.formState.errors.countryCode ? true : false}
								>
									<InputLabel id='countryCode'>Codigo Ciudad</InputLabel>
									<Select
										defaultValue={data?.countryCode || ''}
										disabled={!user?.super}
										labelId='countryCode'
										id='countryCode'
										label='Codigo Ciudad'
										{...methods.register('countryCode')}
									>
										<MenuItem value=''>
											<em>Seleccionar....</em>
										</MenuItem>
										<MenuItem value='US'>Miami</MenuItem>
										<MenuItem value='MX'>México</MenuItem>
										<MenuItem value='CO'>Colombia</MenuItem>
										<MenuItem value='CA'>Canada</MenuItem>
										<MenuItem value='PE'>Péru</MenuItem>
									</Select>
									<FormHelperText>{methods.formState.errors?.countryCode?.message}</FormHelperText>
								</FormControl>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='userBD'
									label='Usuario BD para Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('userBD')}
									error={methods.formState.errors.userBD ? true : false}
									helperText={methods.formState.errors.userBD?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='passBD'
									label='Contraseña BD para Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('passBD')}
									error={methods.formState.errors.passBD ? true : false}
									helperText={methods.formState.errors.passBD?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='nameBD'
									label='Nombre BD para Cliente Ingram'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('nameBD')}
									error={methods.formState.errors.nameBD ? true : false}
									helperText={methods.formState.errors.nameBD?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='hostEmail'
									label='HOST SMTP Correo Electronico'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('hostEmail')}
									error={methods.formState.errors.hostEmail ? true : false}
									helperText={methods.formState.errors.hostEmail?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='userEmail'
									label='Usuario SMTP Correo Electronico'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('userEmail')}
									error={methods.formState.errors.userEmail ? true : false}
									helperText={methods.formState.errors.userEmail?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='passEmail'
									label='Contraseña SMTP Correo Electronico'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('passEmail')}
									error={methods.formState.errors.passEmail ? true : false}
									helperText={methods.formState.errors.passEmail?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<TextField
									margin='dense'
									id='toEmail'
									label='Receptores Email separados por coma'
									type='text'
									fullWidth
									variant='standard'
									{...methods.register('toEmail')}
									error={methods.formState.errors.toEmail ? true : false}
									helperText={methods.formState.errors.toEmail?.message}
								/>
							)}
							{statusCrudUser.profileCustomerRolService && (
								<FormControl
									sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
									size='small'
									error={methods.formState.errors.typeApp ? true : false}
								>
									<InputLabel id='typeApp'>Tipo de Aplicativo</InputLabel>
									<Select
										defaultValue={data?.typeApp || ''}
										disabled={!user?.super}
										labelId='typeApp'
										id='typeApp'
										label='Tipo de Aplicativo'
										{...methods.register('typeApp')}
									>
										<MenuItem value=''>
											<em>Seleccionar....</em>
										</MenuItem>
										<MenuItem value='WOOCOMMERCE'>WOOCOMMERCE</MenuItem>
										<MenuItem value='OPENCART'>OPENCART</MenuItem>
										<MenuItem value='SHOPIFY'>SHOPIFY</MenuItem>
										<MenuItem value='INTEGRATION'>INTEGRATION</MenuItem>
										<MenuItem value='MARKETPLACEAMAZON'>MARKETPLACEAMAZON</MenuItem>
										<MenuItem value='MARKETPLACEMELI'>MARKETPLACEMELI</MenuItem>
									</Select>
									<FormHelperText>{methods.formState.errors?.typeApp?.message}</FormHelperText>
								</FormControl>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
									<InputLabel id='generateSevretApp'>Secreto Ordenes</InputLabel>
									<OutlinedInput
										id='secretAppOrder'
										type={'text'}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle visibility'
													onClick={handleClickSecretOrders}
													onMouseDown={handleMouseDownSecretOrder}
													edge='end'
												>
													{!secretOrder ? <VpnKeyOffIcon /> : <VpnKeyIcon />}
												</IconButton>
											</InputAdornment>
										}
										{...methods.register('secretAppOrder')}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
									<InputLabel id='generateTokenContentsApp'>Token Image</InputLabel>
									<OutlinedInput
										id='tokenAppContents'
										type={'text'}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													aria-label='toggle visibility'
													onClick={handleClickTokenContents}
													onMouseDown={handleMouseDownTokenContents}
													edge='end'
												>
													{!tokenContents ? <VpnKeyOffIcon /> : <VpnKeyIcon />}
												</IconButton>
											</InputAdornment>
										}
										{...methods.register('tokenAppContents')}
									/>
								</FormControl>
							)}
							{(statusCrudUser.profileCustomerRolService && statusCron.urlApiApp) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='userAdminApp'
											label='Usuario Admin App'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('userAdminApp')}
											error={methods.formState.errors.userAdminApp ? true : false}
											helperText={methods.formState.errors.userAdminApp?.message}
										/>
										<TextField
											margin='dense'
											id='passAdminApp'
											label='Pass Admin App'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('passAdminApp')}
											error={methods.formState.errors.passAdminApp ? true : false}
											helperText={methods.formState.errors.passAdminApp?.message}
										/>
									</>
								))}

							{(statusCrudUser.profileCustomerRolService && statusCron.urlApiApp) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<TextField
										margin='dense'
										id='urlApiApp'
										label='URL Api Aplicativo'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('urlApiApp')}
										error={methods.formState.errors.urlApiApp ? true : false}
										helperText={methods.formState.errors.urlApiApp?.message}
									/>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.idApiApp) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<TextField
										margin='dense'
										id='idApiApp'
										label='ID Api Aplicativo'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('idApiApp')}
										error={methods.formState.errors.idApiApp ? true : false}
										helperText={methods.formState.errors.idApiApp?.message}
									/>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.secretApiApp) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<TextField
										margin='dense'
										id='secretApiApp'
										label='Secret Api Aplicativo'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('secretApiApp')}
										error={methods.formState.errors.secretApiApp ? true : false}
										helperText={methods.formState.errors.secretApiApp?.message}
									/>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timePricesCron) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timePricesCron'
											label='Configuración Precios tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timePricesCron')}
											error={methods.formState.errors.timePricesCron ? true : false}
											helperText={methods.formState.errors.timePricesCron?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 30 7,12,18 * * 0,1,2,3,4,5,6`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timePricesShopOnlyCron) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timePricesShopOnlyCron'
											label='Configuración Tienda Precios tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timePricesShopOnlyCron')}
											error={methods.formState.errors.timePricesShopOnlyCron ? true : false}
											helperText={methods.formState.errors.timePricesShopOnlyCron?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 30 7,12,18 * * 0,1,2,3,4,5,6`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeProductsCron) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timeProductsCron'
											label='Configuración Productos tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timeProductsCron')}
											error={methods.formState.errors.timeProductsCron ? true : false}
											helperText={methods.formState.errors.timeProductsCron?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 25 7,17 * * 1,2,3,4,5`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeProductsShopOnlyCron) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timeProductsShopOnlyCron'
											label='Configuración Tienda Productos tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timeProductsShopOnlyCron')}
											error={methods.formState.errors.timeProductsShopOnlyCron ? true : false}
											helperText={methods.formState.errors.timeProductsShopOnlyCron?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 25 7,17 * * 1,2,3,4,5`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeContentsImages) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timeContentsImages'
											label='Configuración Imagenes tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timeContentsImages')}
											error={methods.formState.errors.timeContentsImages ? true : false}
											helperText={methods.formState.errors.timeContentsImages?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 25 7,17 * * 1,2,3,4,5`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeContentsSheets) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<>
										<TextField
											margin='dense'
											id='timeContentsSheets'
											label='Configuración Ficha Téc. tiempo CRON'
											type='text'
											fullWidth
											variant='standard'
											{...methods.register('timeContentsSheets')}
											error={methods.formState.errors.timeContentsSheets ? true : false}
											helperText={methods.formState.errors.timeContentsSheets?.message}
										/>
										<label style={{ color: 'grey' }}>{`0 30 8,18 * * 1,2,3,4,5`}</label>
									</>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeContentsTitles) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<TextField
										margin='dense'
										id='timeContentsTitles'
										label='Configuración Titulos tiempo CRON'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('timeContentsTitles')}
										error={methods.formState.errors.timeContentsTitles ? true : false}
										helperText={methods.formState.errors.timeContentsTitles?.message}
									/>
								))}
							{(statusCrudUser.profileCustomerRolService && statusCron.timeRefactor) ||
								(statusCrudUser.profileCustomerRolServiceUserSuper && (
									<TextField
										margin='dense'
										id='timeRefactor'
										label='Configuración Refactor tiempo CRON'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('timeRefactor')}
										error={methods.formState.errors.timeRefactor ? true : false}
										helperText={methods.formState.errors.timeRefactor?.message}
									/>
								))}
							{statusCrudUser.profileCustomer && (
								<TextField
									margin='dense'
									id='iva'
									label='IVA'
									type='number'
									fullWidth
									variant='standard'
									{...methods.register('iva', { setValueAs: (v: any) => (v === '' ? undefined : parseFloat(v)) })}
									error={methods.formState.errors.iva ? true : false}
									helperText={methods.formState.errors.iva?.message}
								/>
							)}

							{statusCrudUser.profileCustomer && (
								<TextField
									margin='dense'
									id='utility'
									label='Utilidad'
									type='number'
									fullWidth
									variant='standard'
									{...methods.register('utility', { setValueAs: (v: any) => (v === '' ? undefined : parseFloat(v)) })}
									error={methods.formState.errors.utility ? true : false}
									helperText={methods.formState.errors.utility?.message}
								/>
							)}

							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('segmentarIngram'));
											}}
											id='segmentarIngram'
											{...methods.register('segmentarIngram')}
											checked={methods.watch('segmentarIngram')}
										/>
										Categorias Ingram
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('activeOnly'));
											}}
											id='activeOnly'
											{...methods.register('activeOnly')}
											checked={methods.watch('activeOnly')}
										/>
										Solo activos
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setStock'));
											}}
											id='setStock'
											{...methods.register('setStock')}
											checked={methods.watch('setStock')}
										/>
										Enviar inventario
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setPrices'));
											}}
											id='setPrices'
											{...methods.register('setPrices')}
											checked={methods.watch('setPrices')}
										/>
										Enviar precios
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setImages'));
											}}
											id='setImages'
											{...methods.register('setImages')}
											checked={methods.watch('setImages')}
										/>
										Enviar imagenes
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setSheets'));
											}}
											id='setSheets'
											{...methods.register('setSheets')}
											checked={methods.watch('setSheets')}
										/>
										Enviar ficha tec.
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setDescriptions'));
											}}
											id='setDescriptions'
											{...methods.register('setDescriptions')}
											checked={methods.watch('setDescriptions')}
										/>
										Enviar descripciones
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('showBr'));
											}}
											id='showBr'
											{...methods.register('showBr')}
											checked={methods.watch('showBr')}
										/>
										Mostrar almacenes
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setRecategorizeDescription'));
											}}
											id='setRecategorizeDescription'
											{...methods.register('setRecategorizeDescription')}
											checked={methods.watch('setRecategorizeDescription')}
										/>
										Actualizar Titulos
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomerRolServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '33%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('setRecategorized'));
											}}
											id='setRecategorized'
											{...methods.register('setRecategorized')}
											checked={methods.watch('setRecategorized')}
										/>
										Actualizar Categorias
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='paramSpecial'
										label='Parametros Especiales'
										multiline
										maxRows={6}
										fullWidth
										variant='standard'
										{...methods.register('paramSpecial')}
									/>
									<label
										style={{ color: 'grey' }}
									>{`{"catalog_visibility":"visible","status":"publish","module":["insert","update"]}`}</label>
								</FormControl>
							)}

							{(statusCrudUser.profileCustomerRolService || statusCrudUser.profileCustomerRolServiceUserSuper) && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('onlyManufacturerSegmentation'));
											}}
											id='onlyManufacturerSegmentation'
											{...methods.register('onlyManufacturerSegmentation')}
											checked={methods.watch('onlyManufacturerSegmentation')}
										/>
										Solo Fabricantes Segmenatdos
									</div>
								</FormControl>
							)}
							{(statusCrudUser.profileCustomerRolService || statusCrudUser.profileCustomerRolServiceUserSuper) && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
									<div>
										<Checkbox
											//checked={checkedCat}
											//onClick={handleChangeCat2}
											onClick={() => {
												handleChangeCat(!methods.watch('deleteOutStock'));
											}}
											id='deleteOutStock'
											{...methods.register('deleteOutStock')}
											checked={methods.watch('deleteOutStock')}
										/>
										No borrar fuera de stock
									</div>
								</FormControl>
							)}

							{statusCrudUser.profileCustomer && statusCrudUser.userSuper && (
								<AutocompletCheckBoxGroup
									props={{
										group: true,
										data: mapCategories(
											data?.segmentarIngram && !initCat ? categories.dataIngram : categories.datasearch
										),
										value: filters?.categoriesExclude,
										maxWidth: 900,
										id: 'categoriesExclude',
										title: 'Categorias Exclude SEGMENTAR',
										onSearch: (search: searchProps) => serachData(search),
									}}
								/>
							)}

							{statusCrudUser.profileCustomer && statusCrudUser.userSuper && (
								<TextField
									margin='dense'
									id='maxProductsSegment'
									label='Max Productos Segmentar'
									type='number'
									fullWidth
									variant='standard'
									{...methods.register('maxProductsSegment', {
										setValueAs: (v: any) => (v === '' ? undefined : parseFloat(v)),
									})}
									error={methods.formState.errors.maxProductsSegment ? true : false}
									helperText={methods.formState.errors.maxProductsSegment?.message}
								/>
							)}

							{statusCrudUser.profileCustomer && (
								<>
									<TextField
										margin='dense'
										id='maxPricesShop'
										label='Max Precio a mostrar Tienda'
										type='number'
										fullWidth
										variant='standard'
										{...methods.register('maxPricesShop', {
											setValueAs: (v: any) => (v === '' ? undefined : parseFloat(v)),
										})}
										error={methods.formState.errors.maxPricesShop ? true : false}
										helperText={methods.formState.errors.maxPricesShop?.message}
									/>

									<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
										<label style={{ color: 'black', fontWeight: 'bold' }}>
											{`(Seleccionados Activos Actuales) Segmentados: ${productsCount} Ingram vs ${productsCountCustomer} Cliente`}
											{Number(methods.watch('maxProductsSegment')) > 0
												? ` de ${methods.watch('maxProductsSegment')} Autorizados`
												: ``}
										</label>
										<label style={{ color: 'black' }}>
											{`Los cambios en segmentación, se reflejan en un tiempo maximo de 24 horas para cuenta y tienda.`}
										</label>
									</FormControl>

									<AutocompletCheckBoxGroup
										props={{
											error: errorSegmentCategories.status,
											errorMessage: errorSegmentCategories.message,
											//disabled: productsCount > Number(methods.watch('maxProductsSegment')),
											statusProductsSegment: { productsCount, maxProductsSegment: methods.watch('maxProductsSegment') },
											group: true,
											data: mapCategories(
												data?.segmentarIngram && !initCat ? categories.dataIngram : categories.datasearch
											),
											value: filters?.categories,
											maxWidth: 900,
											id: 'categories',
											title: 'Categorias SEGMENTAR',
											onSearch: (search: searchProps) => serachData(search),
											changeOpenDialog: (values: any) =>
												setOpenDialogProducts({
													status: values.status,
													userId: data?.id,
													segment: values.segment,
													type: 'categories',
												}),
											dropDownDisabled: true,
										}}
									/>
									
								</>
							)}

							{statusCrudUser.profileCustomer && (
								<AutocompletCheckBoxGroup
									props={{
										error: errorSegmentManufacturer.status,
										errorMessage: errorSegmentManufacturer.message,
										//disabled: productsCount > Number(methods.watch('maxProductsSegment')),
										statusProductsSegment: { productsCount, maxProductsSegment: methods.watch('maxProductsSegment') },
										group: true,
										data: mapManufacturer(manufacturer.datasearch),
										value: filtersM?.manufacturer,
										maxWidth: 900,
										id: 'manufacturer',
										title: 'Fabricantes SEGMENTAR',
										onSearch: (search: searchProps) => serachDataM(search),
										changeOpenDialog: (values: any) =>
											setOpenDialogProducts({
												status: values.status,
												userId: data?.id,
												segment: values.segment,
												type: 'manufacturer',
											}),
										dropDownDisabled: true,
									}}
								/>
							)}

							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
									<TextField
										disabled={statusCrudUser.marketPlacesNotSuper}
										margin='dense'
										id='skusSegmentation'
										label='Segmentado Especial'
										multiline
										maxRows={6}
										fullWidth
										variant='standard'
										{...methods.register('skusSegmentation')}
									/>
									<label
										style={{ color: 'grey' }}
									>{`Si, no se visualiza una marca, número de parte, Sku Ingram: Coloque el item separado por coma, Ejemplo: 23WES65,Lenovo,IBM,3WR12/2 `}</label>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '15%' }} size='small'>
									<InputLabel id='type'>Regla</InputLabel>
									<Select
										onChange={(e) => serachDataRule({ id: e.target.name, value: e.target.value })}
										defaultValue={valueRule.type || 'IVA'}
										value={valueRule.type}
										labelId='type'
										name='type'
										id='type'
										label='Tipo de Regla'
									>
										<MenuItem value='IVA'>
											<em>Iva 0</em>
										</MenuItem>
										<MenuItem value='UTILITY'>Utilidad</MenuItem>
									</Select>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '35%' }} size='small'>
									<AutocompletBoxGroup
										props={{
											error: errorRules,
											errorMessage: errorRules ? 'Empty' : '',
											group: true,
											data: mapCategories(
												data?.segmentarIngram && !initCat ? categories.dataIngram : categories.datasearch
											),
											value: valueRule?.categoriesIngramId,
											maxWidth: 100,
											id: 'categoriesIngramId',
											title: 'Categorias Reglas',
											onSearch: (search: searchProps) => serachDataRule(search),
										}}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '35%' }} size='small'>
									<AutocompletBoxGroup
										props={{
											error: errorRules,
											errorMessage: errorRules ? 'Empty' : '',
											group: true,
											data: mapManufacturer(manufacturer.datasearch),
											value: valueRule?.manufacturerId,
											maxWidth: 100,
											id: 'manufacturerId',
											title: 'Marca Reglas',
											onSearch: (search: searchProps) => serachDataRule(search),
										}}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '15%' }} size='small'>
									<InputLabel id='key'>Operador</InputLabel>
									<Select
										defaultValue={valueRule.key || 'EQUAL'}
										value={valueRule.key}
										labelId='key'
										id='key'
										name='key'
										label='Tipo de Operación'
										onChange={(e) => serachDataRule({ id: e.target.name, value: e.target.value })}
									>
										<MenuItem value='GREATEREQUAL'>{`>=`}</MenuItem>
										<MenuItem value='MINOREQUAL'>{`<=`}</MenuItem>
										<MenuItem value='EQUAL'>{`=`}</MenuItem>
										<MenuItem value='GREATER'>{`>`}</MenuItem>
										<MenuItem value='MINOR'>{`<`}</MenuItem>
										<MenuItem value='UTILITY'>Utilidad</MenuItem>
									</Select>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '25%' }} size='small'>
									<TextField
										margin='dense'
										id='keyWord'
										label='Palabra Clave'
										type='text'
										fullWidth
										variant='standard'
										value={valueRule?.keyWord || ''}
										onChange={(e) => serachDataRule({ id: e.target.id, value: e.target.value })}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '25%' }} size='small'>
									<TextField
										margin='dense'
										id='value'
										label='Valor Limite'
										type='text'
										fullWidth
										variant='standard'
										value={valueRule?.value || ''}
										onChange={(e) => serachDataRule({ id: e.target.id, value: e.target.value })}
										error={!Number(valueRule?.value) && errorRulesNum && valueRule?.type == 'IVA' ? true : false}
										helperText={!Number(valueRule?.value) && errorRulesNum && valueRule?.type == 'IVA' ? 'Number' : ''}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '25%' }} size='small'>
									<TextField
										margin='dense'
										id='valueApply'
										label='Valor Aplicar'
										type='text'
										fullWidth
										variant='standard'
										value={valueRule?.valueApply || ''}
										onChange={(e) => serachDataRule({ id: e.target.id, value: e.target.value })}
										error={!Number(valueRule?.valueApply) && errorRulesNum ? true : false}
										helperText={!Number(valueRule?.valueApply) && errorRulesNum ? 'Number' : ''}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '25%' }} size='small'>
									<TextField
										margin='dense'
										id='priority'
										label='Prioridad'
										type='text'
										fullWidth
										variant='standard'
										defaultValue={valueRule?.priority || '1'}
										value={valueRule?.priority || '1'}
										onChange={(e) => serachDataRule({ id: e.target.id, value: Number(e.target.value) })}
										error={!Number(valueRule?.priority) && valueRule?.priority ? true : false}
										helperText={!Number(valueRule?.priority) && valueRule?.priority ? 'Number' : ''}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1.5rem' }, width: '15%' }} size='small'>
									<Button onClick={handleAddRule}>Agregar</Button>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1.5rem' }, width: '20%' }} size='small'>
									<Button onClick={resetRules}>Restablecer</Button>
								</FormControl>
							)}
							{statusCrudUser.profileServiceRoleServiceUserSuper && (
								<FormControl sx={{ marginTop: { sm: '1.5rem' }, width: '100%' }} size='small'>
									<TableSandBox
										name={`Sandobox`}
										data={data?.sandbox}
										usersSuper={usersSuper}
										props={{
											saveValues: (value: any) => saveDataSandBox(value, ''),
											deleteValues: (value: any) => deleteDataSandBox(value, ''),
										}}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1.5rem' }, width: '100%' }} size='small'>
									<TableRulesIva
										name={`Reglas de Iva 0`}
										data={dataRules?.rulesPricesIva}
										props={{
											deleteValues: (value: any) => deleteRuleData(value, 'iva'),
											editValues: (value: any) => editRuleData(value, 'iva'),
										}}
									/>
								</FormControl>
							)}
							{statusCrudUser.profileCustomer && (
								<FormControl sx={{ marginTop: { sm: '1.5rem' }, width: '100%' }} size='small'>
									<TableRulesUtility
										name={`Reglas de Utilidad`}
										data={dataRules?.rulesPricesUtility}
										props={{
											deleteValues: (value: any) => deleteRuleData(value, 'utility'),
											editValues: (value: any) => editRuleData(value, 'utility'),
										}}
									/>
								</FormControl>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type='submit'>Realizar</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};