import { FilterActionTypes } from './types';

import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ApplicationState } from '../index';

import { Filter } from '../filters/types';

export type AppThunk = ThunkAction<void, ApplicationState, null, Action<Filter>>;

export const putFilter: ActionCreator<ThunkAction<void, ApplicationState, Filter, Action<string>>> = (data) => {
	return (dispatch: Dispatch) => {
		try {
			return dispatch({
				type: FilterActionTypes.PUT_FILTER,
				payload: data,
			});
		} catch (e) {
			return dispatch({
				type: FilterActionTypes.PUT_FILTER_FAILURE,
				payload: null,
			});
		}
	};
};
