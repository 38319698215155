import * as React from 'react';
import TextField from '@mui/material/TextField';

interface ItemProps {
	value?: any;
	title?: string;
	id?: string;
	onSearch: Function;
}

interface ItemsProps {
	props?: ItemProps;
}

export default function TextFieldBasic(props: ItemsProps) {
	return (
		<TextField
			margin='dense'
			id={props?.props?.id}
			label={props?.props?.title}
			value={props?.props?.value}
			type='number'
			fullWidth
			style={{ maxHeight: '35px' }}
			onChange={(newValue) => {
				props?.props?.onSearch({ id: props?.props?.id, value: newValue.target.value });
			}}
			variant='standard'
			InputLabelProps={{ shrink: true }}
		/>
	);
}