import React, { ChangeEvent, useEffect, useRef } from 'react';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import Button from '@mui/material/Button';

interface FolderInputProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function FolderInput(props: FolderInputProps) {
  const { onChange } = props;
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.setAttribute("directory", "");
      ref.current.setAttribute("webkitdirectory", "");
      ref.current.setAttribute("mozdirectory", "");
    }
  }, [ref]);

  return (
		<>
			<PermMediaIcon onClick={() => ref.current?.click()}/>			
			<input type='file' ref={ref} onChange={onChange} style={{ display: 'none' }} />
		</>
	);
}
