import * as React from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CategoryL } from '../../../store/categories/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


import FormHelperText from '@mui/material/FormHelperText';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { undefined, literal, object, string, TypeOf, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import AutocompletBoxGroup from '../Components/AutocompletBoxGroup';


import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';



interface ItemProps {
	getValues: Function;
}

interface CrudItemProps {
	name?: string;
	data?: CategoryL;
	profile?: string;
	role?: string;
	props: ItemProps;
	categories?: CategoriesState;
	user?: any;
}

interface searchProps {
	value?: any;
	id?: any;
}


export const CrudCategories = ({ name, data, profile, role, props, categories, user }: CrudItemProps) => {
	const mapCategories = (data: any) => {
		return (
			data?.map((item: any) => {
				return {
					parent: item?.parentName || item?.name,
					title: item?.name,
					id: item?.name || 'sin categorizar',
					header: !item?.parentName,
				};
			}) || []
		);
	};
	
	const [open, setOpen] = React.useState(false);
	const [input, setInput] = React.useState({
		showPassword: false,
	});

	const [filters, setFilters] = React.useState<any>({
		categories: [],
	});	
	
	const [checkedCat, setCheckedCat] = React.useState(false);

	const [checkedCatIngram, setCheckedCatIngram] = React.useState(user.super);

	const handleChangeCat = (event: React.ChangeEvent<HTMLInputElement>) => {
		methods.reset(defaultValues);
		setCheckedCat(event.target.checked);
	};

	const handleChangeCatIngram = (event: React.ChangeEvent<HTMLInputElement>) => {
		//methods.reset(defaultValues);
		setCheckedCatIngram(event.target.checked);
	};

	function serachData({ id, value }: searchProps) {		
		setFilters({
			...filters,
			[id]: value,
		});
	}
	
	const userSchema = object({
		subcategory: checkedCat ? string().min(1, 'Requerido') : string().nullable().optional(),
		category: !checkedCat ? string().min(1, 'Requerido') : string().nullable().optional(),
		ingram: boolean().nullable().optional(),
		/*categories: data?.categories || data?.categories == null || !data ? string().min(1, 'Requerido') :  string().nullable().optional()*/
	});

	// 👇 Infer the Schema to get the TS Type
	type CategoryL = TypeOf<typeof userSchema>;

	const defaultValues: CategoryL = data || {
		category: '',
		subcategory: '',
		categories: ''
	};	

	// 👇 The object returned from useForm Hook
	const methods = useForm<CategoryL>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});	


	const handleClickOpen = () => {
		methods.reset(defaultValues);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitHandler: SubmitHandler<CategoryL> = (values: CategoryL) => {		
		const existCategory: any = mapCategories(!checkedCatIngram&&categories?.datasearch||categories?.dataIngram).filter(
			(item: any) => values.category == item.title || values.subcategory == item.title
		);
		
		if (existCategory.length>0) {
			methods.setError('category', { type: 'custom', message: 'Categoria existente' })
		} else {
			if (filters?.categories.title) { values.category = filters.categories.title; }
			values.ingram = checkedCatIngram;
			//console.log(values)
			props?.getValues(values);
			handleClose();
		}
	};

	return (
		<div>
			<Button variant='outlined' onClick={handleClickOpen}>
				{name}
			</Button>

			<Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={handleClose}>
				<DialogTitle>{name}</DialogTitle>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
						<DialogContent>
							{user.super && (
								<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
									<div>
										<Checkbox checked={checkedCatIngram} onChange={handleChangeCatIngram} inputProps={{ 'aria-label': 'controlled' }} />
										Ingram
									</div>
								</FormControl>
							)}
							<FormControl sx={{ marginTop: { sm: '1rem' }, width: '100%' }} size='small'>
								<div>
									<Checkbox checked={checkedCat} onChange={handleChangeCat} inputProps={{ 'aria-label': 'controlled' }} />
									Sub-Categoria
								</div>
							</FormControl>
							<FormControl sx={{ marginTop: { sm: '.5rem' }, width: '100%' }} size='small'>
								{checkedCat && (
									<AutocompletBoxGroup
										props={{
											group: false,
											data: mapCategories(!checkedCatIngram&&categories?.datasearch||categories?.dataIngram).filter((item: any) => item.parent == item.title),
											value: filters?.categories,
											maxWidth: 300,
											id: 'categories',
											title: 'Categorias',
											onSearch: (search: searchProps) => serachData(search),
											/* error: methods.formState.errors.categories ? true : false,
										helperText: methods.formState.errors.categories?.message, */
										}}
										/* {...methods.register('categories')} */
									/>
								)}
							</FormControl>
							{!checkedCat && (
								<FormControl sx={{ marginTop: { sm: '.5rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='category'
										label='Categoria'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('category')}
										error={methods.formState.errors.category ? true : false}
										helperText={methods.formState.errors.category?.message}
									/>
								</FormControl>
							)}
							{checkedCat && (
								<FormControl sx={{ marginTop: { sm: '.5rem' }, width: '100%' }} size='small'>
									<TextField
										margin='dense'
										id='subcategory'
										label='Sub Categoria'
										type='text'
										fullWidth
										variant='standard'
										{...methods.register('subcategory')}
										error={methods.formState.errors.subcategory ? true : false}
										helperText={methods.formState.errors.subcategory?.message}
									/>
								</FormControl>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type='submit'>Realizar</Button>
						</DialogActions>
					</Box>
				</FormProvider>
			</Dialog>
		</div>
	);
};