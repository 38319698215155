import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts } from '../../../store/products/action';
import { getManufacturer } from '../../../store/manufacturer/action';
import { getCategories } from '../../../store/categories/action';
import { userReset } from '../../../store/user/action';

import { Dispatch } from 'redux';

import { ManufacturerState, Manufacturer } from '../../../store/manufacturer/types';
import { ApplicationState } from '../../../store/index';

import AutocompletCheckBox from './AutocompletCheckBox';
import AutocompletCheckBoxGroup from './AutocompletCheckBoxGroup';
import Select from './Select';
import DatePicker from './DatePicker';
import TextNumberBasic from './TextNumber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { putFilter } from '../../../store/filters/action';
import { putLoading } from '../../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { RulesPricesUtility } from '../../../store/user/types';

interface CrudItemProps {
	name?: string;
	data?: RulesPricesUtility[];
	props: ItemProps;
}
interface ItemProps {
	deleteValues: Function;
	editValues: Function;
}
const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
		| undefined
		| 'row?.manufacturerId'
		| 'row?.categoriesIngramId'
		| 'row?.type'
		| 'row?.key'
		| 'row?.keyWord'
		| 'row?.value'
		| 'row?.valueApply'
		| 'row?.priority';
	/* | 'row?.country' */

	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
	component?: boolean;
}

const columns: readonly Column[] = [
	{ id: undefined, label: 'Editar', minWidth: 40, component: true },
	{ id: undefined, label: 'Quitar', minWidth: 40, component: true },
	{
		id: 'row?.manufacturerId',
		label: 'Fabricamte',
		minWidth: 80,
	},
	{
		id: 'row?.categoriesIngramId',
		label: 'Categoria',
		minWidth: 80,
	},
	{
		id: 'row?.type',
		label: 'Tipo',
		minWidth: 80,
	},
	{
		id: 'row?.key',
		label: 'Operador',
		minWidth: 80,
	},
	{
		id: 'row?.keyWord',
		label: 'Palabra Clave',
		minWidth: 80,
	},
	{
		id: 'row?.value',
		label: 'Valor',
		minWidth: 80,
	},
	{
		id: 'row?.valueApply',
		label: 'Valor Aplicar',
		minWidth: 80,
	},
	//{ id: 'row?.country', label: 'Ciudad', minWidth: 40 },
];

function Row(props: { rowp: RulesPricesUtility[]; deleteValues: Function; editValues: Function; }) {
	//const { rowp } = props;
	const [open, setOpen] = React.useState(false);

	return (
		<React.Fragment>
			{props.rowp?.map((row) => {
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column) => {
							if (column?.id) {
								const value = eval(column.id);
								return (
									<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
										{column.format ? column.format(value) : value}
									</TableCell>
								);
							} else {
								if (column.label == 'Quitar') {
									return (
										<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
											<Button
												key={column.id}
												variant='outlined'
												onClick={() => {
													props.deleteValues(row);
												}}
											>
												{column.label}
											</Button>
										</TableCell>
									);
								}
								if (column.label == 'Editar') {
									return (
										<TableCell key={column.id} align={column.align} style={{ fontSize: '11px' }}>
											<Button
												key={column.id}
												variant='outlined'
												onClick={() => {
													props.editValues(row);
												}}
											>
												{column.label}
											</Button>
										</TableCell>
									);
								}
							}
						})}
					</TableRow>
				);
			})}
		</React.Fragment>
	);
}

export default function TableRulesUtility({ name, data, props }: CrudItemProps) {
	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			<div>{name}</div>
			<TableContainer sx={{ maxHeight: 200 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.map((row) => (
							<Row key={row.id} rowp={[row]} deleteValues={props.deleteValues} editValues={props.editValues} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
}
