import { Reducer } from "redux";
import { ManufacturerActionTypes, ManufacturerState } from './types';
import { userReset } from '../user/action';

export const initialState: ManufacturerState = {
	length: 1,
	data: undefined,
	datasearch: undefined,
	errors: { code: undefined, message: undefined },
	loading: false,
};

const reducer: Reducer<ManufacturerState> =  (state = initialState, action)  => {
	switch (action.type) {
		case ManufacturerActionTypes.GET_MANUFACTURER: {
			return { ...state, datasearch:action.payload.manufacturer,data: action.payload.manufacturer, length: action.payload.total, errors: action.payload?.manufacturer ? undefined : { code:500, message:undefined } };
		}
		case ManufacturerActionTypes.GET_MANUFACTURER_FAILURE: {	
			return { ...state,  datasearch: undefined, data: undefined, length: 1, errors: { code:action.payload, message:undefined } };
		}
		case ManufacturerActionTypes.PUT_MANUFACTURER: {
			const dataResponse: any = {};
			const res: any = action.payload.response;
			for (let r in res) { dataResponse[res[r].id] = res[r];}
			return {
				...state,
				datasearch: state.data?.map((item: any) => dataResponse[item.id] ?? item),
				data: state.data?.map((item: any) => dataResponse[item.id] ?? item),
			};
		}
		case ManufacturerActionTypes.PUT_MANUFACTURER_FAILURE: {	
			return { ...state,  datasearch: undefined, data: undefined, length: 1, errors: { code:action.payload, message:undefined } };
		}
		default: {
			return state;
		}
	}
};

export { reducer as ManufacturerReducer };