import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store/index';
import Badge from '@mui/material/Badge';
import NoPhotographyRoundedIcon from '@mui/icons-material/NoPhotographyRounded';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from 'react';


interface Column {
	id: 'row?.sku' | 'row?.products?.images' | 'row?.products?.categoriesIdIngram' | 'row?.products?.manufacturerIdIngram' | 'row?.products?.description' | 'row?.products?.productDetailDescription'  ;
	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columns: readonly Column[] = [
	{ id: 'row?.sku', label: 'Sku', minWidth: 50 },
	{ id: 'row?.products?.images', label: 'Imagen', minWidth: 50 },
	{ id: 'row?.products?.categoriesIdIngram', label: 'Categoria',	minWidth: 170, align: 'right' },
	{ id: 'row?.products?.manufacturerIdIngram', label: 'Fabricante', minWidth: 170, align: 'right' },
	{ id: 'row?.products?.description', label: 'Titulo', minWidth: 100 },
	{ id: 'row?.products?.productDetailDescription', label: 'Descripción', minWidth: 170, align: 'right'	},	
];

export default function TabletProductsSegment(props: { manufacturer: any, categories: any, productsSegmentCountDialog: any, productsSegmentDialog: any, selected: { selectPagination: Function; selectManufacturer: Function, selectCategories: Function } }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const user = useSelector((state: ApplicationState) => state.user.data) || {};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
		props?.selected?.selectPagination({page:newPage,rowsPerPage});
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		props?.selected?.selectPagination({ page, rowsPerPage: +event.target.value });
	};


	return (
		<Paper sx={{ width: '100%', overflow: 'hidden'}}>
			<TableContainer sx={{ maxHeight: 320,}}>
				<Table stickyHeader aria-label='sticky table' size='small'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{props?.productsSegmentDialog?.length > 0 ? (
							props?.productsSegmentDialog?.map((row: any) => {
									return (
										<TableRow hover role='checkbox' tabIndex={-1} key={row.sku}>
											{columns.map((column) => {
												const value = eval(column.id);
												if (column.label === 'Imagen') {
													return value?.length > 0 ? (
														<TableCell key={column.id} align={column.align} size='small'>
															<Badge badgeContent={value?.split(',').length} color='success'>
																<img
																	style={{ borderRadius: '10px' }}
																	width='60px'
																	srcSet={`${value
																		?.split(',')[0]
																		.replace(
																			'.jpg',
																			`${
																				value?.split(',')[0].includes('/api/image/') && !user.user.super
																					? '/' + user.user.tokenAppContents
																					: ''
																			}.jpg`
																		)}`}
																	src={`${value
																		?.split(',')[0]
																		.replace(
																			'.jpg',
																			`${
																				value?.split(',')[0].includes('/api/image/') && !user.user.super
																					? '/' + user.user.tokenAppContents
																					: ''
																			}.jpg`
																		)}`}
																	alt={``}
																	loading='lazy'
																/>
															</Badge>
														</TableCell>
													) : (
														(<TableCell key={column.id} align={column.align} size='small'><NoPhotographyRoundedIcon /></TableCell>)
													);
												} else if (column.label === 'Categoria') {
													return (
														<TableCell key={column.id} align={column.align} size='small'>
															<Checkbox
																checked={props?.categories?.includes(value) || false}
																onClick={() => props?.selected?.selectCategories(value)}
															/>
															{value}															
														</TableCell>
													);
												} else if (column.label === 'Fabricante') {
													return (
														<TableCell key={column.id} align={column.align} size={'small'}>
															<Checkbox
																checked={props?.manufacturer?.includes(value) || false}
																onClick={() => props?.selected?.selectManufacturer(value)}
															/>
															{value}															
														</TableCell>
													);
												} else {
													return (
														<TableCell key={column.id} align={column.align} size={'small'}>
															{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													);
												}
											})}
										</TableRow>
									);
								})
						) : (
							<TableRow hover role='checkbox' tabIndex={-1} key={1}>
								<CircularProgress
									size={50}
									sx={{
										position: 'fixed',
										left: '50%',
										top: '50%',
										transform: 'translate(-50%, -50%)',
										zIndex: (theme) => theme.zIndex.drawer + 1000,
										alignItems: 'flex-start',
										verticalAlign: 'top',
									}}
								/>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25]}
				component='div'
				count={props?.productsSegmentCountDialog}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}