import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Box from '@mui/material/Box';
import { useMemo, useState, useEffect, Suspense } from 'react';


import { undefined, literal, object, string, TypeOf, custom, nullable, number, optional, boolean } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, SubmitHandler, FormProvider, UseFormReset } from 'react-hook-form';

import LinkMUI from '@mui/material/Link';

import { Dispatch } from 'redux';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { UserL, RulesPricesIva, RulesPricesUtility } from '../../../store/user/types';

import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { putSandbox, putSandboxThunk } from '../../../store/user/action';

import { ThunkDispatch } from 'redux-thunk';

import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../../store/index';


export default function SandBoxAccess( ) {
	const [open, setOpen] = React.useState(false);
	const [aviso, setAviso] = React.useState('');
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();

	const sandbox = useSelector((state: ApplicationState) => state.user.sandbox);

	useEffect(() => {
		if (sandbox) {
			setAviso('Verifica la bandeja de entrada, esta en proceso de validación');
		}
		dispatchThunk(putSandboxThunk(false));		
	}, [dispatchThunk, sandbox]);

	const userSchema = object({
		//countryCode: string().optional().nullable(),
		//emailSB: string().min(1, 'Email es requerido').email('Email es invalido')
		emailSB: string().regex(new RegExp(/^([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6})*([,;][\s]*([A-Z0-9.%+-]+@[A-Z0-9.-]+.[A-Z]{2,6}))*$/i),'Email es invalido').min(1, 'Email es requerido'),
	});

	type IUser = TypeOf<typeof userSchema>;

	const defaultValues: IUser = {
		//countryCode:'',
		emailSB: '',
	};

	// 👇 The object returned from useForm Hook
	const methods = useForm<IUser>({
		resolver: zodResolver(userSchema),
		defaultValues,
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmitHandler: SubmitHandler<IUser> = (values: IUser) => {
		/* values.categories = filters.categories.map((item: any) => item.title);
		values.categoriesExclude = filters.categoriesExclude.map((item: any) => item.title);
		values.manufacturer = filtersM.manufacturer.filter((item: any) => item.parent != item.title).map((i: any) => i.title);
		props?.getValues({ ...values, ...dataRules, ...{ secretOrder: secretOrder } });
		setSecretOrder(false); */
		dispatch(putSandbox(values));
	};

	return (
		<React.Fragment>
			<LinkMUI href='#' variant='body2' onClick={handleClickOpen}>
				Solicita acceso
			</LinkMUI>
			<Dialog
				fullWidth={true}
				maxWidth={'sm'}
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<FormProvider {...methods}>
					<Box
						display='flex'
						flexDirection='column'
						component='form'
						noValidate
						autoComplete='off'
						sx={{ padding: { sm: '1rem' } }}
						onSubmit={methods.handleSubmit(onSubmitHandler)}
					>
				<DialogTitle id='alert-dialog-title'>Solicita acceso</DialogTitle>
				<DialogContent>					
					{/* <FormControl
						sx={{ marginTop: { sm: '1rem' }, width: '100%' }}
						size='small'
						//error={methods.formState.errors.countryCode ? true : false}
					>
						<InputLabel id='countryCode'>Codigo Ciudad</InputLabel>
						<Select
							//defaultValue={data?.countryCode || ''}
							labelId='countryCode'
							id='countryCode'
							label='Codigo Ciudad'
							{...methods.register('countryCode')}
						>
							<MenuItem value=''>
								<em>Seleccionar....</em>
							</MenuItem>
							<MenuItem value='US'>Miami</MenuItem>
							<MenuItem value='MX'>México</MenuItem>
							<MenuItem value='CO'>Colombia</MenuItem>
							<MenuItem value='CA'>Canada</MenuItem>
							<MenuItem value='PE'>Péru</MenuItem>
						</Select>
					</FormControl> */}
					<FormControl sx={{ marginTop: { sm: '.1rem' }, width: '100%' }} size='small'>
						<TextField
							margin='dense'
							id='emailSB'
							label={`Email's separados por coma`}
							multiline
							fullWidth
							variant='standard'
							{...methods.register('emailSB')}
							error={methods.formState.errors.emailSB ? true : false}
							helperText={methods.formState.errors.emailSB?.message}
						/>
						<div>{aviso}</div>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cerrar</Button>
					<Button type='submit'>Solicitar</Button>
				</DialogActions>
				</Box>
				</FormProvider>
			</Dialog>
		</React.Fragment>
	);
}
