import * as React from 'react';

import { ComponentType, FC, Suspense } from 'react';
import { useRef, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { getProducts } from '../../../store/products/action';
import { userReset, getUsers, createUser, updateUser, crudUser, putSandBoxData, productsCount ,getLogs, productsSegment} from '../../../store/user/action';
import { getCategoriesSearch, getCategoriesIngramSearch } from '../../../store/categories/action';
import { getManufacturer } from '../../../store/manufacturer/action';


import { Dispatch } from 'redux';

import { UserL } from '../../../store/user/types';
import { ApplicationState } from '../../../store/index';

//import CrudUser from '../Dialogs/CrudUser';

import { CrudUser } from '../Dialogs/CrudUser';
import { Logs } from '../Dialogs/Logs';

import { putLoading } from '../../../store/loading/action';

import { CategoriesState } from '../../../store/categories/types'; 
import { ManufacturerState } from '../../../store/manufacturer/types';

import { UserState, UserLogin } from '../../../store/user/types';
import { loadState } from '../../../utils/localstorage';

import CircularProgress from '@mui/material/CircularProgress';

import TextField from '@mui/material/TextField';

import ProductsSegment from '../Dialogs/ProductsSegment';


const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
		| undefined
		| 'row?.id'
		| 'row?.username'
		| 'row?.email'
		| 'row?.name'
		| 'row?.status'
		| 'row?.createdAt'
		| "row?.updateAtPrices && new Date(row?.updateAtPrices?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) + ' \\n\\t ' + row?.progressUpdatePrices + ' de ' + row?.totalUpdatePrices"
		| "row?.updateAtProducts && new Date(row?.updateAtProducts?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) + ' \\n\\t Insertados con stock Nuevos: ' + row?.progressUpdateProducts + ', Encontrados: ' + row?.totalUpdateProducts"
		| 'CrudUser';

	label?: string;
	component?: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
	profile?: string;
}

const columns: readonly Column[] = [
	{ id: undefined, label: '', minWidth: 20 },
	{ id: 'row?.id', label: 'Id', minWidth: 50 },
	{ id: 'row?.username', label: 'Nom. Usuario', minWidth: 60 },
	{ id: 'row?.email', label: 'Email', minWidth: 100 },
	{
		id: 'row?.name',
		label: 'Nombre',
		minWidth: 100,
		align: 'right',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{
		id: 'row?.status',
		label: 'Status',
		minWidth: 80,
		align: 'right',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{
		id: 'row?.createdAt',
		label: 'Creado',
		minWidth: 80,
		format: (value: number) => new Date(value?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat),
	},
	{
		id: "row?.updateAtPrices && new Date(row?.updateAtPrices?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) + ' \\n\\t ' + row?.progressUpdatePrices + ' de ' + row?.totalUpdatePrices",
		label: 'Precios',
		minWidth: 120,
		align: 'right',
		profile: 'customer',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{
		id: "row?.updateAtProducts && new Date(row?.updateAtProducts?.toLocaleString('es-MX'))?.toLocaleDateString('es-MX', timeFormat) + ' \\n\\t Insertados con stock Nuevos: ' + row?.progressUpdateProducts + ', Encontrados: ' + row?.totalUpdateProducts",
		label: 'Productos',
		minWidth: 120,
		align: 'right',
		profile: 'customer',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{ id: undefined, component: 'CrudUser', minWidth: 100 },
];

/* interface ColumnH {
	id:
		| 'row?.br10'
		| 'row?.br20'
		| 'row?.br30'
		| 'row?.br40'
		| 'row?.br50'
		| 'row?.br60'
		| 'row?.br70'
		| 'row?.products?.images'
		| 'row?.products?.sheets'

	label: string;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
}

const columnshistory: readonly ColumnH[] = [
	{ id: 'row?.br10', label: 'br10', minWidth: 100 },
	{ id: 'row?.br20', label: 'br20', minWidth: 140 },
	{
		id: 'row?.br30',
		label: 'br30',
		minWidth: 100,
		align: 'right',
		//format: (value: number) => value?.toLocaleString('en-US'),
	},
	{ id: 'row?.br40', label: 'br40', minWidth: 80 },
	{ id: 'row?.br50', label: 'br50', minWidth: 100 },
	{ id: 'row?.br60', label: 'br60', minWidth: 100 },
	{ id: 'row?.br70', label: 'br70', minWidth: 80 },
	{ id: 'row?.products?.images', label: 'Imagenes', minWidth: 80 },
	{ id: 'row?.products?.sheets', label: 'Ficha', minWidth: 80 },
];*/

function Row(props: {log:any, usersSuper: any; user: any;  rowp: UserL[]; profile: string; roleUser: string; categories: CategoriesState;manufacturer: ManufacturerState }) {
	const { log, usersSuper, user, rowp, profile, roleUser, categories, manufacturer } = props;
	const [open, setOpen] = React.useState(false);

	const dispatch = useDispatch<any>();	

	function sendData(values: any) {
		/*if (typeof values.id == 'undefined') {
			dispatch(createUser(values,profile));
		} else {
			dispatch(updateUser(values, profile));
		}*/
		//console.log(values);
		dispatch(crudUser(values, profile));
	}

	function sendDataLogs(values: any,id:any) {
		/*if (typeof values.id == 'undefined') {
			dispatch(createUser(values,profile));
		} else {
			dispatch(updateUser(values, profile));
		}*/
		//console.log(values);
		if (values) {
			dispatch(getLogs(values, id));
		}
	}

	function sendDataSandBox(values: any) {
		dispatch(putSandBoxData(values));
	}

	function sendDataProductsCount(values: any) {
		dispatch(productsCount(values, profile));
	}

	function sendDataProductsSegment(values: any) {
		//dispatchThunk(putLoading(true));
		dispatch(productsSegment(values, profile));
	}
	

	return (
		<React.Fragment>
			{rowp?.map((row) => {
				const expand = (
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
				);
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column:any) => {							
							if (column.id) {
								const value = eval(column.id);
								return (!column?.profile || column?.profile == profile?.toLowerCase()) &&(
									<TableCell key={column.id} align={column.align}>
										{column.format ? column.format(value) : value}
									</TableCell>
								);
							} else {
								switch (column.component) {
									case 'CrudUser': {
										return (
											<TableCell>
												<CrudUser
													props={{
														getValuesSandBox: (values: any) => sendDataSandBox(values),
														getValues: (values: any) => sendData(values),
														getProductsCount: (values: any) => sendDataProductsCount(values),
														getProductsSegment: (values: any) => sendDataProductsSegment(values),
													}}
													name={`Actualizar Usuario`}
													role={roleUser}
													user={user}
													data={row}
													profile={profile}
													categories={categories}
													manufacturer={manufacturer}
													usersSuper={usersSuper}
												/>
												{user.super && profile == 'customer' && (
													<Logs
														name={'Logs'}
														data={{ logsDate: row?.logsDate }}
														log={log}
														props={{ getValues: (values: any) => sendDataLogs(values, row.id) }}
													/>
												)}
											</TableCell>
										);
									}
									default: {
										return expand;
									}
								}
														
							}
						})}
					</TableRow>
				);
			})}
			
			{/* <TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit>
						<Box sx={{ margin: 1 }}>
							<Typography variant='h6' gutterBottom component='div'>
								Branch & Content
							</Typography>
							<Table size='small' aria-label='purchases'>
								<TableHead>
									<TableRow>
										{columnshistory.map((column) => (
											<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
												{column.label}
											</TableCell>
										))}
									</TableRow>
								</TableHead>
								<TableBody>
									{rowp.map((row) => {
										return (
											<TableRow tabIndex={-1} key={rowp[0].sku}>
												{columnshistory.map((column) => {
													const value = eval(column.id);
													return (
													<TableCell key={column.id} align={column.align}>
														{column.format && typeof value === 'number' ? column.format(value) : value}
														</TableCell>
													)
												})}
											</TableRow>
										)
									})}	
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow> */}
		</React.Fragment>
	);
} 

export default function Users() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [open, setOpen] = React.useState(false);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();	

	const [search, setSearch] = React.useState('');

	const { users, length, errors,loading, customers } = useSelector((state: ApplicationState) => state.user);
	const filtersGlb = useSelector((state: ApplicationState) => state.filters);
	const user = useSelector((state: ApplicationState) => state.user.data) || {};
	const log = useSelector((state: ApplicationState) => state.user.logs) || '';

	let userLocal: UserLogin = loadState();

	const [profile, setProfile] = React.useState(user?.user?.super? userLocal.user.role.toLowerCase(): 'customer');
	
	const categories = useSelector((state: ApplicationState) => state.categories);
	const manufacturer = useSelector((state: ApplicationState) => state.manufacturer);

	const handleChange = (event: SelectChangeEvent) => {
		setProfile(event.target.value);
	};

	function sendData(values: any) {
		//dispatchThunk(putLoading(true));
		dispatch(crudUser(values, profile));
	}
	

	function sendDataProductsCount(values: any) {
		//dispatchThunk(putLoading(true));
		dispatch(productsCount(values, profile));
	}

	function sendDataProductsSegment(values: any) {
		//dispatchThunk(putLoading(true));
		dispatch(productsSegment(values, profile));
	}

	/* useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, errors]); */

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, users]);

	useEffect(() => {
		if (errors?.code) {			
			if (errors?.code!=400) {
				dispatchThunk(userReset());
			}
		}
	}, [dispatchThunk, errors]);

	useEffect(() => {
		dispatch(
			getCategoriesSearch({
				search: null,
			})
		);
		dispatch(
			getManufacturer({
				search: null,
			})
		);
		dispatch(
			getCategoriesIngramSearch({
				search: null,
				ingram: true,
			})
		);
	}, [dispatchThunk, dispatch]);

	useEffect(() => {

		dispatchThunk(putLoading(true));
		dispatch(
			getUsers({
				profile: profile,
				filters: filtersGlb,
				rowsPerPage: rowsPerPage,
				page: page,
			})
		);

	}, [dispatchThunk, dispatch, page, rowsPerPage, filtersGlb, profile]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);		
	};

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			{user?.user.role == 'SERVICE' && user?.user?.super && (
				<>
					<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
						<InputLabel id='demo-select-small'>Perfil</InputLabel>
						<Select
							labelId='demo-select-small'
							id='demo-select-small'
							value={profile}
							label='Profile'
							onChange={handleChange}
						>
							<MenuItem value='service'>
								<em>Administrador</em>
							</MenuItem>
							<MenuItem value='customer'>Cliente</MenuItem>
						</Select>
					</FormControl>
				</>
			)}
			{user?.user.role == 'SERVICE' && (
				<FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
					<CrudUser
						props={{
							getValuesSandBox: (values: any) => {},
							getValues: (values: any) => sendData(values),
							getProductsCount: (values: any) => sendDataProductsCount(values),
							getProductsSegment: (values: any) => sendDataProductsSegment(values),
						}}
						data={undefined}
						name='Crear Usuario'
						role={user.user.role}
						user={user.user}
						profile={profile}
						categories={categories}
						manufacturer={manufacturer}
						usersSuper={customers || []}
					/>
				</FormControl>
			)}
			
			{user?.user.role == 'SERVICE' && (
				<FormControl sx={{ float: 'right', marginTop: { sm: '-.5rem' }, width: '20%' }} size='small'>
					<TextField
						margin='dense'
						id='search'
						label='Buscar texto'
						type='text'
						variant='standard'
						onChange={(e) => setSearch(e.target.value)}
						style={{ height: '5px' }}
					/>
				</FormControl>
			)}
			<TableContainer sx={{ maxHeight: 440 }} component={Paper}>
				<Table stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map(
								(column) =>
									(!column?.profile || column?.profile == profile?.toLowerCase()) && (
										<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
											{column.label}
										</TableCell>
									)
							)}
						</TableRow>
					</TableHead>
					<TableBody>
						{users?.length
							? users
									?.filter((r: any) => `${Object.values(r).join(' ').toLowerCase()}`.includes(search.toLowerCase()))
									?.map((row: any) => (
										<Row
											key={row.id}
											rowp={[row]}
											roleUser={user.user.role}
											user={user.user}
											profile={profile}
											categories={categories}
											manufacturer={manufacturer}
											usersSuper={customers}
											log={log}
										/>
									))
							: !users && (
									<CircularProgress
										disableShrink
										size={70}
										sx={{
											position: 'fixed',
											left: '50%',
											top: '50%',
											transform: 'translate(-50%, -50%)',
											zIndex: 5,
										}}
									/>
							  )}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100, 300]}
				component='div'
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}