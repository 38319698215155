export interface CategoriesSearch {
	search: string;
	rowsPerPage: number;
	page: number;
	ingram: boolean;
	categoryName?: string;
	categoryNameIngram?: string;
}

export enum CategoriesActionTypes {
	GET_CATEGORIES = '@@categories/GET_CATEGORIES',
	GET_CATEGORIES_FAILURE = '@@categories/GET_CATEGORIES_FAILURE',

	PUT_CATEGORIES = '@@categories/PUT_CATEGORIES',
	PUT_CATEGORIES_FAILURE = '@@categories/PUT_CATEGORIES_FAILURE',

	GET_CATEGORIES_SEARCH = '@@categories/GET_CATEGORIES_SEARCH',

	GET_CATEGORIES_INGRAM = '@@categories/GET_CATEGORIES_INGRAM',

	PUT_CATEGORIES_ADD = '@@categories/GET_CATEGORIES_ADD',

	PUT_CATEGORIES_INGRAM = '@@categories/PUT_CATEGORIES_INGRAM',

	GET_CATEGORIES_INGRAM_SEARCH = '@@categories/GET_CATEGORIES_INGRAM_SEARCH'
}
export interface errors {
	code?: number;
	message?: string;
}

export interface CategoriesState {
	length: number;
	lengthIngram: number;
	loading: boolean;
	data?: Categories[];
	dataIngram?: Categories[];
	dataIngramSearch?: Categories[];
	datasearch?: Categories[];
	errors?: errors;
}

export interface Categories {
	id?: number;
	name: string;
	country: string;
	categoriesIngram?: CategoriesOnCategoriesIngram[];
	customerId: number;
	status: boolean;
}

export interface CategoriesOnCategoriesIngram {
	categoriesId: string;
	categoriesIngramId: string;
	assignedAt: Date;
	assignedBy: string;
}

export interface CategoryL {
	subcategory?: string;
	category?: string;
	categories?:string;
}