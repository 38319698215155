import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled, lighten, darken } from '@mui/system';
import { useRef, useMemo, useState, useEffect } from 'react';


const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const GroupItems = styled('ul')({
	padding: 0,
});


interface ItemProps {
	group?: any;
	data: any;
	value?: any;
	title?: string;
	id?: string;
	onSearch?: Function;
	sort?: boolean;
	maxWidth?: number;
	error?: boolean;
	errorMessage?: string;
}

interface ItemsProps {
	props?: ItemProps;
}
const filterDuplicate = (array:any) => {
	let hash:any = {};
	array = array.filter((o:any) => (hash[o.title] ? false : (hash[o.title] = true)));
	return array;
}
export default function CheckboxesTags(propsaut: ItemsProps) {
const [open, setOpen] = useState(false);
const [inputValue, setInputValue] = useState('');
	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				// only open when in focus and inputValue is not empty
				//if (inputValue) {
				setOpen(true);
				//}
			}}
			onClose={() => setOpen(false)}
			inputValue={inputValue}
			onInputChange={(e, value, reason) => {
				setInputValue(value);
				// only open when inputValue is not empty after the user typed something
				if (value) {
					//setOpen(false);
				}
			}}
			key={propsaut?.props?.id}
			options={propsaut?.props?.data.sort((a: any, b: any) => -b?.parent?.localeCompare(a?.parent))}
			groupBy={(option) => option?.parent}
			id={propsaut?.props?.id}
			value={propsaut?.props?.value}
			autoComplete
			getOptionLabel={(option) =>  option?.title || option || 'Select Option' }
			isOptionEqualToValue={(option, value) => option?.title === value?.title || `${option?.parent}->${option?.title}`== value?.title || option === value || `${option?.parent}->${option?.title}`== value }
			/*defaultValue={propsaut?.props?.value} */
			/* onInputChange={(e, value, reason) => {
				if (value) {
					setOpen(true);
				}
			}} */
			renderGroup={(params) => (
				<li key={params.key}>
					{/*<Checkbox
						icon={icon}
						checkedIcon={checkedIcon}
						style={{ marginRight: 8 }}
						checked={propsaut.props?.value.map((item:any)=>item.title)?.includes(params.group)}
						onChange={() => {
							if (propsaut?.props?.onSearch) {

								let value: any = [
									...propsaut?.props?.value,
									...[{ id: params.group, parent: params.group, title: params.group }],
								];

								if (propsaut.props?.value.map((item:any)=>item.title).includes(params.group)) {
									value = value .filter((item: any) => item.parent != params.group);
								} else {
									value = [
										...value,
										...propsaut?.props?.data.filter(
											(item: any) => item.parent == params.group && item.parent != item.title
										),
									];
								}
 								
								propsaut?.props?.onSearch({
									id: propsaut?.props?.id,
									value:  filterDuplicate(value),
								});

							}
						}}
					/>*/}
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							typography: 'body1',
							'& > :not(style) ~ :not(style)': {
								ml: 2,
							},
							':hover': {
								bgcolor: 'white',
								color: 'black',
							},
						}}
						onClick={() => {
							if (propsaut?.props?.onSearch) {
								propsaut?.props?.onSearch({
									id: propsaut?.props?.id,
									value: { id: params.group, parent: params.group, title: params.group },
								});
							}
						}}
					>
						<Link component='button' variant='body2' href='#' underline='none'>
							{params.group}
						</Link>
					</Box>
					<GroupItems>{propsaut?.props?.group && params.children}</GroupItems>
				</li>
			)}
			renderOption={(props, option, { selected }) => {
				return (
					<>
						<li {...props} style={{ fontSize: '10px' }}>
							{/* <Checkbox
								icon={icon}
								checkedIcon={checkedIcon}
								style={{ marginRight: 8 }} 
								checked={propsaut.props?.value. map((item:any)=>item.title)?.includes(option?.title)}
							/>*/}
							{option?.title}
						</li>
					</>
				);
			}}
			fullWidth
			onChange={(event, value, reason, details) => {
				if (propsaut?.props?.onSearch && value) {
					if (reason == 'removeOption') {
						/* const values: any = propsaut.props?.value.map((item:any)=>item.title).includes(details?.option.title)
							? value.filter((item: any) => item.title != details?.option.title).filter((item: any) => item.parent != details?.option.title)
							: value.filter((item: any) => item.parent != details?.option.title); */

						propsaut?.props?.onSearch({
							id: propsaut?.props?.id,
							value: value /*filterDuplicate(values)*/,
						});
					} else {
						/* const values: any = propsaut.props?.value.map((item:any)=>item.title).includes(details?.option.title)
							? value.filter((item: any) => item.title != details?.option.title)
							: value; */
						propsaut?.props?.onSearch({
							id: propsaut?.props?.id,
							value: value /*filterDuplicate(values)*/,
						});
					}
				}
			}}
			style={{
				minWidth: propsaut?.props?.maxWidth || 200,
				/* overflowY: 'scroll',*/
				//height: '30px',
				//paddingTop: '10px',
				fontSize: '10px',
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label={propsaut?.props?.title}
					placeholder={propsaut?.props?.title}
					style={{ fontSize: '10px' }}
					error={propsaut?.props?.error}
					helperText={propsaut?.props?.errorMessage}
				/>
			)}
			sx={{
				/* '& .MuiAutocomplete-endAdornment': {
					top: '2px !important',
				}, */
				'& .MuiInputBase-input': {
					height: '.01px',
				},
			}}
		/>
	);
}


