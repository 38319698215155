import {
	Home as HomeIcon,
	BarChartOutlined as DashboardIcon,
	CodeOutlined as CodeIcon,
	GitHub as GitHubIcon,
	Public as PublicIcon,
	PublicOff as PrivateIcon,
	AccountBoxRounded as UserIcon,
	SettingsOutlined as SettingsIcon,
	ListAlt as ListIcon,
	CreditCard as BillingIcon,
	IntegrationInstructions as IntegrationInstructions,
	LocalShipping as LocalShipping,
	Toc as Toc,
	TocRounded
} from '@mui/icons-material';

import { Home } from '../pages/Home';
import Products from '../components/Admin/Products';

import ProductsOptimized from '../components/Admin/ProductsOptimized';
import Manufacturer from '../components/Admin/Manufacturer';
import OrdersIngram from '../components/Admin/Orders';
import HistoryAccess from '../components/Admin/HistoryAccess';
import Categories from '../components/Admin/Categories';
import Users from '../components/Admin/Service/Users';

import Dashboard from '../components/Admin/Dashboard';

import Loading from '../components/Admin/Components/Loading';

import { Route } from '../types/Route';

import CategoryIcon from '@mui/icons-material/Category';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
const routes: Array<Route> = [
	{
		key: 'router-users',
		title: 'Usuario',
		description: 'Usuario',
		component: Users,
		path: '/users',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
		rol: 'SERVICE',
	},
	{
		key: 'EXACT-router-history-SUPER',
		title: 'Historial de Acceso',
		description: 'Historial de Acceso',
		component: HistoryAccess,
		path: '/history/access',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
		rol: 'SERVICE',
	},
	{
		key: 'router-users-customer',
		title: 'Usuario',
		description: 'Usuario',
		component: Users,
		path: '/users/customer',
		isEnabled: true,
		icon: HomeIcon,
		appendDivider: true,
		rol: 'CUSTOMER',
	},
	{
		key: 'router-dashboard',
		title: 'Dashboard',
		description: 'Dashboard',
		path: '/dashboard',
		component: Dashboard,
		isEnabled: true,
		icon: DashboardIcon,
		rol: 'CUSTOMER',
	},
	{
		key: 'router-dashboard-admin',
		title: 'Dashboard',
		description: 'Dashboard',
		path: '/dashboard/admin',
		component: Dashboard,
		isEnabled: true,
		icon: DashboardIcon,
		rol: 'SERVICE',
	},
	{
		key: 'EXACT-router-products-PUB',
		title: 'Productos',
		description: 'Productos',
		isEnabled: true,
		icon: IntegrationInstructions,
		rol: 'SERVICE',
		subRoutes: [
			{
				key: 'EXACT-products-PUB',
				title: 'Productos Contents',
				component: Products,
				description: 'Productos Contenido',
				path: '/products/contents',
				isEnabled: true,
				icon: LocalShipping,
				rol: 'SERVICE',
			},
		],
	},
	/*{
		key: 'router-products-admin',
		title: 'Products',
		description: 'Products',
		isEnabled: true,
		icon: IntegrationInstructions,
		rol: 'SERVICE',
		subRoutes: [
			{
				key: 'products-admin',
				title: 'Products Admin',
				component: Products,
				description: 'Products Admin',
				path: '/products/admin',
				isEnabled: true,
				icon: LocalShipping,
				rol: 'SERVICE',
			},
		],
	},*/
	{
		key: 'products-admin',
		title: 'Productos Admin',
		component: Products,
		description: 'Productos Admin',
		path: '/products/admin',
		isEnabled: true,
		icon: LocalShipping,
		rol: 'SERVICE',
	},
	{
		key: 'products',
		title: 'Productos',
		component: Products,
		description: 'Productos',
		path: '/products',
		isEnabled: true,
		icon: LocalShipping,
		rol: 'CUSTOMER',
	},
	/*{
		key: 'router-products',
		title: 'Products',
		description: 'Products',
		isEnabled: true,
		icon: IntegrationInstructions,
		rol: 'CUSTOMER',
		subRoutes: [
			{
				key: 'products',
				title: 'Products',
				component: Products,
				description: 'Products',
				path: '/products',
				isEnabled: true,
				icon: LocalShipping,
				rol: 'CUSTOMER',
			},
		],
	},*/
	{
		key: 'router-categories-admin',
		title: 'Categorias',
		description: 'Categorias',
		component: Categories,
		path: '/categories/service',
		isEnabled: true,
		icon: CategoryIcon,
		appendDivider: true,
		rol: 'SERVICE',
	},
	{
		key: 'router-categories',
		title: 'Categorias',
		description: 'Categorias',
		component: Categories,
		path: '/categories',
		isEnabled: true,
		icon: CategoryIcon,
		appendDivider: true,
		rol: 'CUSTOMER',
	},
	{
		key: 'router-manufacturer-admin',
		title: 'Fabricantes',
		description: 'Fabricantes',
		component: Manufacturer,
		path: '/manufacturer/service',
		isEnabled: true,
		icon: PrecisionManufacturingIcon,
		appendDivider: true,
		rol: 'SERVICE',
	},
	{
		key: 'router-manufacturer',
		title: 'Fabricantes',
		description: 'Fabricantes',
		component: Manufacturer,
		path: '/manufacturer',
		isEnabled: true,
		icon: PrecisionManufacturingIcon,
		appendDivider: true,
		rol: 'CUSTOMER',
	},
	/* {
		key: 'router-code',
		title: 'Contents',
		description: 'Sheets and Images',
		path: '/code-editor',
		isEnabled: true,
		icon: TocRounded,
		appendDivider: true,
		rol: 'CUSTOMER',
	}, */
	{
		key: 'router-order-customer',
		title: 'Ordenes',
		description: 'Ordenes',
		component: OrdersIngram,
		path: '/order/customer',
		isEnabled: true,
		icon: ReceiptIcon,
		appendDivider: true,
		rol: 'CUSTOMER',
	},
	{
		key: 'router-order-service',
		title: 'Ordenes',
		description: 'Ordenes',
		component: OrdersIngram,
		path: '/order/service',
		isEnabled: true,
		icon: ReceiptIcon,
		appendDivider: true,
		rol: 'SERVICE',
	},
	/*{
		key: 'router-orders',
		title: 'Orders',
		description: 'Orders',
		path: '/account',
		isEnabled: true,
		icon: UserIcon,
		rol: 'CUSTOMER',
		subRoutes: [
			{
				key: 'router-order',
				title: 'Orders',
				description: 'Orders',
				path: '/account/settings',
				isEnabled: true,
				icon: SettingsIcon,
				rol: 'CUSTOMER',
			},
			{
				key: 'router-preferences',
				title: 'Preferences',
				description: 'Account Preferences',
				path: '/account/preferences',
				isEnabled: true,
				icon: ListIcon,
				rol: 'CUSTOMER',
			},
			{
				key: 'router-billing',
				title: 'Billing',
				description: 'Account Billing',
				path: '/account/billing',
				isEnabled: true,
				icon: BillingIcon,
				rol: 'CUSTOMER',
			},
		],
	},*/
	/*{
		key: 'router-orders',
		title: 'Orders',
		description: 'Orders',
		path: '/account',
		isEnabled: true,
		icon: UserIcon,
		rol: 'CUSTOMER',
		subRoutes: [
			{
				key: 'router-order',
				title: 'Orders',
				description: 'Orders',
				path: '/account/settings',
				isEnabled: true,
				icon: SettingsIcon,
				rol: 'CUSTOMER',
			},
			{
				key: 'router-preferences',
				title: 'Preferences',
				description: 'Account Preferences',
				path: '/account/preferences',
				isEnabled: true,
				icon: ListIcon,
				rol: 'CUSTOMER',
			},
			{
				key: 'router-billing',
				title: 'Billing',
				description: 'Account Billing',
				path: '/account/billing',
				isEnabled: true,
				icon: BillingIcon,
				rol: 'CUSTOMER',
			},
		],
	},*/
];

export default routes;
