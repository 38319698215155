import React, { useContext } from 'react';
import { Box, Menu, MenuItem } from '@mui/material';

import { Messages, Notifications, SignOut, Settings } from '../../Actions';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { ThemeModeContext } from '../../../contexts';

import DialogDocumentation from '../../../components/Admin/Dialogs/ApiDocumentation';
import { useDispatch } from 'react-redux';

import { Dispatch } from 'redux';
import { userReset, createUser } from '../../../store/user/action';

interface MobileMenuProps {
	isMenuOpen: boolean;
	handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
	handleMenuClose: () => void;
	anchorEl: HTMLElement | null;
}

export const MobileMenu = ({ isMenuOpen, handleMenuOpen, handleMenuClose, anchorEl }: MobileMenuProps) => {
	const { toggleThemeMode } = useContext(ThemeModeContext);
	

	const dispatch = useDispatch<Dispatch<any>>();
	function handleLogout() {
		dispatch(userReset());
		window.location.href = '/';
	}
	
	return (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id='primary-search-account-menu-mobile'
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<Box sx={{ textAlign: 'center' }}>
				{/*
				<MenuItem onClick={toggleThemeMode}>
					<ThemeSwitcher disableTooltip />
					Toggle Theme
				</MenuItem>
				 <MenuItem onClick={handleMenuClose}>
					<Messages total={15} disableTooltip />
					Messages
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<Notifications total={20} disableTooltip />
					Notifications
				</MenuItem>
				<MenuItem onClick={handleMenuClose}>
					<Settings disableTooltip />
					Settings
				</MenuItem> 
				*/}
				<MenuItem>
					<DialogDocumentation props={{ getValues: (values: any) => null }} />
					Doc. Api
				</MenuItem>
				<MenuItem onClick={handleLogout}>
					<SignOut disableTooltip />
					Sign Out
				</MenuItem>
			</Box>
		</Menu>
	);
};
