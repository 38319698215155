import * as React from 'react';
import { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import PreviewIcon from '@mui/icons-material/Preview';
import TableProductsSegment from '../Components/TableProductsSegment';
import { any } from 'zod';

interface ItemProps {
	getValues: Function;
	getOpenDialog: Function;
	getSelectSegment: Function;
	getSelectSegmentPagination: Function;
}
interface CrudItemProps {
	name?: string;
	props: ItemProps;
	openDialog: boolean;
	productsSegment?: any;
	productsSegmentCount?: any;
	categories?: any;
	manufacturer?: any;
	openDialogProducts?: any;
}

export default function ProductsSegment({ productsSegmentCount, productsSegment, openDialog, openDialogProducts, name, props, categories, manufacturer }: CrudItemProps) {
	const [open, setOpen] = React.useState(false);
	const [productsSegmentDialog, setProductsSegmentDialog] = React.useState([]);
	const [productsSegmentCountDialog, setProductsSegmentCountDialog] = React.useState([]);
	const [selectSegment, setSelectSegmentState] = React.useState<any>({ values: null, type: null });
	const [openDialogProductsState, setopenDialogProducts] = React.useState<any>({});

	const handleClose = () => {
		setOpen(false);
		setProductsSegmentDialog([]);
		props?.getOpenDialog({ status: false });
	};

	useEffect(() => {
		setOpen(openDialog);
	}, [openDialog]);

	useEffect(() => {
		setopenDialogProducts(openDialogProducts);
	}, [openDialogProducts]);

	useEffect(() => {
		setProductsSegmentDialog(productsSegment);
	}, [productsSegment]);

	useEffect(() => {
		setProductsSegmentCountDialog(productsSegmentCount);
	}, [productsSegmentCount]);

	/* useEffect(() => {
		props?.getSelectSegment(selectSegment);
	}, [selectSegment]); */

	const setSelectSegment: any = (values:any) => {
		setSelectSegmentState(values);
		props?.getSelectSegment({ ...values, ...{ page:0, rowsPerPage:10 } });
	}

	const setSelectSegmentPagination: any = (values: any) => {
		console.log({ ...openDialogProductsState, ...values.pagination });
		props?.getSelectSegmentPagination({ ...openDialogProductsState, ...values.pagination });
	};


	return (
		<React.Fragment>
			<Dialog
				fullWidth={true}
				maxWidth={'lg'}
				open={open}
				onClose={handleClose}
				aria-describedby='alert-dialog-slide-description'
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 100, alignItems: 'flex-start', verticalAlign: 'top' }}
			>
				<DialogTitle>{''}</DialogTitle>
				<DialogContent>
					<TableProductsSegment
						manufacturer={manufacturer}
						categories={categories}
						productsSegmentDialog={productsSegmentDialog}
						productsSegmentCountDialog={productsSegmentCountDialog}
						selected={{
							selectManufacturer: (values: any) => setSelectSegment({ values: values, type: 'manufacturer' }),
							selectCategories: (values: any) => setSelectSegment({ values: values, type: 'categories' }),
							selectPagination: (values: any) => setSelectSegmentPagination({ pagination: values }),
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cerrar</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}
