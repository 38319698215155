import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import TextField from '@mui/material/TextField';

import { getProducts } from '../../store/products/action';
import { getManufacturer } from '../../store/manufacturer/action';
import {
	getCategories,
	getCategoriesSearch,
	getCategoriesIngramSearch,
	crudCategories,
} from '../../store/categories/action';

import { Dispatch } from 'redux';

import { CategoriesState, Categories } from '../../store/categories/types';
import { ApplicationState } from '../../store/index';

import AutocompletCheckBox from './Components/AutocompletCheckBox';
import AutocompletCheckBoxGroup from './Components/AutocompletCheckBoxGroup';
import AutocompletBoxGroup from './Components/AutocompletBoxGroup';

import DatePicker from './Components/DatePicker';
import TextNumberBasic from './Components/TextNumber';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import { putFilter } from '../../store/filters/action';
import { putLoading } from '../../store/loading/action';

import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { CrudCategories } from './Dialogs/CrudCategories';


import FormControl from '@mui/material/FormControl';
import { FilterCategories } from './Dialogs/FilterCategories';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { userReset, getUsers } from '../../store/user/action';
import { Label } from '@mui/icons-material';

import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

import CircularProgress from '@mui/material/CircularProgress';



const timeFormat: Intl.DateTimeFormatOptions = {
	month: 'short',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit',
	year: 'numeric',
	hour12: false,
	//timeZoneName: 'short',
	//timeZone: 'UTC',
};

interface Column {
	id:
		| 'row?.id'		
		| 'row?.parent?.name'
		| 'row?.name'
		| 'row?.categoriesIngram'
		| undefined
		|undefined;
	label: any;
	minWidth?: number;
	align?: 'right';
	format?: (value: number) => string;
	map?: string;
	component?: string;
}

const columns: readonly Column[] = [
	{
		id: 'row?.id',
		label: 'Id',
		minWidth: 10,
	},
	{
		id: 'row?.parent?.name',
		label: 'Categoria',
		minWidth: 10,
	},
	{ id: 'row?.name', label: 'SubCategoria', minWidth: 10 },
	{ id: 'row?.categoriesIngram', label: 'Linker Ingram', minWidth: 10, map: 'categoriesIngram' },
	{ id: undefined, label: 'Linker', minWidth: 10, component: 'Linker' },
	{ id: undefined, label: 'Activo', minWidth: 10, component: 'Activo' },
];



function Row(props: { rowp: Categories[], user:any }) {
	const { rowp, user } = props;
	const [open, setOpen] = React.useState(true);
	const categoriesIngram = useSelector((state: ApplicationState) => state.categories.dataIngram);

	const [filtersIngram, setFiltersIngram] = React.useState({
		categoriesIngram: [],
	});

	const dispatch = useDispatch<any>();

	const mapCategories = (data: any) => {
		return (
			data
				?.map((item: any) => {
					return {
						parent: item?.parentName || item?.name,
						title: item?.name,
						id: item?.name,
					};
				}) || []
		);
	}
	function serachDataIngram({ id, value }: searchProps, categoryName: string) {
		
		setFiltersIngram({
			...filtersIngram,
			[id]: value,
		});
		dispatch(
			crudCategories({
				categoryParent: undefined,
				categoryName: categoryName,
				categoryNameIngram: value?.title,
				userC: Number(user) || null,
			})
		);
	}
	function deleteLinker(props: deleteProps) {		
		dispatch(
			crudCategories({
				delete_: true,
				categoryParent: undefined,
				categoryName: props?.name,
				categoryNameIngram: props.categoriesIngramId,
				userC: Number(user) || null,
				status: !props.status,
				type: props.type,
			})
		);

	}
	return (
		<React.Fragment>
			{rowp?.map((row:Categories) => {
				const expand = (
					<TableCell>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
				);
				return (
					<TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
						{columns.map((column) => {
							let value: any = null;
							if (column?.map) {
								value = row?.categoriesIngram?.map(
									(item: any) =>
										item.customerId == user && (
											<>
												<Chip
													color={(item.status && 'success') || 'error'}
													label={`${item.categoriesIngramId} +${(row?.categoriesIngram?.length || 1) - 1}`}
													/* onDelete={() =>
														deleteLinker({
															type:'delete',
															status: item.status,
															name: row.name,
															categoriesIngramId: item.categoriesIngramId,
															customerId: user,
														})
													} */
													onClick={() =>
														deleteLinker({
															type:'change',
															status: item.status,
															name: row.name,
															categoriesIngramId: item.categoriesIngramId,
															customerId: user,
														})
													}
												/>
											</>
										)
								);
							} else {
								if (column?.id) {
									value = eval(column?.id);
								} else {
									if (['Linker'].includes(column?.component||'')) {
										value = (
													<AutocompletBoxGroup
														props={{
															group: true,
															data: mapCategories(categoriesIngram),
															value: filtersIngram?.categoriesIngram,
															maxWidth: 200,
															id: 'categoriesIngram',
															title: 'Categorias Ingram',
															onSearch: (search: searchProps) => serachDataIngram(search, row?.name),
														}}
													/>
												);
									}
									if (['Activo'].includes(column?.component || '')) {
										value = (
											<>
												<Chip
													color={(row.status && 'success') || 'error'}
													label={(row.status && 'Activo') || 'Inactivo'}
													/* onDelete={() =>
														deleteLinker({
															type:'delete',
															status: item.status,
															name: row.name,
															categoriesIngramId: item.categoriesIngramId,
															customerId: user,
														})
													} */
													onClick={() =>
														deleteLinker({
															type: 'changeIngram',
															status: row.status,
															name: row.name,
															categoriesIngramId: row.name,
															customerId: user,
														})
													}
												/>
											</>
										);
									}
								}
							} 

							return (
								//value && (
									<TableCell key={column.id} align={column.align} style={{ fontSize: '10px' }}>
										{column.format ? column.format(value) : value }
									</TableCell>
								//)
							);
						})}
					</TableRow>
				);
			})}
		</React.Fragment>
	);
}

interface searchProps {
	value?: any;
	id?: any;
}

interface deleteProps {
	type: string;
	name?: string;
	categoriesIngramId?: string;
	customerId: number;
	status: boolean;
}

export default function Categorie() {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [filters, setFilters] = React.useState({
		categories: [],
		selected: [],
		user: null
	});

	const [open, setOpen] = React.useState(false);
	const dispatchThunk = useDispatch<Dispatch<any>>();
	const dispatch = useDispatch<any>();

	const user = useSelector((state: ApplicationState) => state.user.data) || {};
	const [profile, setProfile] = React.useState(user.user.role.toLocaleLowerCase());

	const { data, length, errors } = useSelector((state: ApplicationState) => state.categories);
	const categories = useSelector((state: ApplicationState) => state.categories);
	const filtersGlb = useSelector((state: ApplicationState) => state.filters);
	const users = useSelector((state: ApplicationState) => state.user);

	const [checkedCatIngram, setCheckedCatIngram] = React.useState(user.user.super);


	function sendDataC(values: any) {
		dispatchThunk(putFilter({ id: 'categories', value: values }));
	}

	const handleChangeCatIngram = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedCatIngram(event.target.checked);
	};

	function sendData(values: any) {
		dispatchThunk(putLoading(true));
		dispatch(
			crudCategories({
				addCategory:true,
				categoryParent: (values.subcategory)? values.category: undefined,
				categoryName: values.subcategory || values.category,
				categoryNameIngram: undefined,
				userC: Number(filters?.user) || null,
				ingram:values.ingram||undefined
			})
		);
	}

	const mapCategories = (data: any) => {
		return (
			data
				?.map((item: any) => {
					return {
						parent: item?.parentName || item?.name,
						title: item?.name,
						id: item?.name,
					};
				}) || []
		);
	}

	const mapFilter = (value: any) => {
		return !Array.isArray(value) ? value : value?.map((elem: any) => elem.id);
	}

	function serachData({ id, value }: searchProps) {
		setFilters({
			...filters,
			[id]: value,
		});
		dispatchThunk(putFilter({ id: id, value: mapFilter(value) }));
	}

	useEffect(() => {
		// 👇️ run a function when the component unmounts 👇️
		return () => {
			dispatchThunk(putFilter({ id: 'clear', value: undefined }));
		};
	}, [dispatchThunk]);

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, data]);

	useEffect(() => {
		dispatchThunk(putLoading(false));
	}, [dispatchThunk, categories]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getCategoriesSearch({
				search: null,
			})
		);		
		dispatch(
			getCategoriesIngramSearch({
				search: null,
				ingram: true,
			})
		);
	}, [dispatchThunk, dispatch]);

	useEffect(() => {
		if (errors?.code) {
			dispatchThunk(userReset());
		} 
	}, [dispatchThunk, errors]);

	useEffect(() => {
		setPage(0);
	}, [dispatchThunk, dispatch, filtersGlb, rowsPerPage]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getCategories({
				user: filters?.user,
				filters: filtersGlb,
				rowsPerPage: rowsPerPage,
				page: page,
			})
		);
		dispatch(
			getCategories({
				user: undefined,
				filters: filtersGlb,
				rowsPerPage: rowsPerPage,
				page: page,
				ingram: true,
			})
		);
	}, [dispatchThunk, dispatch, page, rowsPerPage, filtersGlb, filters?.user]);

	useEffect(() => {
		dispatchThunk(putLoading(true));
		dispatch(
			getUsers({
				profile: 'CUSTOMER',
				filters: null,
				rowsPerPage: 1000,
				page: 0,
			})
		);
	}, [dispatchThunk, dispatch]);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const Item = styled(Paper)(({ theme }) => ({
		backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: 'left',
		color: theme.palette.text.secondary,
	}));

	return (
		<Paper sx={{ width: '100%', overflow: 'hidden' }}>
			{/* <FormControl sx={{ m: 1, minWidth: 400 }} size='small'>
				<AutocompletCheckBoxGroup
					props={{
						group: false,
						data: mapCategories(categories.datasearch).filter((item: any) => item.parent == item.title),
						value: filters?.categories.filter((item: any) => item.parent == item.title),
						maxWidth: 400,
						id: 'categories',
						title: 'Categorias',
						onSearch: (search: searchProps) => serachData(search),
					}}
				/>
			</FormControl> */}

			<FormControl sx={{ m: 0.5 }} size='small'>
				<FilterCategories
					props={{ getValues: (values: any) => sendDataC(values) }}
					data={undefined}
					name='Filtros'
					categories={categories}
					checkedCatIngram={checkedCatIngram}
				/>
			</FormControl>
			{/* <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
				<CrudCategories
					props={{ getValues: (values: any) => sendData(values) }}
					data={undefined}
					name='Crear Categoria'
					role={user.user.role}
					profile={profile}
					categories={undefined}
				/>
			</FormControl> */}
			<FormControl sx={{ m: 0.5 }} size='small'>
				<CrudCategories
					props={{ getValues: (values: any) => sendData(values) }}
					data={undefined}
					name='Crear Categoria'
					role={user.user.role}
					user={user.user}
					profile={profile}
					categories={categories}
				/>
			</FormControl>
			{user.user.super && (
				<FormControl sx={{ m: 0.5 }} size='small'>
					<div>
						<Checkbox
							checked={checkedCatIngram}
							onChange={handleChangeCatIngram}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
						Ingram
					</div>
				</FormControl>
			)}
			{user.user.role == 'SERVICE' && (
				<FormControl sx={{ float: 'right', marginTop: { sm: '.5rem' }, width: '20%' }} size='small'>
					<Autocomplete
						id='user'
						isOptionEqualToValue={(option, value) => option.label === value.label}
						options={[
							...[{ id: 0, label: 'Selecciona...' }],
							...(users.users?.map((m: any) => {
								return { id: m.id, label: m.email };
							}) || []),
						]}
						sx={{
							'& .MuiInputBase-input': {
								height: '.01px',
							},
						}}
						renderInput={(params) => <TextField {...params} label='Cliente' />}
						value={
							users.users
								?.filter((k: any) => k.id == filters?.user)
								.map((m: any) => {
									return { id: m.id, label: m.email };
								})[0] || { id: 0, label: 'Selecciona...' } ||
							undefined
						}
						onChange={(e: any, value: any) => {
							serachData({ id: 'user', value: value?.id || 0 });
						}}
					/>
				</FormControl>
			)}
			<TableContainer sx={{ maxHeight: 400 }} component={Paper}>
				<Table size='small' stickyHeader aria-label='sticky collapsible table'>
					<TableHead>
						<TableRow>
							{columns.map((column) => (
								<TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					{!checkedCatIngram && (
						<TableBody>
							{data?.length
								? data?.map((row) => <Row key={row.id} rowp={[row]} user={filters?.user||user.user.id} />)
								: !data && (
										<CircularProgress
											disableShrink
											size={70}
											sx={{
												position: 'fixed',
												left: '50%',
												top: '50%',
												transform: 'translate(-50%, -50%)',
												zIndex: 5,
											}}
										/>
								  )}
						</TableBody>
					)}
					{checkedCatIngram && (
						<TableBody>
							{categories.dataIngramSearch?.length
								? categories.dataIngramSearch?.map((row) => <Row key={row.id} rowp={[row]} user={filters?.user||user.user.id} />)
								: !categories?.dataIngramSearch && (
										<CircularProgress
											disableShrink
											size={70}
											sx={{
												position: 'fixed',
												left: '50%',
												top: '50%',
												transform: 'translate(-50%, -50%)',
												zIndex: 5,
											}}
										/>
								  )}
						</TableBody>
					)}
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 100, 300]}
				component='div'
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</Paper>
	);
}